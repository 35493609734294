import React, {useState} from 'react';
import Heading from '../../Components/Heading/heading.tsx';
import '../../Components/Button/button-style.scss';
import {Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box} from '@mui/material';
import {toast} from 'react-toastify';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import {addLeads} from './action.ts';
import {validatePincode} from '../../Utils/PincodeValidation/pincode-validation.tsx';
import {validateDriverName} from '../../Utils/NameValidation/name-validation.tsx';
import {validateEmail} from '../../Utils/EmailValidation/email-validation.tsx';
import { validateRemarks } from '../../Utils/RemarksValidation/remarks-validation.tsx';
interface AddLeadModalProps {
  addLeadModalOpen: boolean;
  onClose: () => void;
  fetchUsers:(page: number, pageSize: number, lead: string | null, blacklist: string | null, left: string | null, tabName: string) => void;
  page : number;
  pageSize : number;
}
const AddLeadModal = ({ addLeadModalOpen, onClose , fetchUsers ,page , pageSize}: AddLeadModalProps) => {
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [remarks, setRemarks] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [remarksError, setRemarksError] = useState<string>('');
    const [mobileNumber , setMobileNumber] = useState<string>('');
    const [mobileNumberError , setMobileNumberError] = useState<string>('');
    const [pincode , setPincode] = useState<string>('');
    const [pincodeError , setPincodeError] = useState<string>('');
    const resetState = () => {
      setName('');
      setNameError('');
      setEmail('');
      setEmailError('');
      setMobileNumber('');
      setMobileNumberError('');
      setPincode('');
      setPincodeError('');
      setRemarks('');
      setRemarksError('');
    };
    const handleEmailBlur = () => {
      if (email === '') {
        setEmailError('');
      } else if (validateEmail(email)) {
        setEmailError('');
      } else {
        setEmailError('Please enter a valid email address.');
      }
  };
    const handleSubmit = () => {
       if (!name) {
          setNameError('Name is required');
        }
       if (!mobileNumber) {
          setMobileNumberError('Mobile number is required');
        }
       if (!pincode) {
          setPincodeError('Pincode is required');
        }
      if(nameError||emailError||mobileNumberError||pincodeError){
        toast.error("Please fix the errors");
        return;
      }
      else if (name&&mobileNumber&&pincode&&!nameError&&!emailError&&!mobileNumberError&&!pincodeError) {
        addLeads(
            name,
            pincode,
            mobileNumber,
            email,
            remarks,
              (response: any) => {
                 toast.success(response);
                 onClose();
                 resetState();
                 setTimeout(() => {
                      fetchUsers(page, pageSize, '1', null, null, 'lead');
                  }, 3000);
              },
              (errMessage: any) => {
                  toast.error(errMessage.message);
              }
          );
       }  
    };
    const handleCancel = () =>{
      resetState();
      onClose();
    };
   const handlePincodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setPincode(value);
      const error = validatePincode(value);
      setPincodeError(error);
   };
   const handleNameChange = (event) => {
      const { value } = event.target;
      setName(value);
      if (value === '') {
        setNameError('');
      } else if (validateDriverName(value)) {
        setNameError('');
      } else {
        setNameError('Characters are allowed');
      }
  };
  const handleEmailChange = (event) => {
      const { value } = event.target;
      setEmail(value);
      setEmailError('');
  };
  const handleRemarksChange = (event) => {
    const { value } = event.target;
    setRemarks(value);
    if (value === '') {
      setRemarksError('');
    } else if (validateRemarks(value)) {
      setRemarksError('');
    } else {
      setRemarksError('Invalid Character');
    }
  };
  const handlePhoneNumberChange = (event) => {
      const { name, value } = event.target;
      if(name==='mobileNumber'){
        setMobileNumber(value);
      }
      if(value===''){
      if (name==='mobileNumber' ) {
        setMobileNumberError("");
       } 
      return;
    }
      const { errorMessage } = validatePhoneNumber(value);
      if (name==='mobileNumber' ) {
        setMobileNumberError(errorMessage);
      } 
  };
  const handlePincodeBlur = () => {
    if(pincode==='')
    {
      setPincodeError("");
    }
     if(pincode.length>=1&&pincode.length<6)
     {
      setPincodeError("Pincode must be 6 digits");
     }
  };  
  const handlePhoneNumberBlur = () => {
    if (mobileNumber === '') {
      setMobileNumberError('');
    } else if (mobileNumber.length>=1&&mobileNumber.length<10) {
      setMobileNumberError('Please enter a 10 digit number');
    } 
  };
  const handleNameBlur = () => {
      if (nameError=== '') {
        setNameError('');
      } else  {
        setNameError(nameError);
      } 
  };
    
  return (
    <Dialog
      open={addLeadModalOpen}
      onClose={onClose}
      fullWidth
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '500px', 
          height: 'auto', 
        }
      }}
    >
      <Box sx={{ marginLeft: {
        xs:'0px',
      sm: '-180px', 
    }, }}>
          <Heading title="Add Lead" closeIcon="true" onClose={onClose} />
        </Box>
      <DialogContent>
        <Box sx={{ marginLeft: {xs:'-10px', sm:'0px'}, marginTop: '5px' }}>
            <p style={{ fontSize: '17px' }}> Name</p>
        </Box>
        <Box sx={{ marginLeft:{xs: '112px', sm:'200px'}, marginTop: '-22px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="name"
              value={name}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              error={!!nameError}
              helperText={nameError}
              style={{ width: '225px' }}
           />
          </Box>
          </Box>
          <Box sx={{ marginLeft:{xs: '-10px', sm:'0px'}, marginTop: '5px' }}>        <p style={{ fontSize: '17px' }}>Pincode</p>
          </Box>
          <Box sx={{ marginLeft:{xs: '112px', sm:'200px'}, marginTop: '-22px' }}>          
            <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="pincode"
              value = {pincode}
              onBlur={handlePincodeBlur}
              onChange={handlePincodeChange}
              error={!!pincodeError}
              helperText={pincodeError}
              style={{ width: '225px' }}
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft:{xs: '-10px', sm:'0px'}, marginTop: '5px' }}>        <p style={{ fontSize: '17px' }}>Mobile Number</p>
        </Box>
        <Box sx={{ marginLeft:{xs: '112px', sm:'200px'}, marginTop: '-22px' }}>
        <Box display="flex" alignItems="center">
        <TextField
              variant="outlined"
              size="small"
              type="text"
              name="mobileNumber"
              value={ mobileNumber}
              onChange={(e) =>  handlePhoneNumberChange(e)}
              onBlur = {handlePhoneNumberBlur}
              error={!!mobileNumberError}
              helperText={mobileNumberError}
              style={{ width: '225px' }}
            />
        </Box>
        </Box>
        <Box sx={{ marginLeft:{xs: '-10px', sm:'0px'}, marginTop: '5px' }}>        <p style={{ fontSize: '17px' }}>Email</p>
      </Box>
      <Box sx={{ marginLeft:{xs: '112px', sm:'200px'}, marginTop: '-22px' }}>
           <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="email"
              value={email}
              onChange={handleEmailChange}
               onBlur={handleEmailBlur}
              error={!!emailError}
              helperText={emailError}
              style={{ width: '225px' }}
            />
          </Box>
         </Box>
         <Box sx={{ marginLeft:{xs: '-10px', sm:'0px'}, marginTop: '5px' }}>        <p style={{ fontSize: '17px' }}>Remarks</p>
      </Box>
      <Box sx={{ marginLeft:{xs: '112px', sm:'200px'}, marginTop: '-22px' }}>           <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="remarks"
              value={remarks}
              onChange={handleRemarksChange}
              error={!!remarksError}
              helperText={remarksError}
              style={{ width: '225px' }}
            />
          </Box>
         </Box>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          
             <Button className='custom-button' onClick={handleSubmit}>Add</Button>
             <Button className='custom-cancel-button' onClick={handleCancel}>Cancel</Button>
    
      </DialogActions>
    </Dialog>
  );
};

export default AddLeadModal;
