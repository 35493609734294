import React, { useState ,useEffect} from 'react';
import { Dialog, DialogTitle, DialogContent, Tabs, Tab, Box, TextField, Button, IconButton, Tooltip, Autocomplete, Chip,SelectChangeEvent, Typography} from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import {validateDriverName} from '../../Utils/NameValidation/name-validation.tsx';
import './editCompanyModal.scss'; 
import UploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import {toast} from 'react-toastify';
import {validateEmail} from '../../Utils/EmailValidation/email-validation.tsx';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import {validatePincode} from '../../Utils/PincodeValidation/pincode-validation.tsx';
import {validatePassword} from '../../Utils/PasswordValidation/password-validation.tsx';
import {addCompany} from './action.ts';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import {validateBranchName} from '../../Utils/BranchNameValidation/branchname-validation.tsx';
import {validateAccountNumber} from '../../Utils/AccountNumberValidation/account-number-validation.tsx';
import {validateAddress} from '../../Utils/AddressValidation/address-validation.tsx';
import {validateIFSC} from '../../Utils/IFSCValidation/ifsc-validation.tsx';
import {validateCin} from '../../Utils/CinValidation/cin-validation.tsx';
import {validatePan} from '../../Utils/PANValidation/pan-validation.tsx';
import {validateTan} from '../../Utils/TANValidation/tan-validation.tsx';
import {validateGST} from '../../Utils/GSTValidation/gst-validation.tsx';
import {validateConfirmpassword} from '../../Utils/ConfirmPasswordValidation/confirm-password-validation.tsx';
import { validateCompanyName } from '../../Utils/NameValidation/name-validation.tsx';
import '../../Components/Tab/tab-style.scss';
import '../../Components/TextField/text-field-style.scss';

const createMandatoryLabel = (labelText) => {
  return (
    <span>
      {labelText} <span style={{ color: 'red' }}>*</span>
    </span>
  );
};
interface AddCompanyModalProps {
    open: boolean;
    onClose: () => void;
}
interface NewValuesState {
  companyName: string;
  email: string;
  mobileNumber: string;
  address: string;
  pincode: string;
  bankName: string;
  branchName: string;
  accountName: string;
  accountNumber: string;
  ifsc: string;
  password: string;
  confirmPassword: string;
  brands: string;
  contactNameOne: string;
  contactNameTwo: string;
  contactNameThree: string;
  contactEmailOne: string;
  contactEmailTwo: string;
  contactEmailThree: string;
  contactDesignationOne: string;
  contactDesignationTwo: string;
  contactDesignationThree: string;
  contactNumberOne: string;
  contactNumberTwo: string;
  contactNumberThree: string;
  state: string;
  cin: string;
  pan: string;
  tan: string;
  gst: string;
}

const AddCompanyModal = ({ open, onClose }) => {
 
  const [activeTab, setActiveTab] = useState(0);
  const [type, setType] =  useState('');
  const [typeError, setTypeError] = useState<boolean>(false);
  const [accountTypeError, setAccountTypeError] = useState<boolean>(false);
  const [accountType, setAccountType] =  useState('');
  const [brandsError, setBrandsError] = useState(false);
  const [brandError, setBrandError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [tabError, setTabError] = useState<number | null>(null);
  const [errors, setErrors] = useState({
    companyNameError: '',
    emailError: '',
    pincodeError: '',
    mobileNumberError: '',
    addressError: '',
    bankNameError: '',
    branchNameError: '',
    accountNameError: '',
    accountNumberError: '',
    ifscError: '',
    passwordError: '',
    confirmPasswordError: '',
    contactNameOneError: '',
    contactNameTwoError: '',
    contactNameThreeError: '',
    contactEmailOneError: '',
    contactEmailTwoError: '',
    contactEmailThreeError: '',
    contactNumberOneError: '',
    contactNumberTwoError: '',
    contactNumberThreeError: '',
    contactDesignationOneError: '',
    contactDesignationTwoError: '',
    contactDesignationThreeError: '',
    accountTypeError: '',
    stateError: '',
    cinError: '',
    panError: '',
    gstError : '',
    tanError : '',
  });
  const resetState = () => {
    setActiveTab(0);
    setType('');
    setAccountType('');
    setTypeError(false);
    setAccountTypeError(false);
    setErrors({
      companyNameError: '',
      emailError: '',
      pincodeError: '',
      mobileNumberError: '',
      addressError: '',
      bankNameError: '',
      branchNameError: '',
      accountNameError: '',
      accountNumberError: '',
      ifscError: '',
      passwordError: '',
      confirmPasswordError: '',
      contactNameOneError: '',
      contactNameTwoError: '',
      contactNameThreeError: '',
      contactEmailOneError: '',
      contactEmailTwoError: '',
      contactEmailThreeError: '',
      contactNumberOneError: '',
      contactNumberTwoError: '',
      contactNumberThreeError: '',
      contactDesignationOneError: '',
      contactDesignationTwoError: '',
      contactDesignationThreeError: '',
      accountTypeError: '',
      stateError: '',
      cinError: '',
      panError: '',
      gstError: '',
      tanError: '',
    });
    setNewValues({
      companyName: '',
      email: '',
      mobileNumber: '',
      address: '',
      pincode: '',
      bankName: '',
      branchName: '',
      accountName: '',
      accountNumber: '',
      ifsc: '',
      password: '',
      confirmPassword: '',
      brands: '',
      contactNameOne: '',
      contactNameTwo: '',
      contactNameThree: '',
      contactEmailOne: '',
      contactEmailTwo: '',
      contactEmailThree: '',
      contactDesignationOne: '',
      contactDesignationTwo: '',
      contactDesignationThree: '',
      contactNumberOne: '',
      contactNumberTwo: '',
      contactNumberThree: '',
      state: '',
      cin: '',
      pan: '',
      tan: '',
      gst: '',
    });
  };
  const typesOptions = [
    { id: 'vendor', label: 'Vendor'},
    { id: 'client', label: 'Client' },
  ];
  const accountTypesOptions = [
    { id: 'current', label: 'Current'},
    { id: 'recurring', label: 'Recurring'},
    { id: 'savings', label: 'Savings' },
  ];
  const [icons, setIcons] = useState<{ [key: string]: 'upload' | 'view' }>({
      pan: 'upload',
      tan: 'upload',
      gst: 'upload',
      cancelledCheque: 'upload',
      logo: 'upload'
  });
  const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File | null }>({
    pan: null,
    tan: null,
    gst: null,
    cancelledCheque: null,
    logo: null,
  });
  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    setType(selectedValue);
    if (selectedValue) {
      setTypeError(false); 
    }
  };
  const [newValues, setNewValues] = useState<NewValuesState>({
    companyName: '',
    email: '',
    mobileNumber: '',
    address: '',
    pincode: '',
    bankName:'',
    branchName: '',
    accountName: '',
    accountNumber:'',
    ifsc :'',
    password : '',
    confirmPassword : '',
    brands :  '',
    contactNameOne: '',
    contactNameTwo: '',
    contactNameThree : '',
    contactEmailOne : '',
    contactEmailTwo : '',
    contactEmailThree : '',
    contactDesignationOne :'',
    contactDesignationTwo : '',
    contactDesignationThree : '',
    contactNumberOne : '',
    contactNumberTwo : '',
    contactNumberThree : '',
    state : '',
    cin : '',
    pan : '',
    tan : '',
    gst : '',
  });
  const maxFileSize = 1 * 1024 * 1024; 
  const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
  const handleTabChange = (newTabIndex) => {
    const tabNames = ['Company Details', 'Additional Details', 'Bank Details', 'Documents'];
    const validateTab0 = () => {
        let errorsFound = false;
        const newErrors = {
          companyNameError: !newValues.companyName
              ? 'Company Name is required'
              : errors.companyNameError || '',
      
          emailError: !newValues.email
              ? 'Email is required'
              : errors.emailError || '',
      
          mobileNumberError: !newValues.mobileNumber
              ? 'Mobile Number is required'
              : errors.mobileNumberError || '',
      
          passwordError: !newValues.password
              ? 'Password is required'
              : errors.passwordError || '',
      
          confirmPasswordError: !newValues.confirmPassword
              ? 'Confirm Password is required'
              : errors.confirmPasswordError || '',
      
          addressError: !newValues.address
              ? 'Address is required'
              : errors.addressError || '',
      
          pincodeError: !newValues.pincode
              ? 'Pincode is required'
              : errors.pincodeError || '',
      
          stateError: !newValues.state
              ? 'State is required'
              : errors.stateError || '',
      
          cinError: !newValues.cin
              ? 'CIN is required'
              : errors.cinError || ''
      };
       Object.keys(newErrors).forEach((key) => {
            if (newErrors[key]) {
                errorsFound = true;
            }
        });
        setErrors((prevError) => ({ ...prevError, ...newErrors }));
        if (!type) {
            setTypeError(true);
            errorsFound = true;
        } else {
            setTypeError(false);
        }
        if (newValues.brands.length === 0) {
            setBrandsError(true);
            errorsFound = true;
        } else {
            setBrandsError(false);
        }
        return errorsFound;
    };
    const validateTab1 = () => {
        let errorsFound = false;
        const newErrors = {
          contactEmailOneError: !newValues.contactEmailOne
              ? 'Email of first contact person is required'
              : errors.contactEmailOneError || '',
          contactNameOneError: errors.contactNameOneError || '',
          contactNameTwoError: errors.contactNameTwoError || '',
          contactNameThreeError: errors.contactNameThreeError || '',
          contactEmailTwoError: errors.contactEmailTwoError || '',
          contactEmailThreeError: errors.contactEmailThreeError || '',
          contactNumberTwoError: errors.contactNumberTwoError || '',
          contactNumberOneError: errors.contactNumberOneError || '',
          contactNumberThreeError: errors.contactNumberThreeError || '',
          contactDesignationTwoError: errors.contactDesignationTwoError || '',
          contactDesignationOneError: errors.contactDesignationOneError || '',
          contactDesignationThreeError: errors.contactDesignationThreeError || '',
      };
      
      Object.keys(newErrors).forEach((key) => {
            if (newErrors[key]) {
                errorsFound = true;
            }
        });
        setErrors((prevError) => ({ ...prevError, ...newErrors }));
        return errorsFound;
    };
    const validateTab2 = () => {
        let errorsFound = false;
        const newErrors = {
          accountNumberError: !newValues.accountNumber
              ? 'Account Number is required'
              : errors.accountNumberError || '',
          bankNameError: !newValues.bankName
              ? 'Bank Name is required'
              : errors.bankNameError || '',
          branchNameError: !newValues.branchName
              ? 'Branch Name is required'
              : errors.branchNameError || '',
          ifscError: !newValues.ifsc
              ? 'IFSC is required'
              : errors.ifscError || ''
      };
      Object.keys(newErrors).forEach((key) => {
            if (newErrors[key]) {
                errorsFound = true;
            }
        });
        setErrors((prevError) => ({ ...prevError, ...newErrors }));

        if (!accountType) {
            setAccountTypeError(true);
            errorsFound = true;
        } else {
            setAccountTypeError(false);
        }
        return errorsFound;
    };
    const hasErrors = (currentTab) => {
        switch (currentTab) {
            case 0:
                return validateTab0();
            case 1:
                return validateTab1();
            case 2:
                return validateTab2();
            default:
                return false;
        }
    };
    if (newTabIndex > activeTab) {
        for (let i = 0; i < newTabIndex; i++) {
            if (hasErrors(i)) {
                toast.error(`Please fix the errors in  ${tabNames[i]}`);
                setTabError(i);
                return;
            }
        }
    }
    setActiveTab(newTabIndex);
  };
  const handleNameChange = (event,nameField) => {
    const { value } = event.target;
    setNewValues(prev => ({
      ...prev,
      [nameField]: value,
    }));
  
    if (nameField === 'companyName' && value === '') {
      setNewValues(prev => ({
        ...prev,
        brands: '',
      }));
    }
  
    if (value === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`${nameField}Error`]: ''
      }));
    } else if (validateCompanyName(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`${nameField}Error`]:  ''
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`${nameField}Error`]:  'Invalid Input'
      }));
    }
  };
  const handleClickCancel = () => {
    onClose();
    resetState();
  };
 const handleEmailChange = (event, emailField) => {
    const { value } = event.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${emailField}Error`]: '' 
    }));
   setNewValues((prev) => ({
      ...prev,
      [emailField]: value, 
    }));
  };
  const handleEmailBlur = () => {
    const emailValue = newValues.email;
    if (errors.emailError) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailError: errors.emailError,
      }));
    } else if (validateEmail(emailValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailError: '',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailError: 'Please enter a valid email address.',
      }));
    }
  };
  const handleEmailOneBlur = () => {
    const emailValue = newValues.contactEmailOne;
    if (emailValue === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactEmailOneError: '',
      }));
    } else if (validateEmail(emailValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactEmailOneError: '',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactEmailOneError: 'Please enter a valid email address.',
      }));
    }
  };
  const handleEmailTwoBlur = () => {
    const emailValue = newValues.contactEmailTwo;
    if (emailValue === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactEmailTwoError: '',
      }));
    } else if (validateEmail(emailValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactEmailTwoError: '',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactEmailTwoError: 'Please enter a valid email address.',
      }));
    }
  };
  const handleEmailThreeBlur = () => {
    const emailValue = newValues.contactEmailThree;
    if (emailValue === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactEmailThreeError: '',
      }));
    } else if (validateEmail(emailValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactEmailThreeError: '',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactEmailThreeError: 'Please enter a valid email address.',
      }));
    }
  };
  const handleMobileNumberBlur = () => {
    if (newValues.mobileNumber === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumberError: '',
      }));
    } else if(errors.mobileNumberError){
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumberError: errors.mobileNumberError,
      }));
    }
    else if (newValues.mobileNumber.length>=1&&newValues.mobileNumber.length<10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNumberError: 'Please enter a 10 digit number',
      }));
    } 
  };
  const handleNumberOneBlur = () => {
    if (newValues.contactNumberOne=== '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumberOneError: '',
      }));
    } else if(errors.contactNumberOneError){
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumberOneError: errors.contactNumberOneError,
      }));
    }
    else if (newValues.contactNumberOne.length>=1&&newValues.contactNumberOne.length<10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumberOneError: 'Please enter a 10 digit number',
      }));
    } 
  };
  const handleNumberThreeBlur = () => {
    if (newValues.contactNumberThree === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumberThreeError: '',
      }));
    } else if(errors.contactNumberThreeError){
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumberThreeError: errors.contactNumberThreeError,
      }));
    }
    else if (newValues.contactNumberThree.length>=1&&newValues.contactNumberThree.length<10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumberThreeError: 'Please enter a 10 digit number',
      }));
    } 
  };
  const handleNumberTwoBlur = () => {
    if (newValues.contactNumberTwo === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumberTwoError: '',
      }));
    } else if(errors.contactNumberTwoError){
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumberTwoError: errors.contactNumberTwoError,
      }));
    }
    else if (newValues.contactNumberTwo.length>=1&&newValues.contactNumberTwo.length<10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contactNumberTwoError: 'Please enter a 10 digit number',
      }));
    } 
  };
  const handleAddressChange = (event) => {
    const { value } = event.target;
    setNewValues(prev => ({
      ...prev,
      address: value,
    }));
    if (value === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        addressError: '',
      }));
    } else if (validateAddress(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        addressError: '',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        addressError: 'Invalid Input',
      }));
    }
  }; 
  const handlePhoneNumberChange = (event ,phoneField) => {
      const { value } = event.target;
      if(value==='')
      {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`${phoneField}Error`]: '',
        }));
      }
      else{
        const { errorMessage } = validatePhoneNumber(value);
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`${phoneField}Error`]: errorMessage,
        }));}
        setNewValues(prev => ({
           ...prev,
           [phoneField]: value,
      }));
  };  
  const handleBankNameChange = (event) => {
    const { value } = event.target;
    setNewValues(prev => ({
           ...prev,
           bankName: value,
    }));
    if (value === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bankNameError: '',
    }));
    } else if (validateCompanyName(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          bankNameError: '',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          bankNameError: 'Invalid Input.Only characters are allowed',
        }));
      }
  };
 const handleBranchNameChange = (event) => {
  const { value } = event.target;
  setNewValues(prev => ({
           ...prev,
           branchName: value,
      }));
      if (value === '') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          branchNameError: '',
        }));
      } else if (validateBranchName(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          branchNameError: '',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          branchNameError: 'Invalid Input.Only characters are allowed',
        }));
      }
 };
 const handleAccountNumberChange = (event) => {
    const { value } = event.target;
    setNewValues(prev => ({
      ...prev,
      accountNumber: value,
    }));
    if (value === '') {
      setErrors((prevErrors) => ({
      ...prevErrors,
      accountNumberError: ''
     }));
    } else  {
    const  errorMessage  = validateAccountNumber(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      accountNumberError:  errorMessage
    }));
    }
  };
  const handleAccountTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    setAccountType(selectedValue);
    if (selectedValue) {
       setAccountTypeError(false); 
    }
    setNewValues(prev => ({
       ...prev,
       accountName: selectedValue.toLowerCase(),
    }));
  };
  const handlePincodeBlur = () => {
    if(newValues.pincode==='')
    {
      setErrors((prevErrors) => ({
      ...prevErrors,
      pincodeError: '',
      }));
    }
   if(newValues.pincode.length>=1&&newValues.pincode.length<6)
   {
     setErrors((prevErrors) => ({
      ...prevErrors,
      pincodeError: 'Pincode must be 6 digits',
     }));
    }
  };  
  const handleIfscBlur = () => {
    if (newValues.ifsc === '') {
    setErrors((prevErrors) => ({
      ...prevErrors,
      ifscError: '',
    }));
    } else if(errors.ifscError){
    setErrors((prevErrors) => ({
      ...prevErrors,
      ifscError: errors.ifscError,
    }));
   }
   else if (newValues.ifsc.length>=1&&newValues.ifsc.length<11) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      ifscError: 'IFSC must be a 11 digit number',
    }));
   } 
  };  
  const handlePincodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNewValues(prev => ({
        ...prev,
        pincode: value,
    }));
    const error = validatePincode(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      pincodeError: error,
    }));
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const password = newValues.password;
     setNewValues(prev => ({
      ...prev,
      password: value,
     }));
     if(!newValues.confirmPassword){
    const error = validatePassword(value,'');
    setErrors((prevErrors) => ({
    ...prevErrors,
    passwordError: error,
    }));
    }
    if(newValues.confirmPassword&&(password!==value)){
      const error = validatePassword(value,newValues.confirmPassword);
      setErrors((prevErrors) => ({
      ...prevErrors,
      passwordError: error,
      }));
    }
  };
  const handleStateChange = (event) => {
    const { value } = event.target;
    const state = value.toUpperCase();
     setNewValues(prev => ({
      ...prev,
      state: state,
     }));
     if (state === '') {
      setErrors((prevErrors) => ({
      ...prevErrors,
      stateError: ''
      }));
      return;
     } else if (validateDriverName(state)) {
      setErrors((prevErrors) => ({
      ...prevErrors,
      stateError:  ''
      }));
    } else {
      setErrors((prevErrors) => ({
      ...prevErrors,
      stateError:  'Only alphabets are allowed'
      }));
    }
  };
  const handleIfscChange = (event) => {
    const { value } = event.target;
    const ifsc = value.toUpperCase();
    setNewValues(prev => ({
      ...prev,
      ifsc: ifsc,
    }));
    if (ifsc === '') {
      setErrors((prevErrors) => ({
      ...prevErrors,
      ifscError: '',
      }));
      return; 
    } else  {
      const newError = validateIFSC(ifsc);
      setErrors((prevErrors) => ({
      ...prevErrors,
      ifscError: newError,
     }));
    }
  };
  const handleCinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const cin = value.toUpperCase();
      setNewValues(prev => ({
        ...prev,
        cin: cin,
      }));
      if (cin === '') {
        setErrors((prevErrors) => ({
         ...prevErrors,
        cinError: '',
        }));
        return; 
      } else  {
        const newError = validateCin(cin);
        setErrors((prevErrors) => ({
        ...prevErrors,
        cinError: newError,
        }));
      }
  };
  const handleGSTChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const gst = value.toUpperCase();
      setNewValues(prev => ({
      ...prev,
      gst: gst,
      }));
      if (gst === '') {
        setErrors((prevErrors) => ({
        ...prevErrors,
        gstError: '',
        }));
        return; 
      } else  {
        if(newValues.pan){
         const newError = validateGST(gst,newValues.pan);
         setErrors((prevErrors) => ({
          ...prevErrors,
          gstError: newError,
          }));
          return ;
        }
        else {
          const newError = validateGST(gst,'');
          setErrors((prevErrors) => ({
          ...prevErrors,
          gstError: newError,
          }));
          return ;
        }
     }
  };
  const handlePanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const pan = value.toUpperCase();
      setNewValues(prev => ({
      ...prev,
      pan: pan,
      }));
      if (pan === '') {
        setErrors((prevErrors) => ({
        ...prevErrors,
        panError: '',
        }));
        return; 
      } else  {
        const newError = validatePan(pan);
        setErrors((prevErrors) => ({
        ...prevErrors,
        panError: newError,
        }));
      }
  };
  const handleTanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const tan = value.toUpperCase();
      setNewValues(prev => ({
      ...prev,
      tan: tan,
      }));
      if (tan === '') {
        setErrors((prevErrors) => ({
        ...prevErrors,
        tanError: '',
        }));
        return; 
      } else  {
        const newError = validateTan(tan);
        setErrors((prevErrors) => ({
        ...prevErrors,
        tanError: newError,
       }));
      }
  };
  const handleCinBlur = () => {
     if (newValues.cin === '') {
       setErrors((prevErrors) => ({
        ...prevErrors,
        cinError: '',
       }));
      } else if(errors.cinError){
        setErrors((prevErrors) => ({
        ...prevErrors,
        cinError: errors.cinError,
        }));
      }
      else if (newValues.cin.length>=1&&newValues.cin.length<21) {
        setErrors((prevErrors) => ({
        ...prevErrors,
        cinError: 'CIN must be a 21 digit number',
        }));
      } 
  };
  const handlePanBlur = () => {
    if (newValues.pan === '') {
       setErrors((prevErrors) => ({
      ...prevErrors,
      panError: '',
      }));
    } else if(errors.panError){
      setErrors((prevErrors) => ({
      ...prevErrors,
      panError: errors.panError,
      }));
    }
    else if (newValues.pan.length>=1&&newValues.pan.length<10) {
      setErrors((prevErrors) => ({
      ...prevErrors,
       panError: 'PAN must be a 10 digit number',
      }));
    } 
  };
  const handleTanBlur = () => {
      if (newValues.tan === '') {
        setErrors((prevErrors) => ({
        ...prevErrors,
        tanError: '',
        }));
      } else if(errors.tanError){
          setErrors((prevErrors) => ({
          ...prevErrors,
          tanError: errors.tanError,
        }));
      }
      else if (newValues.tan.length>=1&&newValues.tan.length<10) {
        setErrors((prevErrors) => ({
        ...prevErrors,
        tanError: 'TAN must be a 10 digit number',
        }));
      } 
  };
  const handleGstBlur = () => {
      if (newValues.gst === '') {
        setErrors((prevErrors) => ({
        ...prevErrors,
        gstError: '',
      }));
      } else if(errors.gstError){
        setErrors((prevErrors) => ({
        ...prevErrors,
        gstError: errors.gstError,
        }));
      }
      else if (newValues.gst.length>=1&&newValues.gst.length<15) {
        setErrors((prevErrors) => ({
        ...prevErrors,
        gstError: 'GST must be a 15 digit number',
        }));
      } 
  };
  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNewValues(prev => ({
      ...prev,
      confirmPassword: value,
    }));
    const error = validateConfirmpassword(value,newValues.password);
    setErrors((prevErrors) => ({
      ...prevErrors,
      confirmPasswordError: error,
    }));
  };
  const handlePasswordBlur = () => {
     if(newValues.password==='')
      {
         setErrors((prevErrors) => ({
         ...prevErrors,
         passwordError: '',
        }));
      }
     if(newValues.password.length>=1&&newValues.password.length<4)
      {
        setErrors((prevErrors) => ({
        ...prevErrors,
        passwordError: 'Password must be 4 digits',
       }));
     }
  };  
  const handleConfirmPasswordBlur = () => {
     if(newValues.confirmPassword==='')
     {
        setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPasswordError: '',
       }));
      }
    if(newValues.confirmPassword.length>=1&&newValues.confirmPassword.length<4)
     {
      setErrors((prevErrors) => ({
       ...prevErrors,
      confirmPasswordError: 'Password must be 4 digits',
      }));
    }
  }; 
  const handleAccountNumberBlur = () => {
    if (newValues.accountNumber === '') {
       setErrors((prevErrors) => ({
       ...prevErrors,
       accountNumberError: '',
       }));
    } else if(errors.accountNumberError){
       setErrors((prevErrors) => ({
        ...prevErrors,
        accountNumberError: errors.accountNumberError,
      }));
    }
    else if (newValues.accountNumber.length>=1&&newValues.accountNumber.length<9) {
       setErrors((prevErrors) => ({
        ...prevErrors,
        accountNumberError: 'Please enter atleast 9 digits',
       }));
    } 
  };
  const handleCompanyNameBlur = () => {
    const companyName = newValues.companyName || '';  
    const lowercaseCompanyName = companyName.toLowerCase(); 
  
    if (errors.companyNameError) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyNameError: errors.companyNameError,
      })); 
    }
  
    if (!errors.companyNameError && companyName) {
      setNewValues((prevState) => ({
        ...prevState,
        brands: companyName, // Auto-fill brands with companyName
      }));
      setBrandsError(false);
    }
  };
  
  const handleCompanyNameChange = (event) => {
    const { value } = event.target;
    setNewValues(prev => ({
      ...prev,
      companyName: value,
    }));
  
    if (value === '') {
      // Clear brands if companyName is deleted
      setNewValues(prev => ({
        ...prev,
        brands: '',
      }));
    }
  
    if (value === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyNameError: ''
      }));
    } else if (validateCompanyName(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyNameError: ''
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyNameError: 'Invalid Input'
      }));
    }
  };
  const panMatchingGst = (panNumber, gstNumber) => {
    return (
      panNumber.length === 10 &&
      gstNumber.length === 15 &&
      panNumber === gstNumber.slice(2, 12)
    );
  };
  const handleClickSave = () => {
      setErrors((prevError) => ({
        ...prevError,
        panError: !newValues.pan
        ? 'Pan Number is required' 
        : prevError.panError || '',
        tanError: !newValues.tan
        ? 'Tan Number is required' 
        : prevError.tanError || '',
        gstError: !newValues.gst 
        ? 'GST is required' 
        : prevError.gstError || '',
      }));
     if(!selectedFiles.logo||!selectedFiles.pan||!selectedFiles.tan||!selectedFiles.gst||!selectedFiles.cancelledCheque){
        toast.error("All files are mandatory");
        return;
      }
      if (errors.panError||errors.tanError||errors.gstError)
      {
        toast.error("Please resolve the errors");
        return;
      }
      if (newValues.pan && newValues.gst) {
        const panMatchesGst = panMatchingGst(newValues.pan, newValues.gst);
        if (!panMatchesGst) {
          toast.error("PAN number does not match with the GST number.");
          return;
        }
      }
      if(!errors.panError&&!errors.tanError&&!errors.gstError){
        addCompany
          (
            newValues.companyName,
            newValues.email,
            newValues.password,
            newValues.confirmPassword,
            newValues.mobileNumber,
            newValues.address,
            newValues.pincode,
            newValues.accountName,
            newValues.accountNumber,
            newValues.bankName,
            newValues.branchName,
            newValues.ifsc,
            newValues.contactNameOne,
            newValues.contactEmailOne,
            newValues.contactNumberOne,
            newValues.contactDesignationOne,
            newValues.contactNameTwo,
            newValues.contactEmailTwo,
            newValues.contactNumberTwo,
            newValues.contactDesignationTwo,
            newValues.contactNameThree,
            newValues.contactEmailThree,
            newValues.contactNumberThree,
            newValues.contactDesignationThree,
            newValues.pan,
            newValues.gst,
            newValues.tan,
            newValues.state,
            newValues.cin,
            type,
            newValues.brands,
            selectedFiles.logo,
            selectedFiles.pan,
            selectedFiles.gst,
            selectedFiles.tan,
            selectedFiles.cancelledCheque,
            (response: any) => {
              toast.success(response.message);
              onClose();
              resetState();
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            },
           (errMessage: any) => {
            const emailError = errMessage?.message?.errors?.email?.[0];
            const generalMessage = errMessage?.message?.message || errMessage?.message;
            if (emailError) {
              toast.error(emailError);
            } else if (generalMessage) {
              toast.error(generalMessage);
            }
            }
         );
      }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
     const fileInput = event.target; 
     const file = fileInput.files?.[0] || null;
     if (file) {
        if (file.size > maxFileSize) {
            toast.error("File size must not exceed 1 MB");
            resetFileSelection(type); 
            return;
        }
        if (!allowedFileTypes.includes(file.type)) {
            toast.error("File type not supported");
            resetFileSelection(type); 
            return;
        }
      }
    setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [type]: file
    }));
    setIcons((prevIcons) => ({
        ...prevIcons,
        [type]: file ? 'view' : 'upload'
    }));
    fileInput.value = '';
  };
  const resetFileSelection = (type: string) => {
    setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [type]: null
    }));
    setIcons((prevIcons) => ({
        ...prevIcons,
        [type]: 'upload'
    }));
  };
  const handleIconClick = (type: string, action: string) => {
    if (action === 'view') {
      const file = selectedFiles[type];
      if (file) {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } 
    }
    else if (action === 'remove') {
      resetFileSelection(type);
    }
  };
  const renderFileInput = (label: string, type: string) => {
    const isSpecialField = label === 'Cancelled Cheque' || label === 'Logo';
    const containerStyle = {
      display: 'flex',
      alignItems: 'center',
      ...(isSpecialField ? {  } : {})
    };
    const viewCloseStyle = {
      cursor: 'pointer',
      color: 'blue',
      marginRight: '10px',
      ...(isSpecialField ? { } : {})
    };
    return (
      <div key={type} style={containerStyle}>
         <div style={{ display: 'flex', alignItems: 'center'}}>
          <p style={{ marginRight: '10px', width: '180px' }}>{label}</p>
          <input
            accept="*"
            style={{ display: 'none' }}
            id={`${type}-file`}
            type="file"
            onChange={(e) => handleFileChange(e, type)}
          />
          <label htmlFor={`${type}-file`}>
            {icons[type] === 'upload' && (
              <Tooltip title="Upload File">
                <IconButton component="span">
                  <UploadIcon color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </label>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {icons[type] === 'view' && (
            <Tooltip title="View File">
              <span
                style={viewCloseStyle}
                onClick={() => handleIconClick(type, 'view')}
              >
                View
              </span>
            </Tooltip>
          )}
          {icons[type] === 'view' && (
            <Tooltip title="Remove File">
              <IconButton sx={{ color: 'red' }} component="span" onClick={() => handleIconClick(type, 'remove')}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
<Box
        sx={{
          marginLeft: {
            xs: '0px',
            sm: '-180px',
          },
        }}
      >          <Heading title= "Add Company"  closeIcon="true" onClose={onClose} />
        </Box>
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => handleTabChange(newValue)}
          className="tabs"
        >
       <Tab 
         label="Company Details" 
         className={`tab ${activeTab === 0 ? 'tab-selected' : 'tab-default'}`}
        />
        <Tab 
         label="Additional Details" 
         className={`tab ${activeTab === 1 ? 'tab-selected' : 'tab-default'}`}
        />
        <Tab 
        label="Bank Details" 
        className={`tab ${activeTab === 2 ? 'tab-selected' : 'tab-default'}`}
        />
        <Tab 
        label="Documents" 
        className={`tab ${activeTab === 3 ? 'tab-selected' : 'tab-default'}`}
        />
       </Tabs>
        <TabPanel value={activeTab} index={0}>
          <Box className="form-section">
            <Box className="form-group">
            <TextField
              variant="outlined"
              size="small"
              label={createMandatoryLabel('Company Name')}
              name="name"
              value={newValues.companyName}
              onChange={(e) => handleNameChange(e,'companyName')}
              onBlur={handleCompanyNameBlur}
              error={!!errors.companyNameError}
              helperText={errors.companyNameError}
              className="styled-textfield-width" 
            />
            <TextField
              variant="outlined"
              size="small"
              name="mobileNumber"
              label={createMandatoryLabel('Mobile Number')}
              value = {newValues.mobileNumber}
              onChange={(e) => handlePhoneNumberChange(e,'mobileNumber')}
              onBlur={handleMobileNumberBlur}
              error={!!errors.mobileNumberError}
              helperText={errors.mobileNumberError}
              className="styled-textfield-width" 
            />
            </Box>
            <Box className="form-group">
              <Dropdown 
              options={typesOptions}
              selectedValue={type}
              handleChange={handleTypeChange}
              placeholder="Select Type"
              label={createMandatoryLabel('Select Type')}
              width="225px"
              height="41px"
              error={typeError} 
              helperText={typeError ? 'This field is required' : ''} 
              >
              </Dropdown>
              <TextField
              variant="outlined"
              size="small"
              name="email"
              label={createMandatoryLabel('Email')}
              value = {newValues.email}
              onChange={(e) => handleEmailChange(e,'email')}
              onBlur={handleEmailBlur}
              error={!!errors.emailError}
              helperText={errors.emailError}
              className="styled-textfield-width" 
              />
            </Box>
            <Box className="form-group">
              <TextField
              variant="outlined"
              size="small"
              name="password"
              label={createMandatoryLabel('Password')}
              type="password" 
              value = {newValues.password}
              onChange={handlePasswordChange}
              onBlur={handlePasswordBlur}
              error={!!errors.passwordError}
              helperText={errors.passwordError}
              className="styled-textfield-width" 
              />
              <TextField
              variant="outlined"
              size="small"
              name="confirm password"
              label={createMandatoryLabel('Confirm Password')}
              type="password" 
              value = {newValues.confirmPassword}
              onChange={handleConfirmPasswordChange}
              onBlur={handleConfirmPasswordBlur}
              error={!!errors.confirmPasswordError}
              helperText={errors.confirmPasswordError}
              disabled={!newValues.password}
              className="styled-textfield-width" 
              />
            </Box>
            <Box className="form-group">
              <TextField
              variant="outlined"
              size="small"
              name="address"
              label={createMandatoryLabel('Address')}
              value = {newValues.address}
              onChange={ handleAddressChange}
              //onBlur={handleAddressBlur}
              error={!!errors.addressError}
              helperText={errors.addressError}
              className="styled-textfield-width" 
              />
              <TextField
              variant="outlined"
              size="small"
              name="pincode"
              label={createMandatoryLabel('Pincode')}
              value = {newValues.pincode}
              onChange={handlePincodeChange}
              onBlur={handlePincodeBlur}
              error={!!errors.pincodeError}
              helperText={errors.pincodeError}
              className="styled-textfield-width" 
              />
            </Box>
            <Box className="form-group">
 
      <TextField
   
        variant="outlined"
        size="small"
        name='brand_name'
        value={newValues.brands}
        label={createMandatoryLabel('Brands')}
        placeholder="Enter brand name"
        className="styled-textfield-width"
        error={!!brandsError || !!brandError}
        helperText={brandsError ? 'This field is required' : brandError}
        aria-readonly
      />
       <TextField
              variant="outlined"
              size="small"
              name="state"
              label={createMandatoryLabel('State')}
              value = {newValues.state}
              onChange={ handleStateChange}
              error={!!errors.stateError}
              helperText={errors.stateError}
              className="styled-textfield-width" 
              />
</Box>
            <Box className="form-group">
              <TextField
              variant="outlined"
              size="small"
              name="cin"
              label={createMandatoryLabel('CIN')}
              value = {newValues.cin}
              onChange={ handleCinChange}
              onBlur={handleCinBlur}
              error={!!errors.cinError}
              helperText={errors.cinError}
              className="styled-textfield-width"
              />
            </Box>
             <div style={{ marginLeft: '230px', marginTop: '5px' }}>
               <Button className='custom-button' onClick={() =>handleTabChange(1)}>
                   Next
               </Button>
                <div  style={{ marginLeft : '150px', marginTop:'-30px' ,fontSize: '12px', color: 'red' }}>
                  (All * fields are mandatory)
                </div>
             </div>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
             <Box className="form-section-additional">
               <Typography variant="h6" style={{ fontSize: '16px',marginBottom: '10px', marginTop: '-10px' }}>
                  Contact Person 1
               </Typography>
               <Box className="form-group-additional">
                  <TextField
                  variant="outlined"
                  size="small"
                  label="Name"
                  name="contactNameOne"
                  value={newValues.contactNameOne}
                  onChange={(e) => handleNameChange(e,'contactNameOne')}
                  error={!!errors.contactNameOneError}
                  helperText={errors.contactNameOneError}
                  className="styled-textfield-width" 
                  />
                  <TextField
                   variant="outlined"
                   size="small"
                   name="contactNumberOne"
                   label="Mobile Number"
                   value={newValues.contactNumberOne}
                   onChange={(e) => handlePhoneNumberChange(e,'contactNumberOne')}
                   onBlur={handleNumberOneBlur}
                   error={!!errors.contactNumberOneError}
                   helperText={errors.contactNumberOneError}
                   className="styled-textfield-width" 
                  />
                </Box>
                <Box className="form-group-additional">
                   <TextField
                   variant="outlined"
                   size="small"
                   name="contactEmailOne"
                   label={createMandatoryLabel('Email')}
                   value={newValues.contactEmailOne}
                   onChange={(e) => handleEmailChange(e,'contactEmailOne')}
                   onBlur={handleEmailOneBlur}
                   error={!!errors.contactEmailOneError}
                   helperText={errors.contactEmailOneError}
                   className="styled-textfield-width" 
                   />
                   <TextField
                   variant="outlined"
                   size="small"
                   name="contactDesignationOne"
                   label="Designation"
                   value={newValues.contactDesignationOne}
                   onChange={(e) => handleNameChange(e,'contactDesignationOne')}
                   error={!!errors.contactDesignationOneError}
                   helperText={errors.contactDesignationOneError}
                   className="styled-textfield-width" 
                  />
                </Box>
               <Typography variant="h6" style={{ fontSize: '16px',marginBottom: '10px', marginTop: '-10px' }}>
                    Contact Person 2
               </Typography>
               <Box className="form-group-additional">
                 <TextField
                 variant="outlined"
                 size="small"
                 label="Name"
                 name="contactNameTwo"
                 value={newValues.contactNameTwo}
                 onChange={(e) => handleNameChange(e,'contactNameTwo')}
                 error={!!errors.contactNameTwoError}
                 helperText={errors.contactNameTwoError}
                 className="styled-textfield-width" 
                 />
                 <TextField
                 variant="outlined"
                 size="small"
                 name="contactMobileTwo"
                 label="Mobile Number"
                 value={newValues.contactNumberTwo}
                 onChange={(e) => handlePhoneNumberChange(e,'contactNumberTwo')}
                 onBlur={handleNumberTwoBlur}
                 error={!!errors.contactNumberTwoError}
                 helperText={errors.contactNumberTwoError}
                 className="styled-textfield-width" 
                 />
               </Box>
               <Box className="form-group-additional">
                 <TextField
                 variant="outlined"
                 size="small"
                 name="contactEmailTwo"
                 label="Email"
                 value={newValues.contactEmailTwo}
                 onChange={(e) => handleEmailChange(e,'contactEmailTwo')}
                 onBlur={handleEmailTwoBlur}
                 error={!!errors.contactEmailTwoError}
                 helperText={errors.contactEmailTwoError}
                 className="styled-textfield-width" 
                />
                <TextField
                variant="outlined"
                size="small"
                name="contactDesignationTwo"
                label="Designation"
                onChange={(e) => handleNameChange(e,'contactDesignationTwo')}
                value={newValues.contactDesignationTwo}
                error={!!errors.contactDesignationTwoError}
                helperText={errors.contactDesignationTwoError}
                className="styled-textfield-width" 
                />
               </Box>
              <Typography variant="h6" style={{fontSize: '16px', marginBottom: '10px', marginTop: '-10px' }}>
                    Contact Person 3
              </Typography>
              <Box className="form-group-additional">
                <TextField
                variant="outlined"
                size="small"
                label="Name"
                name="contactNameThree"
                value={newValues.contactNameThree}
                onChange={(e) => handleNameChange(e,'contactNameThree')}
                error={!!errors.contactNameThreeError}
                helperText={errors.contactNameThreeError}
                className="styled-textfield-width" 
                />
                <TextField
                variant="outlined"
                size="small"
                name="contactMobileThree"
                label="Mobile Number"
                value={newValues.contactNumberThree}
                onChange={(e) => handlePhoneNumberChange(e,'contactNumberThree')}
                onBlur={handleNumberThreeBlur}
                error={!!errors.contactNumberThreeError}
                helperText={errors.contactNumberThreeError}
                className="styled-textfield-width" 
                />
              </Box>
              <Box className="form-group-additional">
                <TextField
                variant="outlined"
                size="small"
                name="contactEmailThree"
                label="Email"
                value={newValues.contactEmailThree}
                onChange={(e) => handleEmailChange(e,'contactEmailThree')}
                onBlur={handleEmailThreeBlur}
                error={!!errors.contactEmailThreeError}
                helperText={errors.contactEmailThreeError}
                className="styled-textfield-width" 
                />
                <TextField
                variant="outlined"
                size="small"
                name="contactDesignationThree"
                label="Designation"
                value={newValues.contactDesignationThree}
                onChange={(e) => handleNameChange(e,'contactDesignationThree')}
                error={!!errors.contactDesignationThreeError}
                helperText={errors.contactDesignationThreeError}
                className="styled-textfield-width" 
                />
              </Box>
           <div style={{ marginLeft: '230px', marginTop: '5px' }}>
               <Button className="custom-button"  onClick={() =>handleTabChange(2)}>
                     Next
               </Button>
              <div  style={{ marginLeft : '150px', marginTop:'-30px' ,fontSize: '12px', color: 'red' }}>
                  (All * fields are mandatory)
              </div>
           </div>
          </Box>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
             <Box className="form-section">
                 <Box className="form-group">
                  <TextField
                  variant="outlined"
                  size="small"
                  label={createMandatoryLabel('Bank Name')}
                  name="bankName"
                  value={newValues.bankName}
                  onChange={(e) => handleBankNameChange(e)}
                  error={!!errors.bankNameError}
                  helperText={errors.bankNameError}
                  className="styled-textfield-width" 
                  />
                  <TextField
                  variant="outlined"
                  size="small"
                  name="branchName"
                  label={createMandatoryLabel('Branch Name')}
                  value = {newValues.branchName}
                  onChange={(e) => handleBranchNameChange(e)}
                  error={!!errors.branchNameError}
                  helperText={errors.branchNameError}
                  className="styled-textfield-width" 
                 />
                </Box>
                <Box className="form-group">
                 <TextField
                 variant="outlined"
                 size="small"
                 name="accountNumber"
                 label={createMandatoryLabel('Account Number')}
                 value = {newValues.accountNumber}
                 onChange={(e) => handleAccountNumberChange(e)}
                 onBlur={handleAccountNumberBlur}
                 error={!!errors.accountNumberError}
                 helperText={errors.accountNumberError}
                 className="styled-textfield-width" 
                 />
                 <Dropdown 
                  options={accountTypesOptions}
                  selectedValue={accountType}
                  handleChange={handleAccountTypeChange}
                  placeholder="Select Account Type"
                  label={createMandatoryLabel('Select Account Type')}
                  width="224px"
                  height="41px"
                  error={accountTypeError} 
                  helperText={accountTypeError ? 'This field is required' : ''} 
                >
                </Dropdown>
               </Box>
               <Box className="form-group">
                <TextField
                variant="outlined"
                size="small"
                name="ifsc"
                label={createMandatoryLabel('IFSC')}
                value = {newValues.ifsc}
                onChange={(e) => handleIfscChange(e)}
                onBlur={handleIfscBlur}
                error={!!errors.ifscError}
                helperText={errors.ifscError}
                className="styled-textfield-width" 
                />
               </Box>
              <div style={{ marginLeft: '250px', marginTop: '5px' }}>
                <Button className='custom-button' onClick={() =>handleTabChange(3)}>
                   Next
                </Button>
                <div  style={{ marginLeft : '150px', marginTop:'-30px' ,fontSize: '12px', color: 'red' }}>
                    (All * fields are mandatory)
                </div>
             </div>
            </Box>
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
              <Box className="form-section-icon">
              <Box className="form-group-icon">
                <Box className="form-input-icon" display="flex" alignItems="center" justifyContent="space-between">
                   {renderFileInput('PAN', 'pan')} 
                   <TextField
                    variant="outlined"
                    size="small"
                    name="pan"
                    label={createMandatoryLabel('PAN')}
                    value = {newValues.pan}
                    onChange={ handlePanChange}
                    onBlur={handlePanBlur}
                    error={!!errors.panError}
                    helperText={errors.panError}
                    className="styled-textfield-width" 
                   />
                </Box>
                <Box className="form-input-icon" display="flex" alignItems="center" justifyContent="space-between">
                    {renderFileInput('TAN', 'tan')} 
                    <TextField
                    variant="outlined"
                    size="small"
                    name="tan"
                    label={createMandatoryLabel('TAN')}
                    value = {newValues.tan}
                    onChange={ handleTanChange}
                    onBlur={handleTanBlur}
                    error={!!errors.tanError}
                    helperText={errors.tanError}
                    className="styled-textfield-width" 
                    />
                </Box>
                <Box className="form-input-icon" display="flex" alignItems="center" justifyContent="space-between">
                    {renderFileInput('GST', 'gst')} 
                     <TextField
                     variant="outlined"
                     size="small"
                     name="gst"
                     label={createMandatoryLabel('GST')}
                     value = {newValues.gst}
                     onChange={handleGSTChange}
                     onBlur={handleGstBlur}
                     error={!!errors.gstError}
                     helperText={errors.gstError}
                     className="styled-textfield-width" 
                     />
                </Box>
                <Box className="form-input-icon">
                   {renderFileInput('Cancelled Cheque', 'cancelledCheque')} 
                </Box>
                <Box className="form-input-icon">
                   {renderFileInput('Logo', 'logo')} 
                </Box>
              </Box>
             </Box>
             <div style={{ marginLeft: '210px', marginTop: '5px' }}>
               <Button  className='custom-button' onClick={handleClickSave}>
                  Save
               </Button>
            </div>
            <div style={{ marginLeft: '280px', marginTop: '-38px' }}>
              <Button  className='custom-cancel-button' onClick={handleClickCancel}>
                  cancel
              </Button>
                 <div  style={{ marginLeft : '120px', marginTop:'-30px' ,fontSize: '12px', color: 'red' }}>
                  (All * fields are mandatory)
                    </div>
            </div>
          </TabPanel>
        </DialogContent>
       </Dialog>
     );
};

const TabPanel = ({ children, value, index }) => {
  return value === index && <Box>{children}</Box>;
};

export default AddCompanyModal;
