import React, { FC, useState,useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel, Box } from '@mui/material';
import { getWarehouses,userWarehouseMapping,getWarehouseMapping,deleteWarehouseUserMapping} from './action.ts';
import Heading from '../../Components/Heading/heading.tsx';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface Warehouse {
    id: string;
    name: string;
}

interface WarehousesModalProps {
    open: boolean;
    onClose: () => void;
    userId:any;
    source: 'addUserModal' | 'userPage';

}

const WarehousesModal: FC<WarehousesModalProps> = ({ open, onClose,userId,source}) => {
    const [selectedWarehouses, setSelectedWarehouses] = useState<string[]>([]);
    const [warehouses, setWarehouses] = useState<any[]>([]);
    const [warehouseId, setWarehouseId] = useState('');

    useEffect(() => {
        getWarehouses(
            (response) => {
                if (response.status === 200) {
                    setWarehouses(response.message.warehouses);
                    setWarehouseId(response.message.warehouses.id);
                } else {
                    toast.error("Failed to fetch warehouses");
                }
            },
            (errorMessage) => {
                toast.error(errorMessage.message);
            }
        );
        if (source === 'userPage' && userId) {
            getWarehouseMapping(userId, 0, "100", 
                (response) => {
                    if (response.status === 200) {
                        const mappedWarehouseIds = response.user_warehouses.map((mapping: any) => mapping.warehouse.id);
                    setSelectedWarehouses(mappedWarehouseIds)
                    } else {
                        toast.error("Failed to fetch warehouses");
                    }
                },
                (errorMessage) => {
                    toast.error(errorMessage);
                });
        }
    }, [open,userId,source]);
   
    const handleCheckboxChange = (warehouseId: string) => {
        const isCurrentlySelected = selectedWarehouses.includes(warehouseId);
        if (isCurrentlySelected) {
            deleteWarehouseUserMapping(warehouseId,
                (response) => {
                    toast.success(`Warehouse ${warehouseId} unmapped successfully!`);
                },
                (errorMessage) => {
                    toast.error(`Failed to unmap warehouse ${warehouseId}: ${errorMessage}`);
                }
            );
        } 
        setSelectedWarehouses((prevSelected) =>
            isCurrentlySelected
                ? prevSelected.filter((id) => id !== warehouseId)
                : [...prevSelected, warehouseId]
        );
    };
    const handleSubmit = () => {
        if (selectedWarehouses.length === 0) {
            toast.error("Please select at least one warehouse.");
            return;
        }
        selectedWarehouses.forEach((id) => {
            userWarehouseMapping(userId, id, 
                (response) => {
                    toast.success(`Warehouse ${id} mapped successfully!`);
                },
                (errorMessage) => {
                    toast.error(`Failed to map warehouse ${id}: ${errorMessage}`);
                }
            );
        });
    
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle  sx={{padding:'0px'}}>
                <Box sx={{ marginLeft: { xs: '0px', sm: '-180px' } }}>
                    <Heading title="Warehouses" closeIcon="true" onClose={onClose} />
                </Box>
            </DialogTitle>
            <DialogContent>
                {warehouses.map((warehouse) => (
                    <FormControlLabel
                        key={warehouse.id}
                        control={
                            <Checkbox
                                checked={selectedWarehouses.includes(warehouse.id)}
                                onChange={() => handleCheckboxChange(warehouse.id)}
                            />
                        }
                        label={warehouse.name}
                    />
                ))}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    onClick={onClose}
                    className="custom-cancel-button"
                    style={{ marginRight: '10px' }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    className="custom-button"
                    style={{ marginLeft: '10px' }}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WarehousesModal;