import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import { FaUserTie, FaUser } from 'react-icons/fa'; 

import {uniqBy, sortBy} from "lodash";
import { BiTrip } from "react-icons/bi"
import companyIcon from '../../assets/company_icon.png';
const BASE_URL = `${window.location.origin}`;
const storedUserDetails = localStorage.getItem("userdetails");
const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
const userRole = userDetails ? userDetails.role.toLowerCase() : null;

export const FinalSidebarData = [
  {
    title: 'Dashboard',
    path: '' + BASE_URL + '/dashboard',
    icon: <AiIcons.AiFillHome style={{ color: 'black' }} />,   
     cName: 'nav-text'
  },
  {
    title: 'Orders',
    path: '',
    icon: <FaIcons.FaFileUpload  style={{ color: 'black' }} />,   
    cName: 'nav-text'
  },
  {
    title: 'Trips',
    path: '' + BASE_URL + '/trips',
    icon: <BiTrip style={{ color: 'black' }} />,   
    cName: 'nav-text'
  },
  {
    title: 'Vehicles',
    path: '' + BASE_URL + '/vehicles',
    icon: <FaIcons.FaCarAlt style={{ color: 'black' }} />,   
    cName: 'nav-text'
  },
  {
    title: 'Drivers',
    path: '' + BASE_URL + '/drivers',
    icon: <FaIcons.FaUser style={{ color: 'black' }} />,   
    cName: 'nav-text'
  },
  {
    title: 'Routes',
    path: '',
    icon: <FaIcons.FaMapMarkerAlt style={{ color: 'black' }} />,   
    cName: 'nav-text'
  },
  {
    title: 'Offers',
    path: '',
    icon: <FaIcons.FaGift style={{ color: 'black' }} />,   
    cName: 'nav-text'
  },
  
  {
    title: 'Reports',
    path: '',
    icon:  <IoIcons.IoIosPaper style={{ color: 'black' }} />,   
    cName: 'nav-text'
  },
  {
    title: 'Whatsapp',
    path: '',
    icon: <FaIcons.FaWhatsapp style={{ color: 'black' }} />,   
    cName: 'nav-text'
  },
  {
    title: 'Companies',
    path: '' + BASE_URL + '/company',
    icon: <img src={companyIcon} alt="Company Icon" style={{ width: '20px', height: '30px' }} />,  
    cName: 'nav-text'
  },
  {
    title: 'Clients',
    path: '' + BASE_URL + '/clients',
    icon: <IoIcons.IoIosPeople style={{color:'black' , scale:'1.5'}} />,  
    cName: 'nav-text'
  },
  {
    title: 'Vendors',
    path: '' + BASE_URL + '/vendors',
    icon: <img src={companyIcon} alt="Company Icon" style={{ width: '20px', height: '30px' }} />,  
    cName: 'nav-text'
  },
  {
    title: 'Warehouses',
    path: '' + BASE_URL + '/warehouses',
    icon: <FaIcons.FaWarehouse style={{ color:'black'}} />,  
    cName: 'nav-text'
  },
  {
    title: 'Users',
    path: '' + BASE_URL + '/users',
    icon: <FaIcons.FaUserShield style={{color:'black'}} />,  
    cName: 'nav-text'
  },
];
const filteredSidebarData = FinalSidebarData.filter(item => {
  console.log(userRole);
  if (userRole === 'vendor_supervisor') {
    return item.title !== 'Companies' && item.title !== 'Vendors' && item.title !== 'Clients' && item.title!== 'Warehouses' && item.title !== 'Users';
  } else if (userRole === 'platform_user') {
    return item.title === 'Companies';
  } else if (userRole === 'vendor_company_admin') {
    return item.title === 'Clients' || item.title === 'Warehouses' || item.title === 'Users';
  } else if (userRole === 'client_company_admin') {
    return item.title === 'Vendors';
  }
  return true;
});

const FinallySidebarData = uniqBy(filteredSidebarData, 'title');
export default FinallySidebarData;