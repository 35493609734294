import React, { FC, useState,useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box } from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import { addWarehouses,getClients,warehouseClientMapping } from './action.ts';
import {validateEmail} from '../../Utils/EmailValidation/email-validation.tsx';
import {validateAddress} from '../../Utils/AddressValidation/address-validation.tsx';
import {validateDriverName} from '../../Utils/NameValidation/name-validation.tsx';
import { validateRemarks } from '../../Utils/RemarksValidation/remarks-validation.tsx';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SelectChangeEvent } from '@mui/material/Select';

interface AddWarehousesModalProps {
    open: boolean;
    onClose: () => void;
}

const AddWarehousesModal: FC<AddWarehousesModalProps> = ({ open, onClose }) => {
    const [warehouseName, setWarehouseName] = useState('');
    const [contactName, setContactName] = useState<string>('');
    const [warehouseNameError, setWarehouseNameError] = useState<string>('');
    const [contactNameError, setContactNameError] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [remarks, setRemarks] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [remarksError, setRemarksError] = useState<string>('');
    const [mobileNumber , setMobileNumber] = useState<string>('');
    const [mobileNumberError , setMobileNumberError] = useState<string>('');
    const [location , setLocation] = useState<string>('');
    const [locationError , setLocationError] = useState<string>('');
    const [clientOptions,setClientOptions]= useState<{id: string , label: string}[]>([]);
    const [selectedClient, setSelectedClient] = useState<{ id: string, name: string } | null>(null);
    const [clientNameError, setClientNameError] = useState<string>('');

    const resetState = () => {
        setWarehouseName('');
        setWarehouseNameError('');
        setContactName('');
        setContactNameError('');
        setEmail('');
        setEmailError('');
        setMobileNumber('');
        setMobileNumberError('');
        setLocation('');
        setLocationError('');
        setRemarks('');
        setRemarksError('');
        setSelectedClient(null);
        setClientNameError('');
      };
      const handleEmailBlur = () => {
        if (email === '') {
          setEmailError('');
        } else if (validateEmail(email)) {
          setEmailError('');
        } else {
          setEmailError('Please enter a valid email address.');
        }
    };
    const handleSave = () => {
      let hasError = false;
  
      if (!warehouseName) {
          setWarehouseNameError('Warehouse Name is required');
          hasError = true;
      }
      if (!contactName) {
          setContactNameError('Contact Name is required');
          hasError = true;
      }
      if (!mobileNumber) {
          setMobileNumberError('Mobile number is required');
          hasError = true;
      }
      if (!location) {
          setLocationError('Location is required');
          hasError = true;
      }
      if (!email) {
          setEmailError('Email is required');
          hasError = true;
      }
      if(!remarks){
        setRemarksError('Remarks is Required');
          hasError = true;
      }
      if(!selectedClient){
        setClientNameError('Client is Required');
        hasError = true;
      }
      if (clientNameError || warehouseNameError || contactNameError || emailError || mobileNumberError || locationError || remarksError) {
          toast.error("Please fix the errors");
          return;
      }
  
      if (hasError) {
          toast.error("Please fix the errors");
          return;
      }
  
      addWarehouses(warehouseName, contactName, '3', '2', remarks, mobileNumber, email,
          (response) => {
              toast.success(response.message);
              if(response.status===201){
                const warehouseId = response.warehouse.id
                warehouseClientMapping(selectedClient?.id,warehouseId, (response) => {
                  toast.success(response);
                },
                (errorMessage) => {
                  if (errorMessage) {
                    toast.error(errorMessage);
                  }})
              }
              resetState(); 
              setTimeout(() => {
                window.location.reload();
            }, 3000);
              onClose();  
          },
          (errorMessage) => {
            if(errorMessage.message.errors.name){
              toast.error(errorMessage.message.errors.name[0]);}
          }
      );
  };
  
    const handleCancel = () =>{
        resetState();
        onClose();
      };  
      const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setLocation(value);
        if(value === ''){
            setLocationError('');
        } else if (validateAddress(value)){
            setLocationError('')
        } else
        setLocationError('Invalid Address');
     };
     const handleContactNameChange = (event) => {
        const { value } = event.target;
        setContactName(value);
        if (value === '') {
          setContactNameError('');
        } else if (validateDriverName(value)) {
          setContactNameError('');
        } else {
          setContactNameError('Invalid Character');
        }
    };
      const handleWarehouseNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setWarehouseName(value);
      if (value === '') {
          setWarehouseNameError('');
      } else if (validateRemarks(value)) {
          setWarehouseNameError('');
      } else {
          setWarehouseNameError('Invalid Character');
      }
  };
    const handleEmailChange = (event) => {
        const { value } = event.target;
        setEmail(value);
        setEmailError('');
    };
    const handleRemarksChange = (event) => {
      const { value } = event.target;
      setRemarks(value);
      if (value === '') {
        setRemarksError('');
      } else if (validateRemarks(value)) {
        setRemarksError('');
      } else {
        setRemarksError('Invalid Character');
      }
    };
    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setMobileNumber(value);
        if (value === '') {
            setMobileNumberError('');
            return;
        }
        const { errorMessage } = validatePhoneNumber(value);
        setMobileNumberError(errorMessage || '');
    };
    const handleClientChange = (event: SelectChangeEvent<string>) => {
      const { value } = event.target;
      const clientId = event.target.value;
      const client = clientOptions.find(c => c.id === clientId);
      const transformedClient = client ? { id: client.id, name: client.label} : null;
      setSelectedClient(transformedClient);
      if(value !== ''){
        setClientNameError('')
          return;
      }
    }
    const handleLocationBlur = () => {
      if(location==='')
      {
        setLocationError("");
      }
    };  
    const handlePhoneNumberBlur = () => {
      if (mobileNumber === '') {
        setMobileNumberError('');
      } else if (mobileNumber.length>=1&&mobileNumber.length<10) {
        setMobileNumberError('Please enter a 10 digit number');
      } 
    };
    const handleWarehouseNameBlur = () => {
        if (warehouseName=== '') {
          setWarehouseNameError('');
        } else  {
          setWarehouseNameError(warehouseNameError);
        } 
    };
    const handleRemarksBlur = () => {
      if (remarks=== '') {
        setRemarksError('');
      } else  {
        setRemarksError(remarksError);
      } 
  };
    const fetchClients = () => {
      getClients(
        (response:any) => {
          const options = response.message.clients.map ((clients:any) => ({
            id:clients.id,
            label:clients.name,
          }));
          setClientOptions(options);
        },
        (errMessage:any) => {
          toast.error(errMessage);
        }
      );
    };
    useEffect (() => {
      fetchClients();
    },[])
   
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{padding:'0px'}}>
              <Box sx={{marginLeft: {xs: '0px',sm: '-180px',},}}>    
                <Heading title="Add Warehouses" closeIcon="true" onClose={onClose} />
              </Box>
            </DialogTitle>
            <DialogContent>
              <div style={{ marginLeft: '-10px', marginTop: '5px' }}>                     <p style={{ fontSize: '17px' }}>Client Name</p>                 </div>
                <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                  <Box display="flex" alignItems="center">
                    <Dropdown
                      options={clientOptions}
                      selectedValue={selectedClient?.id || ''}     
                      handleChange={(event) => handleClientChange(event)}
                      placeholder="Choose Client"
                      label="Choose Client"
                      width='222.4px'
                      height='40px'
                      error={!!clientNameError}
                      helperText={clientNameError}
                    />
                  </Box>
                </div>
                <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
                  <p style={{ fontSize: '17px' }}>Warehouse Name</p>
                </div>
                <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                  <Box display="flex" alignItems="center">
                    <TextField
                      size='small'
                      name='warehouse_name'
                      placeholder='Warehouse Name'
                      value={warehouseName}
                      onChange={handleWarehouseNameChange}
                      onBlur={handleWarehouseNameBlur}
                      error={!!warehouseNameError}
                      helperText={warehouseNameError}
                      variant="outlined"
                    />
                  </Box>
                </div>
                <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
                  <p style={{ fontSize: '17px' }}>Contact Name</p>
                </div>
                <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                  <Box display="flex" alignItems="center">
                    <TextField
                      size='small'
                      name='contact_name'
                      placeholder='Contact Name'
                      value={contactName}
                      onChange={ handleContactNameChange}
                      onBlur={handleWarehouseNameBlur}
                      error={!!contactNameError}
                      helperText={contactNameError}
                      variant="outlined"
                    />
                  </Box>
                </div>
                <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
                  <p style={{ fontSize: '17px' }}>Mobile Number</p>
                </div>
                <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                  <Box display="flex" alignItems="center">
                    <TextField
                      size="small"
                      name="mobileNumber"
                      placeholder="Mobile Number"
                      value={mobileNumber}
                      onChange={handlePhoneNumberChange}
                      onBlur={handlePhoneNumberBlur}
                      error={!!mobileNumberError}
                      helperText={mobileNumberError}
                      variant="outlined"
                    />
                  </Box>
                </div>
                <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
                  <p style={{ fontSize: '17px' }}>Location</p>
                </div>
                <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                <Box display="flex" alignItems="center">
                  <TextField
                    size='small'
                    name='location'
                    placeholder='Location'
                    value={location}
                    onChange={ handleLocationChange}
                    onBlur={handleLocationBlur}
                    error={!!locationError}
                    helperText={locationError}
                    variant="outlined"
                  />
                </Box>
              </div>
              <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
                <p style={{ fontSize: '17px' }}>Email</p>
              </div>
              <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                <Box display="flex" alignItems="center">
                  <TextField
                    size='small'
                    name='email'
                    placeholder='Email'
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    error={!!emailError}
                    helperText={emailError}
                    variant="outlined"
                  />
                </Box>
              </div>
              <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
                <p style={{ fontSize: '17px' }}>Remarks</p>
              </div>
              <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                <Box display="flex" alignItems="center">
                  <TextField
                    size='small'
                    name='remarks'
                    placeholder='Remarks'
                    value={remarks}
                    onChange={handleRemarksChange}
                    onBlur={handleRemarksBlur}
                    error={!!remarksError}
                    helperText={remarksError}
                    variant="outlined"
                  />
                </Box>
              </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                onClick={handleCancel}
                className="custom-cancel-button"
                style={{ marginRight: '10px' }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                className="custom-button"
                style={{ marginLeft: '10px' }}
              >
                Save
              </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddWarehousesModal;
