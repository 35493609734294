import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, Button, TextField, IconButton, Tooltip,Box,SelectChangeEvent,Typography } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Heading from '../../Components/Heading/heading.tsx';
import { validatePhoneNumber } from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import { editOwner,editOwnerDetails } from './action.ts';
import { validateAccountNumber} from '../../Utils/AccountNumberValidation/account-number-validation.tsx';
import { validateAddress } from '../../Utils/AddressValidation/address-validation.tsx';
import { validateIFSC } from '../../Utils/IFSCValidation/ifsc-validation.tsx';
import { validateDriverName } from '../../Utils/NameValidation/name-validation.tsx';
import { validateRemarks } from '../../Utils/RemarksValidation/remarks-validation.tsx';
import { validateEmail } from '../../Utils/EmailValidation/email-validation.tsx';

interface EditOwnerModalProps {
  open: boolean;
  onClose: () => void;
  ownerData: any;
  ownerId: number;
  fetchOwners: (phone?: string, page?: number, pageSize?: number) => void;
}

const EditOwnerModal: React.FC<EditOwnerModalProps> = ({ open, onClose, ownerData, ownerId, fetchOwners }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [ifsc, setIfsc] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [remarks, setRemarks] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [ifscError, setIfscError] = useState('');
  const [remarksError, setRemarksError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    if (ownerData) {
      setName(ownerData.name || '');
      setPhone(ownerData.phone || '');
      setEmail(ownerData.email || '');
      setAccountNumber(ownerData.account_number || '');
      setIfsc(ownerData.ifsc || '');
      setAddress(ownerData.address || '')
      setRemarks(ownerData.remarks || '');
    }
  }, [ownerData]);
  const resetFields = () => {
    setNameError('');
    setEmailError('');
    setAddressError('');
    setAccountNumberError('');
    setIfscError('');
    setRemarksError('');
    setPhoneError('');
  };
  const handleNameChange = (event) => {
    const { value } = event.target;
    setName(value);
    if (value === '') {
      setNameError('');
    } else if (validateDriverName(value)) {
      setNameError('');
    } else {
      setNameError('Characters are only allowed');
    }
  };

  const handleNameBlur = () => {
    if (nameError === '') {
      setNameError('');
    } else {
      setNameError(nameError);
    }
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setEmailError('');
  };

  const handleEmailBlur = () => {
    if (email === '') {
      setEmailError('');
    } else if (validateEmail(email)) {
      setEmailError('');
    } else {
      setEmailError('Please enter a valid email address.');
    }
  };
const handlePhoneNumberChange = (event) => {
        const { value } = event.target;
        setPhone(event.target.value);
        if(value==='')
        {
          setPhoneError("");
        }
        else{
        const { errorMessage } = validatePhoneNumber(value);
        setPhoneError(errorMessage);}
    };  
    const handlePhoneNumberBlur = () => {
      if (phone === '') {
        setPhoneError('');
      } else if (phone.length>=1&&phone.length<10) {
        setPhoneError('Please enter a 10 digit number');
      } 
    };
  const handleRemarksChange = (event) => {
    const { value } = event.target;
    setRemarks(value);
    if (value === '') {
      setRemarksError('');
    } else if (validateRemarks(value)) {
      setRemarksError('');
    } else {
      setRemarksError('Invalid Character');
    }
  };

  const handleAccountNumberChange = (event) => {
    const { value } = event.target;
    setAccountNumber(value);
    if (value === '') {
      setAccountNumberError('');
    } else {
      const errorMessage = validateAccountNumber(value);
      setAccountNumberError(errorMessage);
    }
  };

  const handleAccountNumberBlur = () => {
    if (accountNumber === '') {
      setAccountNumberError('');
    } else if (accountNumber.length >= 1 && accountNumber.length < 9) {
      setAccountNumberError('Please enter at least 9 digits');
    }
  };

  const handleIfscChange = (event) => {
    const { value } = event.target;
    const ifsc = value.toUpperCase();
    setIfsc(ifsc);
    if (ifsc === '') {
      setIfscError('');
      return;
    } else {
      const newError = validateIFSC(ifsc);
      setIfscError(newError);
    }
  };

  const handleIfscBlur = () => {
    if (ifsc === '') {
      setIfscError('');
    } else if (ifsc.length >= 1 && ifsc.length < 11) {
      setIfscError('IFSC must be an 11 digit number');
    }
  };

  const hasChanges = () => {
    return (
      name !== ownerData.name ||
      phone !== ownerData.phone ||
      email !== ownerData.email ||
      address !== ownerData.address ||
      ifsc !== ownerData.ifsc ||
      accountNumber !== ownerData.account_number ||
      remarks !== ownerData.remarks
    );
  };

  const handleSubmit = () => {
    if (!hasChanges()) {
      onClose();
      return;
    }
    editOwner(
      ownerId,
      name,
      phone,
      (response) => {
        editOwnerDetails(
          ownerData.details_id,
          accountNumber,
          ifsc,
          remarks,
          email,
          address,
          (response) => {
            toast.success(response.message.message);
            fetchOwners();
            onClose();
          },
          (errorMessage) => {
            toast.error(errorMessage.message);
          }
        );
      },
      (errorMessage) => {
        toast.error(errorMessage);
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: '750px', height: 'auto' } }}>
      <Box sx={{ marginLeft: { sm: '-180px' } }}>
        <Heading title="Edit Owner" closeIcon="true" onClose={onClose} />
      </Box>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '15px',
            '@media (max-width: 600px)': {
              flexDirection: 'column',
            },
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            label="Name"
            value={name}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            error={!!nameError}
            helperText={nameError}
            style={{ width: '225px' }}
          />
          <TextField
            variant="outlined"
            size="small"
            style={{ width: '225px' }}
            label="Phone"
            value={phone}
            onChange={(e) => handlePhoneNumberChange(e)}
            onBlur={handlePhoneNumberBlur}
            error={!!phoneError}
            helperText={phoneError}
          />
          <TextField
            variant="outlined"
            size="small"
            style={{ width: '225px' }}
            label="Email"
            value={email}
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            variant="outlined"
            size="small"
            style={{ width: '225px' }}
            label="Address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
              setAddressError(validateAddress(e.target.value) ? '' : 'Invalid address');
            }}
            error={!!addressError}
            helperText={addressError}
          />
          <TextField
            variant="outlined"
            size="small"
            style={{ width: '225px' }}
            label="Account Number"
            value={accountNumber}
            onChange={(e) => handleAccountNumberChange(e)}
            onBlur={handleAccountNumberBlur}
            error={!!accountNumberError}
            helperText={accountNumberError}
          />
          <TextField
            variant="outlined"
            size="small"
            style={{ width: '225px' }}
            label="IFSC"
            value={ifsc}
            onChange={(e) => handleIfscChange(e)}
            onBlur={handleIfscBlur}
            error={!!ifscError}
            helperText={ifscError}
          />
          <TextField
            variant="outlined"
            size="small"
            style={{ width: '225px' }}
            label="Remarks"
            value={remarks}
            onChange={handleRemarksChange}
            error={!!remarksError}
            helperText={remarksError}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
        <Button className="custom-button" onClick={handleSubmit}>Save</Button>
        <Button className="custom-cancel-button" onClick={() => { onClose(); resetFields(); }}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOwnerModal;