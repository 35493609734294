import axios, { AxiosResponse, AxiosError } from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const storedUserDetails = localStorage.getItem("userdetails");
const token = storedUserDetails ? JSON.parse(storedUserDetails).token.replace(/^"(.*)"$/, '$1') : null;

interface ErrorResponse {
  status: number;
  message: string;
}
export const getUsers = (
  device_id: string | null,
  user_phone: string | null,
  country_code: string | null,
  companies_id:string |null,
  page_number: number,
  per_page_records: string | null,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: string) => void 
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(`${global.api_url}/api/users/?device_id=&user_phone=${user_phone}&country_code=&companies_id=${companies_id}&per_page_records=${per_page_records}&page_number=${page_number}`, {
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      const errorData = error.response?.data as ErrorResponse | undefined;
      const errorMessage = errorData?.message || "Unknown Error";
      _errorCallBack(errorMessage);
    });
};
export const addUsers = (name:any,phone:any,whatsapp_number:any,email:any,password:any,role:any,password_confirmation:any,otp:any,device_id:any,country_code:any,companies_id:any,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
  ) => {
    const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
    const headers = {
      Authorization: `Bearer ${userDetailsFromStorage.token}`, 
    };
    const payload = {
      name,
      role,
      phone,
      whatsapp_number,
      email,
      password,
      password_confirmation,
      otp,
      device_id,
      country_code,
      companies_id
    };
  axios
    .post(`${global.api_url}/api/register`, payload, { headers })
    
    .then((response: AxiosResponse) => {
      if (response.status === 200 || response.status === 201) {
        _successCallBack(response.data);
      } else {
        _errorCallBack("Unexpected status code: " + response.status);
      }
    })
    .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response);
        } else if (error.response?.status === 404) {
            console.log(error.response);
          _errorCallBack(error.response);
        }else if (error.response?.status === 401) {
          localStorage.clear();
        } 
      });
};
export const editUsers = (
  name:any,phone:any,whatsapp_number:any,email:any,country_code:any,language:any,user_id:any,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`, 
    'Content-Type': 'application/json',
  };
  const payload = {
    name,
    phone,
    email,
    whatsapp_number,
    country_code,
    language,
    user_id
  
  };
  axios
    .put(`${global.api_url}/api/edit_user`, payload, { headers })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data); 
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data); 
      } 
      else if (error.response?.status === 404) {
        _errorCallBack(error.response.data);
      }else if  (error.response?.status === 401){
        localStorage.clear();
      }
      else{
        toast.error("Server Error");
      }
    });
};
export const deleteUsers = (
users_id: any,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void
) => {
  const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
  const headers = {
    Authorization: `Bearer ${userDetailsFromStorage.token}`
  };

  axios
    .delete(`${global.api_url}/api/delete_account?users_id=${users_id}`, {
      headers: headers,

    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if (response.status === 200) {
        _successCallBack(response.data);
      } 
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }  else if( error.response?.status === 404)
        {
          _errorCallBack(error?.response?.data);
        }
      else {
        toast.error("Server Error");
      }
    });
};
export const getWarehouses = (
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
  ) => {
    const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
    console.log(userDetailsFromStorage);

  const headers = {
    Authorization: `Bearer ${userDetailsFromStorage.token}`, 
  };
  axios
    .get(`${global.api_url}/api/warehouses`, { 
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200 || response.status === 201) {
        _successCallBack(response.data);
      } else {
        _errorCallBack("Unexpected status code: " + response.status);
      }
    })
    .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        } else if (error.response?.status === 404) {
            console.log(error.response.data);
          _errorCallBack(error.response.data);
        }else if (error.response?.status === 401) {
          localStorage.clear();
        } 
      });
};
export const userWarehouseMapping = (
  usersId: any,
  warehouseId: any,
  successCallBack: (message: string) => void,
  errorCallBack: (errorMessage: string) => void
) => {
  const payload = {
    users_id:usersId,
    warehouses_id:warehouseId,
  };
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios.post(
    `${global.api_url}/api/user_warehouses`,
    payload,
    { headers }
  )
  .then((response: AxiosResponse) => {
    if (response.status === 201) {
      successCallBack(response.data.message);
    } 
    else if (response.status === 200) {
      successCallBack(response.data.message);
    }
  })
  .catch((error: any) => {
    if (error.response) {
      if (error.response.status === 400) {
        errorCallBack(error.response.data);
      } else if (error.response.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }
    } 
    else if( error.response?.status === 404)
      {
        errorCallBack(error?.response?.data);
      }
    else {
      toast.error("Server Error");
    }
  });
};
export const getWarehouseMapping = (
  users_id: string | null,
  page_number: number,
  per_page_records: string | null,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: string) => void 
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(`${global.api_url}/api/user_warehouses?per_page_records=${per_page_records}&page_number=${page_number}&users_id=${users_id}`, {
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      const errorData = error.response?.data as ErrorResponse | undefined;
      const errorMessage = errorData?.message;
      _errorCallBack(errorMessage || '');
    });
};
export const deleteWarehouseUserMapping = (
  users_id: any,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {
    const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
    const headers = {
      Authorization: `Bearer ${userDetailsFromStorage.token}`
    };
  
    axios
      .delete(`${global.api_url}/api/user_warehouses/${users_id}`, {
        headers: headers,
  
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if (response.status === 200) {
          _successCallBack(response.data);
        } 
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        } else if (error.response?.status === 401) {
          localStorage.clear();
          toast.error("Login Expired.");
        }  else if( error.response?.status === 404)
          {
            _errorCallBack(error?.response?.data);
          }
        else {
          toast.error("Server Error");
        }
      });
  };