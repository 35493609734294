import React, { FC, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box } from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import { addClients } from './action.ts';
import { validateRemarks } from '../../Utils/RemarksValidation/remarks-validation.tsx';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface AddClientsModalProps {
    open: boolean;
    onClose: () => void;
}

const AddClientsModal: FC<AddClientsModalProps> = ({ open, onClose }) => {
    const [clientName, setClientName] = useState('');
    const [clientNameError, setClientNameError] = useState<string>('');
    const storedUserDetails = JSON.parse(localStorage.getItem("userdetails"));
    const resetState = () => {
        setClientName('');
        setClientNameError('');
      };
     
    const handleSave = () => {
        let hasError = false;
      if (!clientName) {
          setClientNameError('Client Name is required');
          hasError = true;

      }
      if (clientNameError ) {
          toast.error("Please fix the errors");
          return;
      }
      if (hasError) {
                toast.error("Please fix the errors");
                return;
            }
      addClients(clientName,storedUserDetails?.user?.companies_id,
          (response) => {
          toast.success(response.message);
          setTimeout(() => {
            window.location.reload();
        }, 3000);
              resetState(); 
              onClose();
          },
          (errorMessage) => {
            if(errorMessage.message.errors.name){
              toast.error(errorMessage.message.errors.name[0]);}
          }
      );
  };
    const handleCancel = () =>{
        resetState();
        onClose();
      };  

    const handleClientNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setClientName(value);
        if (value === '') {
            setClientNameError('');
        } else if (validateRemarks(value)) {
            setClientNameError('');
        } else {
            setClientNameError('Invalid Character');
        }
};   
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs"  sx={{
            '& .MuiDialog-paper': {
                width: '400px',
                maxWidth: 'none',
            },
        }}>
            <DialogTitle sx={{padding:'0px'}}>
              <Box
                      sx={{
                        marginLeft: {
                          xs: '0px',
                          sm: '-180px',
                        }
                      }}
                    >    
                    <Heading title="Add Clients" closeIcon="true" onClose={onClose} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <div style={{ marginTop: '5px' }}>
                    <p style={{ fontSize: '17px' }}>Client Name</p>
                </div>
                <div style={{ marginLeft: '140px', marginTop: '-25px' }}>
                    <Box display="flex" alignItems="center">
                    <TextField
                        size='small'
                        name='client_name'
                        placeholder='Client Name'
                        value={clientName}
                        onChange={handleClientNameChange}
                        error={!!clientNameError}
                        helperText={clientNameError}

                        variant="outlined"
                    />
                    </Box>
                </div>
            </DialogContent>
            
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    onClick={handleCancel}
                    className="custom-cancel-button"
                    style={{ marginRight: '10px' }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    className="custom-button"
                    style={{ marginLeft: '10px' }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddClientsModal;
