import React, { useState,useEffect } from 'react';
import Heading from '../../Components/Heading/heading.tsx';
import '../../Components/Button/button-style.scss';
import { Dialog, DialogActions, DialogContent, Button, TextField, SelectChangeEvent, Box } from '@mui/material';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import DatePicker from '../../Components/DatePicker/date-picker.tsx';
import { getDriverName, getOwnerName, addVehicle,editVehicle, addOwner,addOwnerDetails,addWarehouseUserMapping,getWarehouseUserMapping,getVehicle,verifyOTP, getWarehouses } from './action.ts';
import { toast } from 'react-toastify';
import AddDocumentModal from './add-document-modal.tsx';
import { validatePhoneNumber } from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import { formatVehicleNo } from '../../Utils/VehicleNumberValidation/vehicle-number-validation.tsx';
import OTPVerificationModal from './otp-verification-modal.tsx';
import AddOwnerModal from './add-owner-modal.tsx';


interface AddVehicleModalProps {
  addVehicleModalOpen: boolean;
  handleCloseAddVehicle: () => void;
}

interface Warehouse {
  id: number;
  name: string;
}

interface Vendor {
  id: number;
  name: string;
}

const AddVehicleModal = ({ addVehicleModalOpen, handleCloseAddVehicle }: AddVehicleModalProps) => {
  const [selectedValue, setSelectedValue] = useState<{ [key: string]: string }>({
    numberPlate: '',
    model: '',
    regDate: '',
    fuelType:'',
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [errorWarehouse, setErrorWarehouse] = useState('');
  const [ownerPhoneNumber, setOwnerPhoneNumber] = useState<string>('');
  const [ownerPhoneError, setOwnerPhoneError] = useState<string>('');
  const [driverPhoneNumber, setDriverPhoneNumber] = useState<string>('');
  const [driverPhoneError, setDriverPhoneError] = useState<string>('');
  const [driverName, setDriverName] = useState<string>('');
  const [ownerName, setOwnerName] = useState<string>('');
  const [ownerId, setOwnerId] = useState(0);
  const [driverId, setDriverId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [apiSucessForOwner, setApiSuccessForOwner] = useState<string>('');
  const [apiSucessForDriver, setApiSuccessForDriver] = useState<string>('');
  const [vendorName, setVendorName] = useState<string>('');
  const [warehouseName, setWarehouseName] = useState<string>('');
  const [warehouse, setWarehouse] = useState<Warehouse[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [vehicleId, setVehicleId] = useState(0);
  const [selectedWarehouse, setSelectedWarehouse] = useState<{ id: string; label: string }[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<{ id: string; label: string }[]>([]);
  const [vendorId, setVendorId] = useState(0);
  const [driver, setDriver] = useState(0);
  const [owner, setOwner] = useState(0);
  const [error, setError] = useState({
    numberPlate: '',
    regDate: '',
    model: '',
    fuelType:''
  });
  const [isFocusedOwner, setIsFocusedOwner] = useState(false);
  const [isFocusedDriver, setIsFocusedDriver] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isAddOwnerModalOpen, setIsAddOwnerModalOpen] = useState(false);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [otp, setOtp] = useState('');
  const [warehouseDisplayName, setWarehouseDisplayName] = useState('');
  const storedUserDetails = localStorage.getItem("userdetails");
  const userRole = storedUserDetails ? JSON.parse(storedUserDetails).role : null;
  const maxDate = new Date(); 
  const [originalVehicleDetails, setOriginalVehicleDetails] = useState({
    model: '',
    regDate: '',
    numberPlate: '',
    fuelType: '',
    driverId: 0,
    ownerId: 0
  });
  const warehouseId = selectedWarehouse.length > 0 && selectedWarehouse[0].id.trim() !== ''
  ? parseInt(selectedWarehouse[0].id, 10)
  : 0;
  const handleClose = () => {
    handleCloseAddVehicle();
    setIsDocumentModalOpen(false);
    resetAddVehiclePopup();
    window.location.reload();
  };
let otpVerified = '';
  const resetAddVehiclePopup = () => {
    setSelectedValue({
      fuelType: '',
      numberPlate: '',
      model: '',
      regDate: '',
    });
    setOwnerPhoneNumber('');
    setOwnerPhoneError('');
    setDriverPhoneNumber('');
    setDriverPhoneError('');
    setDriverName('');
    setOwnerName('');
    setOwnerId(0);
    setDriverId(0);
    setUserId(0);
    setApiSuccessForOwner('');
    setApiSuccessForDriver('');
    setVendorName('');
    setWarehouseName('');
    setWarehouse([]);
    setVendors([]);
    setSelectedWarehouse([]);
    setSelectedVendor([]);
    setError({
      numberPlate: '',
      regDate: '',
      model: '',
      fuelType:'',
    });
    setIsFocusedOwner(false);
    setIsFocusedDriver(false);
    setErrorWarehouse('');
    setDriver(0);
    setIsEditMode(false); 
  };
  useEffect(() => {
    if (addVehicleModalOpen) {
      const storedUserDetails = localStorage.getItem("userdetails");
      const vendorName = storedUserDetails ? JSON.parse(storedUserDetails).vendor_name : null;
      const vendorId = storedUserDetails ? JSON.parse(storedUserDetails).vendor_id : 0;
      
      if (vendorName) {
        setVendorId(vendorId);
        setVendorName(vendorName);
        getWarehouses(
          (response) => {
            setWarehouse(response.message.warehouses);
          },
          (errorMessage) => console.error(errorMessage)
        );
      }
    }
  }, [addVehicleModalOpen]);
  const handleCancel = () => {
    resetAddVehiclePopup();
    handleCloseAddVehicle();
  };

  const fuelOptions = [
    { id: 'diesel', label: 'Diesel' },
    { id: 'EV', label: 'EV' },
    { id: 'petrol', label: 'Petrol' },
    { id: 'CNG', label: 'CNG' }
  ];
  const modelOptions = [
    { id: 'EV', label: 'EV' },
    { id: 'Tata Ace', label: 'Tata Ace' }
  ];
  const validateVehicleNo = (vehicleNo) => {
    if (!vehicleNo) {
      return "";
    }
    const replacedSpace = vehicleNo.replace(/ /g, "");
    const pattern = /^([A-Z]{2})(\d{2})([A-Z]{0,2})?(\d{1,4})?$/;
    const match = replacedSpace.match(pattern);
    let errorMessage = '';
    if (match) {
      const [, part1, part2, part3, part4] = match;
      if (part2 && (!part4 || part4.length === 0)) {
        errorMessage = 'Invalid vehicle number format';
      }
      if (part3 && part3.length > 0 && (!part4 || part4.length === 0)) {
        errorMessage = 'Invalid vehicle number format';
      }
    } else {
      errorMessage = 'Invalid vehicle number format.';
    }
    if (errorMessage) {
      setError((prevError) => ({
        ...prevError,
        numberPlate: errorMessage,
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        numberPlate: '',
      }));
    }
  };
  const resetFormFields = () => {
    setSelectedValue((prevValue) => ({
      ...prevValue,
      model: '',
      regDate: '',
      fuelType:'',
    }));
    setDriverName('');
    setDriverId(0);
    setOwnerName('');
    setOwnerId(0);
    setDriverPhoneNumber('');
    setOwnerPhoneNumber('');
    setApiSuccessForDriver('false');
    setApiSuccessForOwner('false');
    setIsEditMode(false);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setError((prevError) => ({
      ...prevError,
      [name]: '',
    }));
  
    if (name === 'numberPlate') {
      const { formatted, error } = formatVehicleNo(value);
      setSelectedValue((prevValue) => ({
        ...prevValue,
        [name]: formatted,
      }));
      setError((prevError) => ({
        ...prevError,
        numberPlate: error,
      }));
  
      if (formatted !== selectedValue.numberPlate) {
        resetFormFields();
      }
    } else {
      setSelectedValue((prevValue) => ({
        ...prevValue,
        [name]: value,
      }));
    }
  };
  const handlePhoneNumberChange = (event) => {
    const { name, value } = event.target;
    if (name === 'driverPhoneNumber') {
      setDriverPhoneNumber(value);
    }
    if (name === 'ownerPhoneNumber') {
      setOwnerPhoneNumber(value);
    }
    if (value === '') {
      if (name === 'ownerPhoneNumber') {
        setOwnerPhoneError("");
        setApiSuccessForOwner("false");
        setOwnerName("");
      }
      if (name === 'driverPhoneNumber') {
        setDriverPhoneError("");
        setApiSuccessForDriver("false");
        setErrorWarehouse("");
      }
      return;
    }
    const { errorMessage } = validatePhoneNumber(value);
    if (name === 'ownerPhoneNumber') {
      setOwnerPhoneError(errorMessage);
    } else if (name === 'driverPhoneNumber') {
      setDriverPhoneError(errorMessage);
      setSelectedWarehouse([{ id: '', label: 'Select Warehouse' }]);
      setErrorWarehouse("");
    }
  };

  const handleFocusOwner = () => {
    setIsFocusedOwner(true);
  };

  const handleBlurOwner = () => {
    if (ownerPhoneNumber.length >= 1 && ownerPhoneNumber.length < 10) {
      setOwnerPhoneError('Phone number must be 10 digits');
      setOwnerName('');
      setApiSuccessForOwner("false");
      setIsFocusedOwner(false);
    } else if (ownerPhoneNumber.length === 10) {
      setIsFocusedOwner(false);
      getOwnerName(ownerPhoneNumber,
        (response) => {
                    const user = response.message.vehicles_owners_list[0];
          const userName = user?.name || '';
          const userIdSuccess = Number(user?.id);
          setOwnerName(userName);
          setApiSuccessForOwner("true");
          setOwnerId(userIdSuccess);
        },
        (errorMessage) => {
          if (errorMessage.status === 404 || errorMessage.message === "No Client Found") {
            setIsAddOwnerModalOpen(true);
          } else {
            setApiSuccessForOwner("false");
            setOwnerPhoneError("User Not found");
            setOwnerName("");
          }
        }
      );
    } else {
      setIsFocusedOwner(false);
    }
  };

  const handleFocusDriver = () => {
    setIsFocusedDriver(true);
  };

  const handleBlurDriver = () => {
    if (driverPhoneNumber.length >= 1 && driverPhoneNumber.length < 10) {
      setDriverPhoneError('Phone number must be 10 digits');
      setDriverName('');
      setIsFocusedDriver(false);
      setDriver(0);
      setApiSuccessForDriver("false");
      setErrorWarehouse("");
      setSelectedWarehouse([{ id: '', label: 'Select Warehouse' }]);
    } else if (driverPhoneNumber.length === 10) {
      setIsFocusedDriver(false);
      getDriverName(driverPhoneNumber,
        (success) => {
          setDriverPhoneError('');
          const user = success.message.user[0];
          const userName = user?.user_name || '';
          const userIdSuccess = Number(user?.user_unique_id);
          setDriverName(userName);
          setApiSuccessForDriver("true");
          setUserId(userIdSuccess);
          setDriverId(userIdSuccess);
          setDriver(0);

        },
        (errorMessage) => {
          if (errorMessage.status === 400) {
            setApiSuccessForDriver("false");
            setDriverPhoneError(" User not found")
            setDriverName("");
            setDriver(1);
            setErrorWarehouse("");
          }
        }
      );
    } else {
      setIsFocusedDriver(false);
    }
  };
const userWarehouseMapping = () => {
  addWarehouseUserMapping(userId || driverId,warehouseId, (mappingResponse) => {
    if (mappingResponse.data.status === 200) {
      toast.error(mappingResponse?.data?.message); 
    } else if (mappingResponse.data.status === 201) {
      toast.success(mappingResponse.data.message);
    }
  },
  (errorMessage) => {
    toast.error(errorMessage.message);
  }
  );
}
  const handleVehicleNumberBlur = () => {
    const currentVehicleNumber = selectedValue.numberPlate;
    validateVehicleNo(currentVehicleNumber);
    if (!error.numberPlate && currentVehicleNumber) {
      getVehicle(
        '',
        '',
        (response: any) => {
          const vehicleDetails = response.message?.vehicles_list || [];
          if (vehicleDetails.length > 0) {
            const vehicle = vehicleDetails[0];
                      getWarehouseUserMapping(
                        vehicle?.driver_id,
                        (response) => { const warehouseName = response.user_warehouses[0]?.warehouse?.name || '';
                          setWarehouseDisplayName(warehouseName);
                          const warehouseObj = warehouse.find(w => w.name === warehouseName);

                          if (warehouseObj) {
                            setSelectedWarehouse([{ 
                              id: warehouseObj.id.toString(), 
                              label: warehouseObj.name 
                            }]);
                          }
                        },
                        (errorMessage) => {toast.error(errorMessage);}
                      )
              if (vehicle.number_plate === currentVehicleNumber) {
              setSelectedValue({
                numberPlate: vehicle.number_plate,
                model: vehicle.make_model,
                regDate: vehicle.reg_date,
                fuelType:vehicle.fuel_type
              });
              setOriginalVehicleDetails({
                model: vehicle.make_model,
                regDate: vehicle.reg_date,
                numberPlate: vehicle.number_plate,
                fuelType: vehicle.fuel_type,
                driverId: vehicle.driver_id,
                ownerId: vehicle.owner_id || 0
              });
              setDriverName(vehicle.driver_name);
              setDriverId(vehicle.driver_id);
              setOwnerName(vehicle.owner_name || '');
              setOwnerId(vehicle.owner_id || 0);
              setDriverPhoneNumber(vehicle.driver_phone || '');
              setOwnerPhoneNumber(vehicle.owner_phone || '');
              setApiSuccessForDriver(vehicle.driver_name ? 'true' : 'false');
              setApiSuccessForOwner(vehicle.owner_name ? 'true' : 'false');
              setIsEditMode(true);
            }
          } else {
            setIsEditMode(false); 
          }
        },
        (errMessage: any) => {
          if (errMessage.message?.errors?.number_plate) {
            toast.error(errMessage.message?.errors?.number_plate[0]);
          } else {
            console.log(errMessage.message);
          }
          setIsEditMode(false);
        },
        currentVehicleNumber,
        '',
      );
    }
  };

const handleSubmit = () => {
  const { model, regDate, numberPlate, fuelType } = selectedValue;
  setDriverPhoneError('');
  if (selectedWarehouse.length === 0 || !selectedWarehouse[0] || !selectedWarehouse[0].id) {
    setErrorWarehouse('Warehouse is mandatory');
  }
    setError((prevError) => ({
    ...prevError,
    numberPlate: !selectedValue.numberPlate ? 'Vehicle number is required' : '',
    model: !selectedValue.model ? 'Model is required' : '',
    regDate: !selectedValue.regDate ? 'Registration Date is required' : '',
    fuelType: !selectedValue.fuelType ? 'Fuel Type is required' : '',
  }));
  if (!driverPhoneNumber) {
    setDriverPhoneError('Driver phone number is required');
  }
  if (driver === 1) {
    setDriverPhoneError("User Not Found");
  }
  if (ownerPhoneNumber.length > 0 && ownerName === '') {
    toast.error("Please fix the errors");
    handleMakeYearBlur();
    handleVehicleNumberBlur();
    handleModel();
    return;
  }

  if (isEditMode) {
    const currentVehicleDetails = {
      model: selectedValue.model,
      regDate: selectedValue.regDate,
      numberPlate: selectedValue.numberPlate,
      fuelType: selectedValue.fuelType,
      driverId: driverId,
      ownerId: ownerId
    };

    const hasVehicleDetailsChanged = 
      currentVehicleDetails.model !== originalVehicleDetails.model ||
      currentVehicleDetails.regDate !== originalVehicleDetails.regDate ||
      currentVehicleDetails.numberPlate !== originalVehicleDetails.numberPlate ||
      currentVehicleDetails.fuelType !== originalVehicleDetails.fuelType ||
      currentVehicleDetails.ownerId !== originalVehicleDetails.ownerId;
    const hasDriverChanged = currentVehicleDetails.driverId !== originalVehicleDetails.driverId;
    const hasWarehouseChanged = warehouseDisplayName !== selectedWarehouse[0]?.label;

    if (!hasVehicleDetailsChanged && !hasDriverChanged && !hasWarehouseChanged) {
      setTimeout(() => {
        handleClose();
      }, 1000);
      return;
    }
    if (!hasVehicleDetailsChanged && !hasDriverChanged && hasWarehouseChanged) {
      if (selectedWarehouse.length > 0 && selectedWarehouse[0]?.id) {
        userWarehouseMapping();
      }
      setTimeout(() => {
        handleClose();
      }, 1000);
      return;
    }
    if (!hasVehicleDetailsChanged && hasDriverChanged && !hasWarehouseChanged) {
      if (driverId) {
        userWarehouseMapping();
      }
      setTimeout(() => {
        handleClose();
      }, 1000);
      return;
    }
    if (hasVehicleDetailsChanged && !hasDriverChanged && !hasWarehouseChanged) {
      editVehicle(
        model,
        regDate,
        numberPlate,
        driverId,
        ownerId,
        fuelType,
        otpVerified,
        handleVehicleResponse,
        handleVehicleError
      );
      setTimeout(() => {
        handleClose();
      }, 1000);
      return;

    }
    if ((hasVehicleDetailsChanged && (hasDriverChanged || hasWarehouseChanged))) {
      editVehicle(
        model,
        regDate,
        numberPlate,
        driverId,
        ownerId,
        fuelType,
        otpVerified,
        (response) => {
          handleVehicleResponse(response);
          if (selectedWarehouse.length > 0 && selectedWarehouse[0]?.id) {
            userWarehouseMapping();
          }
        },
        handleVehicleError
      );
      return;
    }
  }

  const vehicleAction = isEditMode ? editVehicle : addVehicle;
  if (error.numberPlate === '' && error.model === '' && error.regDate === '' && 
      ownerPhoneError === '' && driverPhoneError === '' && driver === 0 && 
      selectedWarehouse.length > 0 && selectedWarehouse[0]?.id) {
    vehicleAction(
      model,
      regDate,
      numberPlate,
      driverId,
      ownerId,
      fuelType,
      otpVerified,
      (response) => {
        handleVehicleResponse(response);
        if (!isEditMode || (isEditMode && (warehouseDisplayName !== selectedWarehouse[0]?.label || driverId !== originalVehicleDetails.driverId))) {
          userWarehouseMapping();
        }
        if (!isEditMode) {
          setIsDocumentModalOpen(true);
        }
      },
      handleVehicleError
    );
  } else {
    toast.error("Please fix the errors");
    handleMakeYearBlur();
    handleVehicleNumberBlur();
    handleModel();
  }
};

const handleVehicleResponse = (response) => {
  if (response.status === 200) {
    if (response?.data?.status === 308) {
      toast.error(response.data.message);
    } else if (response?.data?.status === 400) {
      toast.error("Please contact your administrator");
    } else if (response?.data?.message === 'OTP sent.' ||
      response?.data?.message === 'Old OTP expired. New OTP generated and re-sent.' ||
      response?.data?.message === 'OTP re-sent.') {
      setIsOTPModalOpen(true);
    } else if (response.message.message === 'Driver updated successfully') {
      toast.success(response?.message?.message);
    } else {
      toast.error(response?.message || 'An error occurred');
    }
  } else if (response.status === 201) {
    const id = response.id;
    setVehicleId(id);
    toast.success(response?.data?.message?.message);
  }
};

const handleVehicleError = (errorMessage) => {
  toast.error(errorMessage.message);
};

  const handleVerifyOTP = (otp: string): Promise<{ success: boolean; message?: string }> => {
    return new Promise((resolve, reject) => {
      verifyOTP(
        driverPhoneNumber,
        '91',
        otp,
        (response) => {
         
          if (response.data.status === 200) {
            toast.success(response.data.message);
            otpVerified = '1';
            resolve({ success: true });
            handleSubmit();
          } else {
           resolve({ success: false, message: response.data.message|| 'OTP verification failed.' });
          }
        },
        (errorMessage) => {
          resolve({ success: false, message: errorMessage });
        }
      );
    });
  };
  const handleWarehouseDropdownChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value;
    const selectedOption = options.find(option => option.id === selectedId);
    if (selectedOption) {
      setSelectedWarehouse([selectedOption]);
      setErrorWarehouse('');
    } else {
      setSelectedWarehouse([]);
    }
  };

  const options = warehouse.map((warehouse) => ({
    id: warehouse.id.toString(),
    label: warehouse.name,
  }));

  const handleVendorDropdownChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value;
    const selectedOption = optionsVendor.find(optionVendor => optionVendor.id === selectedId);
    if (selectedOption) {
      setSelectedVendor([selectedOption]);
    } else {
      setSelectedVendor([]);
    }
  };

  const optionsVendor = vendors.map((vendor) => ({
    id: vendor.id.toString(),
    label: vendor.name,
  }));

  const handleModel = () => {
    if (error.model) {
      setError((prevError) => ({
        ...prevError,
        model: error.model,
      }));
    }
  };

  const handleMakeYearBlur = () => {
    if (error.regDate) {
      setError((prevError) => ({
        ...prevError,
        regDate: error.regDate,
      }));
    }
  };

  const handleAddOwner = (name: string, phoneNumber: string,email:any,address:any,ifsc:any,accountNumber:any,remarks:any) => {
   addOwner(name,phoneNumber,(response) => {
    toast.success('Owner added successfully ');
    setIsAddOwnerModalOpen(false)
    const ownerId = response.vehicle_owner.id;
 if(ownerId){
  addOwnerDetails(ownerId,accountNumber,ifsc,remarks,email,address,(response) => { toast.success('Owner added successfully ');},
  (errorMessage) => {
    toast.error(errorMessage);
  } );
 }
  },
  (errorMessage) => {
    toast.error(errorMessage);
  })
    setOwnerName(name);
    setApiSuccessForOwner("true");
    setOwnerPhoneNumber(phoneNumber);
  };

  return (
    <Dialog open={addVehicleModalOpen} onClose={handleCloseAddVehicle}>
        <Box
          sx={{
            marginLeft: {
              xs: '0px',
              sm: '-180px',
  
            },
          }}
        >
          <Heading title="Add Vehicle" closeIcon="true" onClose={handleCloseAddVehicle} />
        </Box>

      <DialogContent>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}>Vehicle Number</p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-22px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              style={{ width: '225px' }}
              variant="outlined"
              size="small"
              name="numberPlate"
              placeholder="Eg: KA 23 DE 1234"
              value={selectedValue.numberPlate}
              onBlur={handleVehicleNumberBlur}
              onChange={handleInputChange}
              error={!!error.numberPlate}
              helperText={error.numberPlate}
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>        <p style={{ fontSize: '17px' }}>Model</p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-22px' , marginBottom: error.model ? '20px' : '0'}}>
          <Box display="flex" alignItems="center">
            <Dropdown
              options={modelOptions}
              selectedValue={selectedValue.model}
              handleChange={(e) => handleInputChange({
                target: {
                  name: 'model',
                  value: e.target.value
                }
              })}
              placeholder="Select Model"
              width="225px"
              height="41px"
              displayEmpty={true}
              error={!!error.model}
              helperText={error.model}
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '-8px' }, marginTop: '5px' }}>        <p style={{ fontSize: '17px' }}>Registration Date</p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-22px', marginBottom: error.regDate ? '20px' : '0' }}>
          <Box display="flex" alignItems="center">
             <DatePicker
                                id="regDate"
                                name="regDate"
                                value={selectedValue.regDate}
                                onChange={(e) => handleInputChange({
                                  target: {
                                    name: 'regDate',
                                    value: e.target.value
                                  }
                                })}
                                maxDate={maxDate}
                                error={!!error.regDate}
                                helperText={error.regDate}
                                
                              />
          </Box>
        </Box>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>        <p style={{ fontSize: '17px' }}>Fuel Type</p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-22px', marginBottom: error.fuelType ? '20px' : '0'  }}>
          <Box display="flex" alignItems="center">
            <Dropdown
              options={fuelOptions}
              selectedValue={selectedValue.fuelType}
              handleChange={(e) => handleInputChange({
                target: {
                  name: 'fuelType',
                  value: e.target.value
                }
              })}
              placeholder="Select Fuel"
              width="225px"
              height="41px"
              displayEmpty={true}
              error={!!error.fuelType}
              helperText={error.fuelType}
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>        <p style={{ fontSize: '17px' }}>Owner</p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-22px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              style={{ width: '225px' }}
              variant="outlined"
              size="small"
              type="text"
              name="ownerPhoneNumber"
              value={
                !isFocusedOwner &&
                ownerPhoneNumber.length === 10 &&
                apiSucessForOwner === 'true'
                  ? ownerName
                  : ownerPhoneNumber
              }
              placeholder={
                !isFocusedOwner &&
                ownerPhoneNumber.length === 10 &&
                apiSucessForOwner === 'true'
                  ? ''
                  : 'Phone Number'
              }
              onChange={(e) => handlePhoneNumberChange(e)}
              onFocus={handleFocusOwner}
              onBlur={handleBlurOwner}
              error={!!ownerPhoneError}
              helperText={ownerPhoneError}
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>        <p style={{ fontSize: '17px' }}>Driver</p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-22px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              type="text"
              name="driverPhoneNumber"
              value={
                !isFocusedDriver && driverPhoneNumber.length === 10 && apiSucessForDriver === 'true'
                  ? driverName
                  : driverPhoneNumber
              }
              placeholder={
                !isFocusedDriver && driverPhoneNumber.length === 10 && apiSucessForDriver === 'true'
                  ? ''
                  : 'Phone Number'
              }
              onChange={handlePhoneNumberChange}
              onFocus={handleFocusDriver}
              onBlur={handleBlurDriver}
              error={!!driverPhoneError}
              helperText={driverPhoneError}
              style={{ width: '225px' }}
            />
          </Box>
        </Box>
        {apiSucessForDriver === 'true' && !isFocusedDriver && (
          <>
            {userRole === 'vendor_supervisor' || userRole === 'vendor_manager' ? (
              <>
                <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>                <p style={{ fontSize: '17px' }}>Warehouse</p>
                </Box>
                <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-23px' }}>
                  <Dropdown
                    options={options}
                    selectedValue={
                     selectedWarehouse.length > 0 ? selectedWarehouse[0].id : ''
                    }
                    handleChange={handleWarehouseDropdownChange}
                    placeholder="Select Warehouse"
                    displayEmpty={true}
                    width="225px"
                    height="43px"
                    searchable={false}
                    error={!!errorWarehouse}
                    helperText={errorWarehouse}
                  />
                </Box>
                <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: errorWarehouse ? '18px' : '10px' }}>
                  <p style={{ fontSize: '17px' }}>Vendor</p>
                </Box>
                <Box
                  sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: errorWarehouse ? '-19px' : '-28px' }}
                >
                  <Box display="flex" alignItems="center">
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="vendorName"
                      disabled
                      value={vendorName}
                      style={{ width: '225px' }}
                    />
                  </Box>
                </Box>
              </>
            ) : userRole === 'warehouse_supervisor' || userRole === 'warehouse_manager' ? (
              <>
                <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>                <p style={{ fontSize: '17px' }}>Vendor</p>
                </Box>
                <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-25px' }}>
                  <Dropdown
                    options={optionsVendor}
                    selectedValue={selectedVendor.length > 0 ? selectedVendor[0].id : ''}
                    handleChange={handleVendorDropdownChange}
                    placeholder="Select Vendor"
                    width="220px"
                    height="43px"
                  />
                </Box>
                <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>
                  <p style={{ fontSize: '17px' }}>Warehouse</p>
                </Box>
                <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-25px' }}>
                  <Box display="flex" alignItems="center">
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="warehouseName"
                      value={warehouseName}
                      disabled
                      style={{ width: '225px' }}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <p> Service Not Available </p>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
      <Button className="custom-button" onClick={handleSubmit}>
    {isEditMode ? 'Edit' : 'Add'}
  </Button>
        <Button className="custom-cancel-button" onClick={handleCancel}>Cancel</Button>
        {vehicleId !== null && (
          <AddDocumentModal
            isDocumentOpen={isDocumentModalOpen}
            handleClose={handleClose}
            vehicleId={vehicleId}
          />
        )}
      </DialogActions>
      <AddOwnerModal
        isOpen={isAddOwnerModalOpen}
        handleClose={() => setIsAddOwnerModalOpen(false)}
        phoneNumber={ownerPhoneNumber}
        onAddOwner={handleAddOwner}
      />
      <OTPVerificationModal
        isOpen={isOTPModalOpen}
        handleClose={() => setIsOTPModalOpen(false)}
        phoneNumber={{driverPhoneNumber}}
        handleVerifyOTP={handleVerifyOTP}
      />
    </Dialog>
  );
};

export default AddVehicleModal;