import React, { FC, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button,Box } from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import { editWarehouses } from './action.ts';
import {validateEmail} from '../../Utils/EmailValidation/email-validation.tsx';
import {validateAddress} from '../../Utils/AddressValidation/address-validation.tsx';
import {validateDriverName} from '../../Utils/NameValidation/name-validation.tsx';
import { validateRemarks } from '../../Utils/RemarksValidation/remarks-validation.tsx';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AddWarehousesModalProps {
    open: boolean;
    onClose: () => void;
    warehouse: any;
}

const EditWarehousesModal: FC<AddWarehousesModalProps> = ({ open, onClose, warehouse }) => {
    const [warehouseName, setWarehouseName] = useState('');
    const [contactName, setContactName] = useState<string>('');
    const [warehouseNameError, setWarehouseNameError] = useState<string>('');
    const [contactNameError, setContactNameError] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [remarks, setRemarks] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [remarksError, setRemarksError] = useState<string>('');
    const [mobileNumber , setMobileNumber] = useState<string>('');
    const [mobileNumberError , setMobileNumberError] = useState<string>('');
    const [location , setLocation] = useState<string>('');
    const [locationError , setLocationError] = useState<string>('');
    useEffect(() => {
        if (open) {
            setWarehouseName(warehouse.warehouseName);
            setContactName(warehouse.contactName);
            setMobileNumber(warehouse.mobileNumber);
            setEmail(warehouse.email);
            setRemarks(warehouse.remarks);
        }
    }, [open, warehouse]);
    const handleEmailBlur = () => {
        if (email === '') {
          setEmailError('');
        } else if (validateEmail(email)) {
          setEmailError('');
        } else {
          setEmailError('Please enter a valid email address.');
        }
    };
    const handleSave = () => {
        if (!warehouseName) {
            setWarehouseNameError('Warehouse Name is required');
          }
          if (!contactName) {
            setWarehouseNameError('Contact Name is required');
          }
         if (!mobileNumber) {
            setMobileNumberError('Mobile number is required');
          }

          if (!email) {
            setLocationError('Email is required');
          }
        if(contactNameError||emailError||mobileNumberError||locationError || warehouseNameError || remarksError){
          toast.error("Please fix the errors");
          return;
        } 
        else {

        editWarehouses(
            warehouseName,
            contactName,
            '3',
            '3',
            remarks,
            mobileNumber,
            email,
            warehouse.id,
            (response) => {
                toast.success(response.message.message);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            },
            (errorMessage) => {
                toast.error(errorMessage);
            }
        );

        setWarehouseName('');
        onClose();
    }
    };
    const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setLocation(value);
        if(value === ''){
            setLocationError('');
        } else if (validateAddress(value)){
            setLocationError('')
        } else
        
        setLocationError('Invalid Address');
     };
     const handleContactNameChange = (event) => {
        const { value } = event.target;
        setContactName(value);
        if (value === '') {
          setContactNameError('');
        } else if (validateDriverName(value)) {
          setContactNameError('');
        } else {
          setContactNameError('Characters are allowed');
        }
    };
    const handleWarehouseNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setWarehouseName(value); 
        if (value === '') {
            setWarehouseNameError('');
        } else if (validateRemarks(value)) {
            setWarehouseNameError('');
        } else {
            setWarehouseNameError('Characters are allowed');
        }
    };
        const handleEmailChange = (event) => {
            const { value } = event.target;
            setEmail(value);
            setEmailError('');
        };
        const handleRemarksChange = (event) => {
          const { value } = event.target;
          setRemarks(value);
          if (value === '') {
            setRemarksError('');
          } else if (validateRemarks(value)) {
            setRemarksError('');
          } else {
            setRemarksError('Invalid Character');
          }
        };
        const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            setMobileNumber(value);
        
            if (value === '') {
                setMobileNumberError('');
                return;
            }
        
            const { errorMessage } = validatePhoneNumber(value);
            setMobileNumberError(errorMessage || '');
        };
        
        const handleLocationBlur = () => {
          if(location==='')
          {
            setLocationError("");
          }
           
        };  
        const handlePhoneNumberBlur = () => {
          if (mobileNumber === '') {
            setMobileNumberError('');
          } else if (mobileNumber.length>=1&&mobileNumber.length<10) {
            setMobileNumberError('Please enter a 10 digit number');
          } 
        };
        const handleWarehouseNameBlur = () => {
            if (warehouseNameError=== '') {
              setWarehouseNameError('');
            } else  {
              setWarehouseNameError(warehouseNameError);
            } 
        };
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
              <Box sx={{marginLeft: {xs: '0px',sm: '-180px',},}}>                   
                <Heading title="Edit Warehouses" closeIcon="true" onClose={onClose} />
              </Box>
            </DialogTitle>
            <DialogContent style={{textAlign:'center'}} >
              <div style={{ marginTop: '10px' }}>
                <TextField
                  label="Warehouse Name"
                  size='small'
                  value={warehouseName}
                  onChange={handleWarehouseNameChange}
                  sx={{ marginRight: '10px' }}
                  variant="outlined"
                  error={!!warehouseNameError}
                  helperText={warehouseNameError}
                />
                <TextField
                  label="Contact Name"
                  value={contactName}
                  size='small'
                  onChange={ handleContactNameChange}
                  error={!!contactNameError}
                  helperText={contactNameError}
                  variant="outlined"
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                <TextField
                  label="Mobile Number"
                  value={mobileNumber}
                  size='small'
                  onChange={handlePhoneNumberChange}
                  onBlur={handlePhoneNumberBlur}
                  error={!!mobileNumberError}
                  helperText={mobileNumberError}
                  sx={{ marginRight: '10px' }}
                  variant="outlined"
                />        
                <TextField
                  label="Location"
                  size='small'
                  value={location}
                  onChange={ handleLocationChange}
                  error={!!locationError}
                  helperText={locationError}
                  variant="outlined"
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                <TextField
                  label="Email"
                  value={email}
                  size='small'
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                  error={!!emailError}
                  helperText={emailError}
                  variant="outlined"
                  sx={{ marginRight: '10px' }}
                />
                <TextField
                  label="Remarks"
                  value={remarks}
                  size='small'
                  onChange={handleRemarksChange}
                  error={!!remarksError}
                  helperText={remarksError}
                  variant="outlined"
                />
              </div>  
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                onClick={onClose}
                className="custom-cancel-button"
                style={{ marginRight: '10px' }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                className="custom-button"
                style={{ marginLeft: '10px' }}
              >
                Save
              </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditWarehousesModal;
