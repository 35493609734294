import React, { useEffect, useState } from 'react';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import {SelectChangeEvent} from '@mui/material/Select';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx';
import { Button,Box,Typography} from '@mui/material';
import NavBar from '../../Components/Navbar/sidenav.tsx';
import Header from '../../Components/Header/header.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import AddWarehousesModal from './add-warehouse-modal.tsx';
import EditWarehousesModal from './edit-warehouse-modal.tsx';
import IconButton from '@mui/material/IconButton';
import Newpagination from '../../Components/Pagination/pagination.tsx';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getWarehouses, deleteWarehouses } from './action.ts';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WarehousesPage = () => {
    const [warehouses, setWarehouses] = useState<any[]>([]);
    const [addWarehousesModalOpen, setAddWarehousesModalOpen] = useState(false);
    const [editWarehousesModalOpen, setEditWarehousesModalOpen] = useState(false);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [warehouseToDelete, setWarehouseToDelete] = useState(null);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const tableHeight = windowHeight - 200;
    useEffect(() => {
        getWarehouses(
            page,
            pageSize,
            (response) => {
                if (response.status === 200) {
                    setWarehouses(response.message.warehouses);
                } else {
                    toast.error("Failed to fetch warehouses");
                }
            },
            (errorMessage) => {
                toast.error(errorMessage.message);
            }
        );
    }, [page,pageSize]);

    const handleClickOpen = () => {
        setAddWarehousesModalOpen(true);
    };
    const handleClickEditOpen = (warehouse) => {
        setSelectedWarehouse(warehouse);
        setEditWarehousesModalOpen(true);
    };

    const handleDeleteClick = (warehouse) => {
        setWarehouseToDelete(warehouse);
        setConfirmationOpen(true);
    };

    const handleDeleteWarehouses = () => {
        deleteWarehouses(warehouseToDelete?.id,  
            (response) => {
                if (response.status === 200) {
                    toast.success(response.message);
                    setConfirmationOpen(false);
                    setWarehouseToDelete(null);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else {
                    toast.error(response.message);
                }
            },
            (errorMessage) => {
                toast.error(errorMessage);
            }
        );
    };

    const handleCloseAdd = () => {
        setAddWarehousesModalOpen(false);
    };
    const handleCloseEdit = () => {
        setEditWarehousesModalOpen(false);
    };

    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
        setWarehouseToDelete(null);
    };

    const rows = warehouses.map((warehouse, index) => ({
        id: warehouse.id,
        slNo: index + 1,
        warehouseClientName: `${warehouse.name}/${warehouse.client_name}`,
        warehouseName:warehouse.name,
        contactName: warehouse.contact_name,
        mobileNumber: warehouse.phone,
        email: warehouse.email,
        remarks: warehouse.remarks,
    }));

    const columns = [
        { field: 'slNo', headerName: 'SL No', width: 80, headerAlign: 'center' },
        { field: 'warehouseClientName', headerName: 'Warehouse Name', width:200, headerAlign: 'center' },
        { field: 'contactName', headerName: 'Contact Name', width: 200, headerAlign: 'center' },
        { field: 'mobileNumber', headerName: 'Mobile Number', width: 200, headerAlign: 'center' },
        { field: 'location', headerName: 'Location', width: 150, headerAlign: 'center' },
        { field: 'remarks', headerName: 'Remarks', width:150, headerAlign: 'center' },
        { field: 'email', headerName: 'Email', width:200, headerAlign: 'center' },
        {
            field: 'actions',
            headerName: 'Actions',
            flex:1, headerAlign: 'center',
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', gap: '8px' }}>
                        <IconButton color="primary" onClick={() => handleDeleteClick(params.row)}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={() => handleClickEditOpen(params.row)}>
                            <EditIcon />
                        </IconButton>
                    </div>
                );
            },
        },
    ];
  const handlePageClick = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage - 1);
  };
    const showEntries = [
        { id: '10', label: '10' },
        { id: '20', label: '20' },
        { id: '50', label: '50' },
        { id: '100', label: '100' },
    ];

    const updateField = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        if (!isNaN(Number(value))) {
            setPageSize(Number(value));
            setPage(0);
        }
    };

    return (
        <>
            <Header />
            <div style={{ marginTop: "70px" }}>
                <Heading
                    title="Warehouses"
                    closeIcon="false"
                    leftContent={
                        <>
                            <Box display="flex" alignItems="center" marginLeft={'10px'}>
                                <Typography style={{ marginRight: '5px' }}>Show Entries:</Typography>
                                <Dropdown
                                    options={showEntries}
                                    selectedValue={pageSize.toString()}
                                    handleChange={(event) => updateField(event)}
                                    width='75px'
                                    height='25px'
                                />
                            </Box>
                        </>
                    }
                    rightContent={
                        <>
                            <Box display="flex" alignItems="center" gap="10px" marginRight={'10px'}>
                                <Button
                                    className="custom-button"
                                    onClick={handleClickOpen}>
                                    Add Warehouses
                                </Button>
                            </Box>
                        </>
                    }
                />
                <Box className="table-container custom-data-grid" sx={{ marginLeft: {xs: '10px', sm: '180px', },marginTop: '10px', marginRight: '40px', position: 'relative', height: tableHeight, overflowY: 'auto' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        rowCount={totalRecords}
                        autoHeight
                        disableExtendRowFullWidth={true}
                        disableRowSelectionOnClick
                        hideFooterPagination
                    />
                </Box>
                <div style={{marginTop: '-20px',marginLeft: '50%'}}>
                    <Newpagination
                        rows={totalRecords}
                        pageSize={pageSize}
                        page={page}
                        handlePageChange={handlePageClick}
                    />
                </div>
                <AddWarehousesModal open={addWarehousesModalOpen} onClose={handleCloseAdd} />
                <EditWarehousesModal open={editWarehousesModalOpen} onClose={handleCloseEdit} warehouse={selectedWarehouse} />
            </div>
            <NavBar selected="Warehouses" />
            <ConfirmationPopup
                open={confirmationOpen}
                onClose={handleCloseConfirmation}
                onConfirm={handleDeleteWarehouses}
                title="Delete Warehouse"
                message="Are you sure you want to delete the warehouse?"
            />
        </>
    );
};

export default WarehousesPage;
