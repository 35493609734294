import React from 'react';

interface MandatoryLabelProps {
  labelText: string;
  required?: boolean;
}

const MandatoryLabel: React.FC<MandatoryLabelProps> = ({ labelText, required = true }) => {
  if (!required) {
    return <span>{labelText}</span>;
  }

  return (
    <span>
      {labelText} <span style={{ color: 'red' }}>*</span>
    </span>
  );
};

export default MandatoryLabel;