import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, Button, TextField, IconButton, Tooltip,Box,SelectChangeEvent } from '@mui/material';
import { getDocuments, addDocuments,editDocuments, editVehicle,getDriverName,getOwnerName,addOwner,addOwnerDetails,deleteDocument,getWarehouses,fetchVendors,addWarehouseUserMapping, getWarehouseUserMapping,verifyOTP} from './action.ts';
import AddOwnerModal from './add-owner-modal.tsx';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import UploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import '../../Components/Table/table-style.scss';
import DatePicker from '../../Components/DatePicker/date-picker.tsx';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import './vehicle.scss';
import OTPVerificationModal from './otp-verification-modal.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface EditVehicleModalProps {
  open: boolean;
  onClose: () => void;
  vehicle: {
    id: any;
    vehicle_no: string;
    model: string;
    reg_date: string;
    owner: string;
    driver: string;
    owner_phone:any;
    driver_phone:any;
    driver_id:string;
    fuel_type: string;
  } | null;
}
interface Warehouse {
  id: number;
  name: string;
}
interface Vendor {
  id: number;
  name: string; 
}

const EditVehicleModal: React.FC<EditVehicleModalProps> = ({ open, onClose, vehicle }) => {
  const [formValues, setFormValues] = useState({
    vehicle_no: '',
    model: '',
    reg_date: '',
    owner: '',
    driver: '',
    driver_phone:'',
    owner_phone:''
  });
  const [initialValues, setInitialValues] = useState(formValues);
  const [selectedInsuranceDate, setSelectedInsuranceDate] = useState<string>('');
  const [selectedFitnessDate, setSelectedFitnessDate] = useState<any>('');
  const [initialInsuranceDate, setInitialInsuranceDate] = useState<any>('');
  const [initialFitnessDate, setInitialFitnessDate] = useState<any>('');
  const [getFitnessDoc, setGetFitnessDoc] = useState<string>('');
  const [getInsuranceDoc, setGetInsuranceDoc] = useState<string>('');
  const [documentList, setDocumentList] = useState<any[]>([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [documentToRemove, setDocumentToRemove] = useState<string | null>(null);
  const [ownerUserName, setOwnerUserName] = useState<string>('');
  const [isAddOwnerModalOpen, setIsAddOwnerModalOpen] = useState(false);
  const [driverUserName, setDriverUserName] = useState<string>('');
  const [ownerPhoneNumber, setOwnerPhoneNumber] = useState<string>('');
  const [driverPhoneNumber, setDriverPhoneNumber] = useState<string>('');
  const [documentDeleted, setDocumentDeleted] = useState(false);
  const [ownerId, setOwnerId] = useState<string>('');
  const [driverId, setDriverId] = useState<string>('');
  const [isOwnerFocused, setIsOwnerFocused] = useState<boolean>(false);
  const [isDriverFocused, setIsDriverFocused] = useState<boolean>(false);
  const [ownerError, setOwnerError] = useState<string | null>(null);
  const [driverError, setDriverError] = useState<string | null>(null);
  const [regDateError, setRegDateError] = useState<string | null>(null);
  const [modelError, setModelError] = useState<string | null>(null);
  const [warehouseError, setWarehouseError] = useState<string | null>(null);
  const [documentLocation, setDocumentLocation] = useState<string | null>(null);
  const [documentId, setDocumentId] = useState<number | null>(null);
  const [getInsuranceDate, setGetInsuranceDate] = useState<string | null>(null);
  const [getFitnessDate, setGetFitnessDate] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState<{ id: string; label: string }[]>([]);
  const [fuelType, setFuelType] = useState<{ id: string; label: string }[]>([]);
  const [fuelName, setFuelName] = useState<string>('');
  const [selectedVendor, setSelectedVendor] = useState<{ id: string; label: string }[]>([]);
  const [warehouseData,setWarehouseData] = useState<string | null>(null);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [otp, setOtp] = useState('');
  const storedUserDetails = localStorage.getItem("userdetails");
  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
  const userRole = userDetails ? userDetails.role : null
  const vendorName = userDetails? userDetails.vendor_name : null;
  const clientName = userDetails? userDetails.client_name : null;
  const vendorId = storedUserDetails ? JSON.parse(storedUserDetails).vendor_id:0;
  const maxDate = new Date();
  let otpVerified = '';
  const resetErrorMessages = () => {
    setOwnerError(null);
    setDriverError(null);
    setRegDateError(null);
    setModelError(null);
    setWarehouseError(null);
    setErrorMessage(null);
    setSelectedInsuranceDate('');
    setGetFitnessDate('');
    setGetInsuranceDate('');
    setSelectedFitnessDate('');
    setSelectedWarehouse([]);
    setWarehouseData('');
  };
  const handleClose = () => {
    resetErrorMessages();
    onClose();
  };
  const handleOwnerFocus = () => {
    setIsOwnerFocused(true);
    setOwnerUserName('');
  };
  const handleOwnerBlur = () => {
    if(ownerPhoneNumber.length === 10){
      fetchDriverDetails(ownerPhoneNumber, 'owner');
      }
    setIsOwnerFocused(false);
  };

  const handleDriverFocus = () => {
    setIsDriverFocused(true);
  };
  const handleDriverBlur = () => {
    setIsDriverFocused(false);
  };
  const fuelTypeOptions = [
    { id: 'diesel', label: 'Diesel' },
    { id: 'EV', label: 'EV' },
    { id: 'petrol', label: 'Petrol' },
    { id: 'CNG', label: 'CNG' }
  ];
  const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File | null }>({
    registrationCertificate: null,
    pollutionCertificate: null,
    fitnessCertificate: null,
    insurance: null,
    drivers_license: null,
    aadhar: null
  });
  const [icons, setIcons] = useState<{ [key: string]: string }>({
    registrationCertificate: 'upload',
    pollutionCertificate: 'upload',
    fitnessCertificate: 'upload',
    insurance: 'upload'
  });
  const fetchDocuments = () => {
    if (vehicle?.id) {
      getDocuments(
        vehicle.id,
        (response: any) => {
          const documentDetails = response.message?.vehicle_documents || [];
          setDocumentList(documentDetails);
          const updatedDocuments = documentDetails.map(doc => ({
            ...doc,
            expiry: doc.status === 'uploaded' ? doc.expiry || '' : ''
          }));
          const insuranceDoc = updatedDocuments.find(doc => 
            doc.document_name.trim() === 'insurance' && doc.status === 'uploaded'
          );
          const fitnessDoc = updatedDocuments.find(doc => 
            doc.document_name.trim() === 'fitness certificate' && doc.status === 'uploaded'
          );
        if (insuranceDoc) {
          setGetInsuranceDate(insuranceDoc.expiry);
          setInitialInsuranceDate(insuranceDoc.expiry);
          setGetInsuranceDoc(insuranceDoc);
        }
        if (fitnessDoc) {
          setGetFitnessDate(fitnessDoc.expiry);
          setInitialFitnessDate(fitnessDoc.expiry);
          setGetFitnessDoc(fitnessDoc);
        }
          const newIcons: { [key: string]: string } = {
            registrationcertificate: 'upload',
            pollutioncertificate: 'upload',
            fitnesscertificate: 'upload',
            insurance: 'upload'
          };
          documentDetails.forEach(doc => {
            if (doc.status === 'uploaded') {
              newIcons[doc.document_name.replace(' ', '')] = 'view';
            }
          });
          setIcons(newIcons);
        },
        (errMessage: any) => {
          toast.error(errMessage);
        }
      );
    }
  };
  useEffect(() => {
    if (open && vehicle?.id) {
        fetchDocuments();
      }
    if (vehicle) {
      const parseDate = (dateString: string) => {
        const [day, month, year] = dateString.split('-');
        return new Date(`${year}-${month}-${day}`);
      };
        const formatDate = (date: Date) => {
        return date.toISOString().split('T')[0];
      };
  
      const formattedRegDate = vehicle.reg_date ? formatDate(parseDate(vehicle.reg_date)) : '';
      setFormValues({
        vehicle_no: vehicle.vehicle_no || '',
        model: vehicle.model || '',
        reg_date:formattedRegDate || '',
        owner: vehicle.owner || '',
        driver: vehicle.driver || '',
        driver_phone:vehicle.driver_phone || '',
        owner_phone:vehicle.owner_phone || ''
      });
      setInitialValues({
        vehicle_no: vehicle.vehicle_no || '',
        model: vehicle.model || '',
        reg_date:formattedRegDate || '',
        owner: vehicle.owner || '',
        driver: vehicle.driver || '',
         driver_phone:vehicle.driver_phone || '',
        owner_phone:vehicle.owner_phone || ''
      })
        if(vehicle?.driver_id){
          getWarehouseUserMapping(
            vehicle?.driver_id,
            (response) => {setWarehouseData(response.user_warehouses[0]?.warehouse?.name || '');
            },
            (errorMessage) => {toast.error(errorMessage);}
          )
        }
      setOwnerUserName(vehicle.owner || '');
      setOwnerPhoneNumber(vehicle.owner_phone);
      setDriverPhoneNumber(vehicle.driver_phone);
      setDriverUserName(vehicle.driver || '');
    }
    if(vendorName !== '' && vehicle){
      getWarehouses(
      (response) => {setWarehouses(response.message.warehouses);
      },
      (errorMessage) => toast.error(errorMessage)
       );
     }
      else if (clientName !== '' && vehicle){
       fetchVendors(
         (response) => setVendors(response),
         (errorMessage) => toast.error(errorMessage)
     );
      } 
  }, [vehicle]);
  useEffect(() => {
    if (vehicle?.fuel_type) {
      const matchedOption = fuelTypeOptions.find(option => option.id === vehicle.fuel_type);
      if (matchedOption) {
        setFuelType([{ id: matchedOption.id.toString(), label: matchedOption.label }]);
      }
    }
  }, [vehicle?.fuel_type]);
  useEffect(() => {
    if (warehouseData) {
      const matchedWarehouse = warehouses.find(warehouse => warehouse.name === warehouseData);
      if (matchedWarehouse) {
        setSelectedWarehouse([{ id: matchedWarehouse.id.toString(), label: matchedWarehouse.name }]);
      }
    }
  }, [warehouseData, warehouses]);
  const handleChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
    }));
};
const handleModelChange = (event: SelectChangeEvent<string>) => {
  setFormValues(prevValues => ({
    ...prevValues,
    model: event.target.value
  }));
  setModelError(null);
};
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target; 
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    validatePhoneNumber(value, name);
    if (name === 'driver') {
      setDriverPhoneNumber(value);
      if (value.length === 10) {
        fetchDriverDetails(value, 'driver');
      } } else {
        setOwnerPhoneNumber(value);
    }
  };
  const validateModelName = (value: string) => {
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (!regex.test(value)) {
      return 'Invalid Model Name.';
    }
    return null;
  };
  const uploadFile = (type: string, file: File, insuranceDate: string , fitnessCerificateDate: string,action:string) => {
    let insuranceExpiryDate = '';
    let fitnessExpiryDate = '';
    if (type === 'insurance') {
      insuranceExpiryDate = insuranceDate; 
    } else if (type === 'fitnesscertificate') {
      fitnessExpiryDate = fitnessCerificateDate; 
    }
    if(action === 'add'){
    addDocuments(
        type === 'drivers_license' ? file : null,
        type === 'aadhar' ? file : null,
        type === 'registrationcertificate' ? file : null,
        type === 'insurance' ? file : null,
        type === 'fitnesscertificate' ? file : null,
        type === 'pollutioncertificate' ? file : null,
        vehicle?.id,
        fitnessExpiryDate,
        insuranceExpiryDate,
        (response) => {
            if (response.status === 200) {           
                setSelectedFiles((prevFiles) => ({
                  ...prevFiles,
                  [type]: file                 
                }));
                setIcons((prevIcons) => ({
                  ...prevIcons,
                  [type]: 'view'
                }));
                toast.success("Document Uploaded Successfully");
                setErrorMessage(null);   
                setDocumentLocation(response?.message?.location);
                setDocumentId(response?.message?.id)
                }              
        },
        (errMessage: any) => {  
          if (errMessage.status === 400) {
            let allErrorMessages: string[] = [];
            if (typeof errMessage.message === 'string') {
              allErrorMessages.push(errMessage.message);
              setSelectedFiles((prevFiles) => ({
                ...prevFiles,
                [type]: file
              }));
              setIcons((prevIcons) => ({
                ...prevIcons,
                [type]: 'view'
              }));
              toast.error(allErrorMessages.join(" "));
            } 
            else if (errMessage.status =413 ){
              toast.error("Document Size is large");
            }
            else if (errMessage.message && typeof errMessage.message === 'object') {
              const errors = errMessage.message.errors || {};
              for (const [key, messages] of Object.entries(errors)) {
                if (Array.isArray(messages)) {
                  allErrorMessages.push(...messages);
                }
              }
              setSelectedFiles((prevFiles) => ({
                ...prevFiles,
                [type]: null
              }));
              setIcons((prevIcons) => ({
                ...prevIcons,
                [type]: 'upload'
              }));
              toast.error(allErrorMessages.join(" "));
            }
            if (allErrorMessages.length > 0) {
              setErrorMessage(allErrorMessages.join(" "));
            } 
          } 
        });}
        else {
          editDocuments(
            type === 'drivers_license' ? file : null,
            type === 'aadhar' ? file : null,
            type === 'registrationcertificate' ? file : null,
            type === 'insurance' ? file : null,
            type === 'fitnesscertificate' ? file : null,
            type === 'pollutioncertificate' ? file : null,
            vehicle?.id,
            fitnessExpiryDate,
            insuranceExpiryDate,
            (response) => {
                if (response.status === 200) {           
                    setSelectedFiles((prevFiles) => ({
                      ...prevFiles,
                      [type]: file                 
                    }));
                    setIcons((prevIcons) => ({
                      ...prevIcons,
                      [type]: 'view'
                    }));
                    toast.success("Document Uploaded Successfully");
                    setErrorMessage(null);   
                 
                    }              
            },
            (errMessage: any) => {  
              if (errMessage.status === 400) {
                let allErrorMessages: string[] = [];
                if (typeof errMessage.message === 'string') {
                  allErrorMessages.push(errMessage.message);
                  setSelectedFiles((prevFiles) => ({
                    ...prevFiles,
                    [type]: file
                  }));
                  setIcons((prevIcons) => ({
                    ...prevIcons,
                    [type]: 'view'
                  }));
                  toast.error(allErrorMessages.join(" "));
                } 
                else if (errMessage.status =413 ){
                  toast.error("Document Size is large");
                }
                else if (errMessage.message && typeof errMessage.message === 'object') {
                  const errors = errMessage.message.errors || {};
                  for (const [key, messages] of Object.entries(errors)) {
                    if (Array.isArray(messages)) {
                      allErrorMessages.push(...messages);
                    }
                  }
                  setSelectedFiles((prevFiles) => ({
                    ...prevFiles,
                    [type]: null
                  }));
                  setIcons((prevIcons) => ({
                    ...prevIcons,
                    [type]: 'upload'
                  }));
                  toast.error(allErrorMessages.join(" "));
                }
                if (allErrorMessages.length > 0) {
                  setErrorMessage(allErrorMessages.join(" "));
                } 
              } 
            });}
        
      }; 
      const handleConfirmRemove = () => {
        if (documentToRemove && vehicle?.id) {
          const document = documentList.find(doc => 
          doc.document_name.replace(' ', '').toLowerCase() === documentToRemove.toLowerCase() &&
          doc.status === 'uploaded'
          );   
          if(documentId){
          deleteDocument(
            documentId,
            vehicle?.id, 
            (response) => {
              toast.success(response?.message);
              setSelectedFiles((prevFiles) => ({
                ...prevFiles,
                [documentToRemove]: null
              }));
              setIcons((prevIcons) => ({
                ...prevIcons,
                [documentToRemove]: 'upload'
              }));
              setDocumentLocation('');
              setSelectedInsuranceDate('');
              setGetInsuranceDate('');
              setSelectedFitnessDate('');
              setGetFitnessDate('');
              setErrorMessage('');
              setInitialInsuranceDate('');
              setInitialFitnessDate('');
            },
            (errorMessage) => {   
              toast.error(errorMessage.data.message);
            }
          );
        }
        else {
          deleteDocument(
            document?.id,
             vehicle?.id, 
             (response) => {
               toast.success(response?.message);
               setSelectedFiles((prevFiles) => ({
                 ...prevFiles,
                 [documentToRemove]: null
               }));
               setIcons((prevIcons) => ({
                 ...prevIcons,
                 [documentToRemove]: 'upload'
               }));
               setDocumentLocation('');
               setSelectedInsuranceDate('');
               setGetInsuranceDate('');
               setSelectedFitnessDate('');
               setGetFitnessDate('');
               setInitialInsuranceDate('');
               setInitialFitnessDate('');
             },
             (errorMessage) => {   
               toast.error(errorMessage.data.message);
             }
           );
        }
        } 
        else {
          toast.error('Document not found.');
        }
        setDocumentToRemove(null);
        setIsConfirmationOpen(false);
      }
      const handleInsuranceDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
        if (!selectedFiles.insurance && !getInsuranceDoc){
          setErrorMessage("Choose file to upload Insurance ");
        }
        if (value === ''){
          setErrorMessage('');
        }
        setSelectedInsuranceDate(value);
        if ((selectedFiles.insurance || getInsuranceDoc) && value) {
          if (!initialInsuranceDate) {
            setInitialInsuranceDate(event.target.value);
            uploadFile('insurance', selectedFiles?.insurance || getInsuranceDoc, value, selectedFitnessDate,'add');
          } else {
            uploadFile('insurance',selectedFiles?.insurance || getInsuranceDoc, value, selectedFitnessDate,'edit');
          }
        }
      };
      const handleAddOwner = (name: string, phoneNumber: string,email:any,address:any,ifsc:any,accountNumber:any,remarks:any) => {
         addOwner(name,phoneNumber,(response) => {
          toast.success(response.message);
          setIsAddOwnerModalOpen(false)
          const ownerId = response.vehicle_owner.id;
       if(ownerId){
        addOwnerDetails(ownerId,accountNumber,ifsc,remarks,email,address,(response) => {
          toast.success(response.message);},
        (errorMessage) => {
          toast.error(errorMessage);
        } );
       }
        },
        (errorMessage) => {
          toast.error(errorMessage);
        })
          setOwnerUserName(name);
          setOwnerPhoneNumber(phoneNumber);
        };
   const handleVerifyOTP = (otp: string): Promise<{ success: boolean; message?: string }> => {
     return new Promise((resolve, reject) => {
       verifyOTP(
         driverPhoneNumber,
         '91',
         otp,
         (response) => {
          
           if (response.data.status === 200) {
             toast.success(response.data.message);
             otpVerified = '1';
             resolve({ success: true });
             handleSave();
           } else {
            resolve({ success: false, message: response.data.message|| 'OTP verification failed.' });
           }
         },
         (errorMessage) => {
           resolve({ success: false, message: errorMessage });
         }
       );
     });
   };
      const handleFitnessDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
        if (!selectedFiles.fitnesscertificate && !getFitnessDoc){
          setErrorMessage("Choose file to upload Fitness Certificate");
        }
        if (value === ''){
          setErrorMessage('');
        }
        setSelectedFitnessDate(value);
        if ((selectedFiles.fitnesscertificate || getFitnessDoc) && value) {
          if(!initialFitnessDate){
            setInitialFitnessDate(value);
          uploadFile('fitnesscertificate', selectedFiles.fitnesscertificate,'', value,'add');
          }else{
            uploadFile('fitnesscertificate',selectedFiles.fitnesscertificate,'',value,'edit');
          }
        }
      };
      const handleCancelRemove = () => {
        setDocumentToRemove(null);
        setIsConfirmationOpen(false);
      };
      const validatePhoneNumber = (value: string, name:string) => {
        if (/[^0-9]/.test(value)) {
          if (name === 'owner') {
            setOwnerError('Invalid Phone Number');
          } else {
            setDriverError('Invalid Phone Number');
          }
          return;
        }
        if (value.length > 10) {
          if (name === 'owner') {
            setOwnerError('Phone number cannot exceed 10 digits.');
          } else {
            setDriverError('Phone number cannot exceed 10 digits.');
          }
          return;
        } 
        if (value.length === 10) {
          if (name === 'owner'){
            setOwnerError('')
          } else {
            setDriverError('')
          }
          return;
        }
        if (/^[0-5]/.test(value)) {
          if (name === 'owner') {
            setOwnerError('Phone number must start with 6,7,8,9');
          } else {
            setDriverError('Phone number must start with 6,7,8,9');
          }
          return;
        }
        if (name === 'owner' && value === '') {
          setOwnerError('');
        } else if (name === 'driver' && value === ''){
          setDriverError('');
        }   
      };
      
      const handleIconClick = (type: string, action: string) => {
        if (action === 'view' && (type === 'pollutioncertificate' || type === 'registrationcertificate')) {
          const document = documentList.find(doc => doc.document_name.replace(' ', '').toLowerCase() === type.toLowerCase() && doc.status === 'uploaded');
          if(document && !documentDeleted){ 
            const url = document.location;
            window.open(`${global.documents_image_url}${url}`, '_blank');      } 
          else if (documentLocation){
            const url = documentLocation;
            window.open(`${global.documents_image_url}${url}`, '_blank');      }
          else{
            toast.error('Document not found.');
          }
        } else if (action === 'view' &&((type === 'fitnesscertificate' && (getFitnessDate|| selectedFitnessDate)) || (type === 'insurance' && (getInsuranceDate|| selectedInsuranceDate))) ){
          const document = documentList.find(doc => doc.document_name.replace(' ', '').toLowerCase() === type.toLowerCase() && doc.status === 'uploaded');
          if(document && !documentDeleted){
            const url = document.location;
            window.open(`${global.documents_image_url}${url}`, '_blank');      } 
          else if (documentLocation){
            const url = documentLocation;
            window.open(`${global.documents_image_url}${url}`, '_blank');      }
          else{
            toast.error('Document not found.');
          }
        } 
        else if (action === 'remove') {
            setDocumentToRemove(type);
            setIsConfirmationOpen(true);
            setDocumentDeleted(true);      
        }
      };
      const fetchDriverDetails = (phoneNumber: string, name: string) => {
        if (phoneNumber.length < 10 && phoneNumber.length > 0){
          if (name === 'owner'){
            setOwnerError("Phone number must be 10 digits")         
          }
          else if (phoneNumber.length < 10 && phoneNumber.length > 0){
            setDriverError("Phone number must be 10 digits")
          }
          else{
            setDriverError('');
            setOwnerError('');
          }
          return;
        }
        if (phoneNumber.length === 10) {
          if (name === 'owner') {
            getOwnerName(phoneNumber,
              (response: any) => {
                if (response.status === 200 ) {
                  const user = response.message.vehicles_owners_list[0];
                    setOwnerUserName(user.name);
                    setOwnerPhoneNumber(user.phone);
                    setOwnerId(user.id); 
                } 
              },
              (errMessage: any) => {        
                if (errMessage.status === 404 || errMessage.message === "No Client Found") {
                  setIsAddOwnerModalOpen(true);
                  setOwnerId("");
                  setOwnerUserName("");
                } else {
                  setOwnerId("");
                  setOwnerError("User Not found"); 
                  setOwnerUserName("");
                }
              })
          }
else{
          getDriverName(
            phoneNumber,
            (response: any) => {
              if (response.status === 200 ) {
                const user = response.message.user[0];
        
                  setDriverUserName(user.user_name);
                  setDriverPhoneNumber(user.user_phone);
                  setDriverId(user.user_unique_id);
                
              } 
            },
            (errMessage: any) => {        
              if(errMessage.status === 400) {
                if(errMessage.message.user.length === 0){
                 setDriverUserName('');
                    setDriverError("User Not Found")
                    setDriverPhoneNumber('');           
                }    
                else
                toast.error(errMessage.message);
              }
            }
          );
        }
        } 
      }; 
      const isEqual = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) return false;
        for (let key of keys1) {
          if (obj1[key] !== obj2[key]) return false;
        }
        return true;
      };
      
      const handleSave = () => {
        const hasFormValuesChanged = !isEqual(formValues, initialValues);
        const hasFuelTypeChanged = fuelType[0]?.id !== vehicle?.fuel_type;
        const hasWarehouseChanged = warehouseData !== selectedWarehouse[0]?.label;
        if (!hasFormValuesChanged && !hasFuelTypeChanged && !hasWarehouseChanged) {
          toast.info("No changes detected");
          onClose();
          return;
        }
        if (!formValues.model) {
          setModelError('This field is required.');
          return;
        }
        if (!formValues.reg_date) {
          setRegDateError('This field is required.');
          return;
        }     
        if (!driverUserName) {
          setDriverError('This field is required.');
          return;
        }
        if (selectedWarehouse.length === 0) {
          setWarehouseError('This field is required.');
          return;
        }
        if (ownerError || driverError || regDateError || modelError || errorMessage) {
          toast.error('Please fix the errors before saving.');
          return;
        }
        if (!hasFormValuesChanged && !hasFuelTypeChanged && hasWarehouseChanged) {
          const warehouseId = selectedWarehouse.length > 0 ? parseInt(selectedWarehouse[0].id, 10) : null;
          addWarehouseUserMapping(
            driverId || vehicle?.driver_id,
            warehouseId,
            (mappingResponse) => {
              if (mappingResponse.data.status === 200) {
                toast.error(mappingResponse?.data?.message); 
              } else if (mappingResponse.data.status === 201) {
                toast.success(mappingResponse.data.message);
                onClose();
              }
            },
            (errorMessage) => {
              toast.error(errorMessage.message);
            }
          );
          return;
        }
        if (!hasFormValuesChanged && !hasFuelTypeChanged && driverId !== vehicle?.driver_id) {
          editVehicle(
            formValues.model,
            formValues.reg_date,
            vehicle?.vehicle_no,
            driverId,
            ownerId,
            fuelType[0]?.id || '',
            otpVerified,
            (response) => {
              handleVehicleEditResponse(response);
              if (hasWarehouseChanged || driverId !== vehicle?.driver_id) {
                const warehouseId = selectedWarehouse.length > 0 ? parseInt(selectedWarehouse[0].id, 10) : null;
                addWarehouseUserMapping(
                  driverId || vehicle?.driver_id,
                  warehouseId,
                  (mappingResponse) => {
                    if (mappingResponse.data.status === 200) {
                      toast.error(mappingResponse?.data?.message); 
                    } else if (mappingResponse.data.status === 201) {
                      toast.success(mappingResponse.data.message);
                      onClose();
                    }
                  },
                  (errorMessage) => {
                    toast.error(errorMessage.message);
                  }
                );
              }
            },
            (errorMessage) => {
              toast.error(errorMessage.message); 
            }
          );
        }
        if ((hasFormValuesChanged || hasFuelTypeChanged) && !hasWarehouseChanged && driverId === vehicle?.driver_id) {
          editVehicle(
            formValues.model,
            formValues.reg_date,
            vehicle?.vehicle_no,
            driverId,
            ownerId,
            fuelType[0]?.id || '',
            otpVerified,
            (response) => handleVehicleEditResponse(response),
            (errorMessage) => {
              toast.error(errorMessage.message); 
            }
          );
          return;
        }
          if ((hasFormValuesChanged || hasFuelTypeChanged) && (hasWarehouseChanged || driverId !== vehicle?.driver_id)) {
          editVehicle(
            formValues.model,
            formValues.reg_date,
            vehicle?.vehicle_no,
            driverId,
            ownerId,
            fuelType[0]?.id || '',
            otpVerified,
            (response) => {
              handleVehicleEditResponse(response);
              if (hasWarehouseChanged || driverId !== vehicle?.driver_id) {
                const warehouseId = selectedWarehouse.length > 0 ? parseInt(selectedWarehouse[0].id, 10) : null;
                addWarehouseUserMapping(
                  driverId || vehicle?.driver_id,
                  warehouseId,
                  (mappingResponse) => {
                    if (mappingResponse.data.status === 200) {
                      toast.error(mappingResponse?.data?.message); 
                    } else if (mappingResponse.data.status === 201) {
                      toast.success(mappingResponse.data.message);
                      onClose();
                    }
                  },
                  (errorMessage) => {
                    toast.error(errorMessage.message);
                  }
                );
              }
            },
            (errorMessage) => {
              toast.error(errorMessage.message); 
            }
          );
        }
      };
      const handleVehicleEditResponse = (response) => {
        if (response.status === 200) {
          if (response?.data?.status === 308) {
            toast.error(response.data.message);
          } else if (response?.data?.status === 400) {
            toast.error("Please contact your administrator");
          } else if (
            response?.data?.message === 'OTP sent.' ||
            response?.data?.message === 'Old OTP expired. New OTP generated and re-sent.' ||
            response?.data?.message === 'OTP re-sent.'
          ) {
            setIsOTPModalOpen(true);
          } else {
            toast.success(response.message.message);
            if (!(warehouseData !== selectedWarehouse[0]?.label || driverId !== vehicle?.driver_id)) {
              onClose();
            }
          }
        } else {
          toast.error(response?.message || 'An error occurred');
        }
      };
      const isValidFileType = (file: File, allowedTypes: string[]) => {
      return allowedTypes.includes(file.type);
      };    
      const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const fileInput = event.target; 
        const file = event.target.files?.[0] || null;      
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
        const maxFileSize = 1 * 1024 * 1024; 
        if (!file) {
          toast.error('No file selected. Please choose a file to upload.');
          return;
        }
        if (!isValidFileType(file, allowedTypes)) {
          toast.error('Invalid file type. Please upload a jpeg, jpg, png, or pdf file.');
          return;
        }
        if (file.size > maxFileSize) {
          toast.error('File size exceeds 1 MB. Please upload a smaller file.');
          return;
        }
        setSelectedFiles((prevFiles) => ({
          ...prevFiles,
          [type]: file
        }));
        setIcons((prevIcons) => ({
          ...prevIcons,
          [type]: 'upload'
        }));     
        if (vehicle?.id && file) {
          if (type === 'fitnesscertificate' && !selectedFitnessDate) {            
            setErrorMessage('Expiry date is required for Fitness Certificate');
            setIcons((prevIcons) => ({
              ...prevIcons,
              [type]: 'view'
            }));
          } else if (type === 'insurance' && !selectedInsuranceDate) {         
            setErrorMessage('Expiry date is required for Insurance Certificate');
            setIcons((prevIcons) => ({
              ...prevIcons,
              [type]: 'view'
            }));
          } 
          else {
            setErrorMessage('');
            uploadFile(type, file, selectedFitnessDate, selectedInsuranceDate,'add');
          }
        }
        fileInput.value = '';
            };
      const warehouseOptions = warehouses.map((warehouse) => ({
        id: warehouse.id.toString(),
        label: warehouse.name,
      }));
      const handleFuelTypeChange = (event: SelectChangeEvent<string>) => {
        const selectedId = event.target.value; 
        setFuelName(selectedId);
        const selectedOption = fuelTypeOptions.find(option => option.id === selectedId);
        if (selectedOption) {
          setFuelType([selectedOption]);
       } else {
          setFuelType([]);
        }
      };
      const handleWarehouseDropdownChange = (event: SelectChangeEvent<string>) => {
        const selectedId = event.target.value;
        const selectedOption = warehouseOptions.find(option => option.id === selectedId);
        setWarehouseError('');
        if (selectedOption) {
          setSelectedWarehouse([selectedOption]);
       } else {
          setSelectedWarehouse([]);
        }
      };
      const handleVendorDropdownChange = (event: SelectChangeEvent<string>) => {
        const selectedId = event.target.value;
        const selectedOption = vendorOptions.find(option => option.id === selectedId);
        if (selectedOption) {
          setSelectedVendor([selectedOption]);
        } else {
          setSelectedVendor([]);
        }
     };
     const modelOptions = [
      { id: 'EV', label: 'EV' },
      { id: 'Tata Ace', label: 'Tata Ace' }
    ];
      const vendorOptions = vendors.map((vendor) => ({
        id: vendor.id.toString(),
        label: vendor.name,
      }));
      const renderFileInput = (label: string, type: string) => (
        <div>
          <p style={{  display: 'inline-block', width: '165px', padding: '0px !important' }}>{label}</p>
          <input
            accept="*"
            style={{ display: 'none' }}
            id={`${type}-file`}
            type="file"
            onChange={(e) => handleFileChange(e, type)}
          />
          <label htmlFor={`${type}-file`}>
          <Tooltip title="Upload File">
              <IconButton color="primary" component="span" sx={{ padding: 0 }} >
                {icons[type] === 'upload' && <UploadIcon />}
              </IconButton>
            </Tooltip>
          </label>
          {icons[type] === 'view' && (
            <>
              <Tooltip title="View File">
                <span
                  style={{ cursor: 'pointer', color: 'blue' }}
                  onClick={() => handleIconClick(type, 'view')}
                >
                  View
                </span>
              </Tooltip>
              <Tooltip title="Remove File">
                <IconButton sx={{ color: 'red' }} component="span" onClick={() => handleIconClick(type, 'remove')}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      );
      return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: '750px', height: 'auto' } }}>
        
            <Box
              sx={{
                marginLeft: {
                  sm: '-180px',
                },
              }}
            >
              <Heading title={`Vehicle - ${formValues.vehicle_no}`} closeIcon="true" onClose={handleClose} />
            </Box>
         
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '15px',
                '@media (max-width: 600px)': {
                  flexDirection: 'column',
                },
              }}
            >
               <Dropdown
                options={modelOptions}
                selectedValue={formValues.model}
                handleChange={handleModelChange}
                placeholder="Select Model"
                label="Select Model"
                width="200px"
                height="40px"
                error={!!modelError}
                helperText={modelError}
              />
              <DatePicker
                id="registrationDate"
                name="reg_date"
                label='Registration Date'
                value={formValues.reg_date}
                onChange={handleChange}
                maxDate={maxDate}
              />
              
              <TextField
                size="small"
                label={ownerUserName ? 'Owner Name' : 'Owner Phone Number'}
                placeholder="Owner Phone Number"
                name="owner"
                value={isOwnerFocused ? ownerPhoneNumber : ownerUserName || ownerPhoneNumber}
                onChange={handlePhoneNumberChange}
                sx={{ width: { xs: '100%', sm: 200 } }}
                error={!!ownerError}
                helperText={ownerError}
                onFocus={handleOwnerFocus}
                onBlur={handleOwnerBlur}
              />
              <TextField
                label={driverUserName ? 'Driver Name' : 'Driver Phone Number'}
                size="small"
                name="driver"
                placeholder="Driver Phone Number"
                value={isDriverFocused ? driverPhoneNumber : driverUserName || driverPhoneNumber}
                onChange={handlePhoneNumberChange}
                onFocus={handleDriverFocus}
                onBlur={handleDriverBlur}
                error={!!driverError}
                helperText={driverError}
                sx={{ width: { xs: '100%', sm: 200 } }}
              />
              <Dropdown
                options={fuelTypeOptions}
                selectedValue={fuelType.length > 0 ? fuelType[0].id : ''}
                handleChange={handleFuelTypeChange}
                placeholder="Select Fuel"
                label="Select Fuel Type"
                width="200px"
                height="40px"
              />
              {userRole === 'vendor_supervisor' || userRole === 'vendor_manager' ? (
                <>
                  <Dropdown
                    options={warehouseOptions}
                    selectedValue={selectedWarehouse.length > 0 ? selectedWarehouse[0].id : ''}
                    handleChange={handleWarehouseDropdownChange}
                    placeholder="Select Warehouse"
                    label="Select Warehouse"
                    width="200px"
                    height="40px"
                    error={!!warehouseError}
                    helperText={warehouseError}
                  />
                  <TextField
                    size="small"
                    type="text"
                    name="vendorName"
                    disabled
                    value={vendorName}
                    label="Vendor Name"
                    sx={{ width: { xs: '100%', sm: 200 } }}
                  />
                </>
              ) : userRole === 'client_supervisor' || userRole === 'client_manager' ? (
                <>
                  <Dropdown
                    options={vendorOptions}
                    selectedValue={selectedVendor.length > 0 ? selectedVendor[0].id : ''}
                    handleChange={handleVendorDropdownChange}
                    placeholder="Select Vendor"
                    label="Select Vendor"
                    width="200px"
                    height="40px"
                  />
                  <TextField
                    size="small"
                    variant="outlined"
                    type="text"
                    name="clientName"
                    value={clientName}
                    disabled
                    sx={{ width: { xs: '100%', sm: 200 } }}
                  />
                </>
              ) : (
                <p style={{ color: 'red' }}>User role is not vendor_supervisor, vendor_manager, client_supervisor, or client_manager</p>
              )}
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              {renderFileInput('Registration Certificate', 'registrationcertificate')}
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              {renderFileInput('Pollution Certificate', 'pollutioncertificate')}
            </Box>
            <Box sx ={{ display: 'flex', flexWrap:'wrap'}}>
            <Box>
              {renderFileInput('Fitness Certificate', 'fitnesscertificate')}
              
            </Box>
            <DatePicker
                id="fitnessExpiryDate"
                name="fitnessExpiryDate"
                value={selectedFitnessDate || getFitnessDate || ''}
                label="Expiry Date"
                onChange={handleFitnessDateChange}
                labelMargin={'30px'}
              />
            </Box>
            <Box sx ={{ display: 'flex', flexWrap:'wrap'}}>
            <Box>
              {renderFileInput('Insurance', 'insurance')}
            </Box>
            <DatePicker
                id="insuranceExpiryDate"
                name="insuranceExpiryDate"
                value={selectedInsuranceDate || getInsuranceDate || ''}
                label="Expiry Date"
                onChange={handleInsuranceDateChange}
                labelMargin={'30px'}
              />
            </Box>
            {errorMessage && (
            <div style={{ color: 'red', marginTop: '-10px', fontSize:'15px' }}>
              {errorMessage}
            </div>
          )}
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
            <Button className="custom-button" onClick={handleSave}>
              Save
            </Button>
            <Button className="custom-cancel-button" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
          <ConfirmationPopup
            open={isConfirmationOpen}
            onClose={handleCancelRemove}
            onConfirm={handleConfirmRemove}
            title="Confirm Removal"
            message="Are you sure you want to remove this file?"
          />
           <AddOwnerModal
        isOpen={isAddOwnerModalOpen}
        handleClose={() => setIsAddOwnerModalOpen(false)}
        phoneNumber={ownerPhoneNumber}
        onAddOwner={handleAddOwner}
      />
           <OTPVerificationModal
        isOpen={isOTPModalOpen}
        handleClose={() => setIsOTPModalOpen(false)}
        phoneNumber={driverPhoneNumber}
        handleVerifyOTP={handleVerifyOTP}
      />
        </Dialog>
      );
    };
    export default EditVehicleModal;