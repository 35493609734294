import React from "react";

const formatDate = (dateString: string): React.ReactNode => {
  if (!dateString) return null;

  const hasTime = /[T ]\d{2}:\d{2}:\d{2}/.test(dateString);
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "Invalid Date";

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  if (!hasTime) return formattedDate;

  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = String(hours % 12 || 12).padStart(2, "0");
  const formattedTime = `${formattedHours}:${minutes} ${amPm}`;
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      lineHeight: '1.2'
    }}>
      <div>{formattedDate}</div>
      <div >{formattedTime}</div>
    </div>
  );
};

export default formatDate;