import React, { FC, useState,useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box } from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import { addUsers } from './action.ts';
import {validateEmail} from '../../Utils/EmailValidation/email-validation.tsx';
import {validateAddress} from '../../Utils/AddressValidation/address-validation.tsx';
import {validateDriverName} from '../../Utils/NameValidation/name-validation.tsx';
import { validateRemarks } from '../../Utils/RemarksValidation/remarks-validation.tsx';
import {validatePassword} from '../../Utils/PasswordValidation/password-validation.tsx';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import {validateConfirmpassword} from '../../Utils/ConfirmPasswordValidation/confirm-password-validation.tsx';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SelectChangeEvent } from '@mui/material/Select';
import WarehousesModal from './add-warehouse-modal.tsx';

interface AddUsersModalProps {
    open: boolean;
    onClose: () => void;
}

const AddUsersModal: FC<AddUsersModalProps> = ({ open, onClose }) => {
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [userNameError, setUserNameError] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [mobileNumber, setMobileNumber] = useState<string>('');
    const [mobileNumberError, setMobileNumberError] = useState<string>('');
    const [whatsappNumber, setWhatsappNumber] = useState<string>('');
    const [whatsappNumberError, setWhatsappNumberError] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const [roleError,setRoleError] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');
    const [clientOptions,setClientOptions]= useState<{id: string , label: string}[]>([]);
    const [selectedClient, setSelectedClient] = useState<{ id: string, name: string } | null>(null);
    const [roleLabel, setRoleLabel] = useState<string>('');
    const [role, setRole] = useState<string>(''); 
    const [warehousesModalOpen, setWarehousesModalOpen] = useState<boolean>(false);
    const storedUserDetails = localStorage.getItem("userdetails");
    const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
    const userRole = userDetails ? userDetails.role.toLowerCase() : null;
    const resetState = () => {
        setUserName('');
        setUserNameError('');
        setEmail('');
        setEmailError('');
        setMobileNumber('');
        setMobileNumberError('');
        setWhatsappNumber('');
        setWhatsappNumberError('');
        setPassword('');
        setPasswordError('');
        setConfirmPassword('');
        setConfirmPasswordError('');
        setRole('');
        setRoleError('');
        setRoleLabel('')
    };

    const handleEmailBlur = () => {
        if (email === '') {
            setEmailError('');
        } else if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
        } else {
            setEmailError('');
        }
    };

    const transformRole = (selectedRole: string) => {
        if (userRole === 'client_company_admin') {
            switch (selectedRole) {
                case 'admin':
                    return 'client_company_Admin';
                case 'manager':
                    return 'client_manager';
                case 'supervisor':
                    return 'client_supervisor';
                default:
                    return '';
            }
        } else if (userRole === 'vendor_company_admin') {
            switch (selectedRole) {
                case 'Admin':
                    return 'vendor_company_Admin';
                case 'manager':
                    return 'vendor_manager';
                case 'supervisor':
                    return 'vendor_supervisor';
                default:
                    return '';
            }
        }
        return '';
    };

    const handleSave = () => {
        let hasError = false;
        if (!userName) {
        setUserNameError('User Name is required');
        hasError = true;
        }
        if (!mobileNumber) {
        setMobileNumberError('Mobile number is required');
        hasError = true;
        }
        if (!whatsappNumber) {
        setWhatsappNumberError('Mobile number is required');
        hasError = true;
        }
        if (!email){
        setEmailError('Email is required');
        hasError=true;
        }
        if (!password) {
        setPasswordError('Password is required');
        hasError = true;
        }
        if (!role){
        setRoleError("Role is required");
        hasError = true;
        }
        if (!confirmPassword) {
        setConfirmPasswordError('Confirm Password is Required');
        hasError = true;
        }
        if (userNameError || emailError || mobileNumberError || passwordError || confirmPasswordError || roleError) {
        toast.error("Please fix the errors");
        return;
        }
        if (hasError) {
        toast.error("Please fix the errors");
        return;
        }

        addUsers(
            userName,
            mobileNumber,
            whatsappNumber,
            email,
            password,
            role,
            confirmPassword,
            '',
            '',
            '91',
            userDetails.user.companies_id,
            (response) => {
                toast.success(response.message);
                setUserId(response.user.id);
                setWarehousesModalOpen(true);
                resetState();
                onClose();
            },
            (errorMessage) => {
                if (errorMessage) {
                    toast.error(errorMessage);
                }
            }
        );
    };

    const handleCancel = () => {
        resetState();
        onClose();
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setPassword(value);
        const errorMessage = validatePassword(value, confirmPassword);
        setPasswordError(errorMessage);
    };

    const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setUserName(value);
        if (value === '') {
            setUserNameError('');
        } else if (validateDriverName(value)) {
            setUserNameError('');
        } else {
            setUserNameError('Invalid User Name');
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setEmail(value);
        setEmailError('');
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setConfirmPassword(value);
        const errorMessage = validateConfirmpassword(value, password);
        setConfirmPasswordError(errorMessage);
    };
      const handlePasswordBlur = () => {
        if (password === '') {
            setPasswordError("Password is Required");
        } else {
            const errorMessage = validatePassword(password, confirmPassword);
            setPasswordError(errorMessage);
        }
    };

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setMobileNumber(value);

        if (value === '') {
            setMobileNumberError('');
            return;
        }
        const { errorMessage } = validatePhoneNumber(value);
        setMobileNumberError(errorMessage || '');
    };
    const handlePhoneNumberBlur = () => {
        if (mobileNumber === '') {
            setMobileNumberError('');
        } else if (mobileNumber.length < 10) {
            setMobileNumberError('Please enter a 10 digit number');
        }
    };
    const handleWhatsappNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setWhatsappNumber(value);
        if (value === '') {
            setWhatsappNumberError('');
            return;
        }
        const { errorMessage } = validatePhoneNumber(value);
        setWhatsappNumberError(errorMessage || '');
    };

    const handleWhatsappNumberBlur = () => {
        if (mobileNumber === '') {
            setWhatsappNumberError('');
        } else if (mobileNumber.length < 10) {
            setWhatsappNumberError('Please enter a 10 digit number');
        }
    };
    const handleUserNameBlur = () => {
        if (userName === '') {
            setUserNameError('');
        } else if (!validateRemarks(userName)) {
            setUserNameError('Invalid User Name');
        }
    };

    const handleConfirmPasswordBlur = () => {
        if (confirmPassword === '') {
            setConfirmPasswordError('Confirm Password is Required');
        } else {
            const errorMessage = validateConfirmpassword(confirmPassword, password);
            setConfirmPasswordError(errorMessage);
        }
    };

    const handleRoleChange = (event: SelectChangeEvent<string>) => {
        const selectedRole = event.target.value;
        const transformedRole = transformRole(selectedRole);
        setRoleLabel(selectedRole);
        setRole(transformedRole);
        if(selectedRole !== ''){
            setRoleError('');
        }
    };

    const roleOption = [
        { id: "admin", label: 'Admin' },
        { id: 'manager', label: 'Manager' },
        { id: 'supervisor', label: 'Supervisor' }
    ];

    return (
        <>
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{padding:'0px'}}>
                <Box sx={{ marginLeft: { xs: '0px', sm: '-180px' }}}>
                    <Heading title="Add Users" closeIcon="true" onClose={onClose} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
                    <p style={{ fontSize: '17px' }}>User Name</p>
                </div>
                <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                    <Box display="flex" alignItems="center">
                        <TextField
                            size='small'
                            name='user_name'
                            placeholder='User Name'
                            value={userName}
                            onChange={handleUserNameChange}
                            onBlur={handleUserNameBlur}
                            error={!!userNameError}
                            helperText={userNameError}
                            variant="outlined"
                        />
                    </Box>
                </div>
                <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
                    <p style={{ fontSize: '17px' }}>Role</p>
                </div>
                <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                    <Box display="flex" alignItems="center">
                        <Dropdown
                            options={roleOption}
                            label={"Choose Role"}
                            placeholder='Choose Role'
                            selectedValue={roleLabel}
                            handleChange={handleRoleChange}
                            width='222.4px'
                            height='40px'
                            error={!!roleError}
                            helperText={roleError}
                        />
                    </Box>
                </div>
                <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
                    <p style={{ fontSize: '17px' }}>Mobile Number</p>
                </div>
                <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                    <Box display="flex" alignItems="center">
                        <TextField
                            size="small"
                            name="mobileNumber"
                            placeholder="Mobile Number"
                            value={mobileNumber}
                            onChange={handlePhoneNumberChange}
                            onBlur={handlePhoneNumberBlur}
                            error={!!mobileNumberError}
                            helperText={mobileNumberError}
                            variant="outlined"
                        />
                    </Box>
                </div>
                <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
                    <p style={{ fontSize: '17px' }}>Whatsapp Number</p>
                </div>
                <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                    <Box display="flex" alignItems="center">
                        <TextField
                            size="small"
                            name="mobileNumber"
                            placeholder="Mobile Number"
                            value={whatsappNumber}
                            onChange={handleWhatsappNumberChange}
                            onBlur={handleWhatsappNumberBlur}
                            error={!!whatsappNumberError}
                            helperText={whatsappNumberError}
                            variant="outlined"
                        />
                    </Box>
                </div>
                <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
                    <p style={{ fontSize: '17px' }}>Email</p>
                </div>
                <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                    <Box display="flex" alignItems="center">
                        <TextField
                            size='small'
                            name='email'
                            placeholder='Email'
                            value={email}
                            onChange={handleEmailChange}
                            onBlur={handleEmailBlur}
                            error={!!emailError}
                            helperText={emailError}
                            variant="outlined"
                        />
                    </Box>
                </div>
                <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
                    <p style={{ fontSize: '17px' }}>Password</p>
                </div>
                <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                    <Box display="flex" alignItems="center">
                        <TextField
                            size='small'
                            name='password'
                            type = 'password'
                            placeholder='Password'
                            value={password}
                            onChange={handlePasswordChange}
                            onBlur={handlePasswordBlur}
                            error={!!passwordError}
                            helperText={passwordError}
                            variant="outlined"
                        />
                    </Box>
                </div>
                <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
                    <p style={{ fontSize: '17px' }}>Confirm Password</p>
                </div>
                <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
                    <Box display="flex" alignItems="center">
                        <TextField
                            size='small'
                            name='confirm_password'
                             type = 'password'
                            placeholder='Confirm Password'
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            onBlur={handleConfirmPasswordBlur}
                            error={!!confirmPasswordError}
                            helperText={confirmPasswordError}
                            variant="outlined"
                        />
                    </Box>
                </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    onClick={handleCancel}
                    className="custom-cancel-button"
                    style={{ marginRight: '10px' }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    className="custom-button"
                    style={{ marginLeft: '10px' }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
         <WarehousesModal
         open={warehousesModalOpen}
         onClose={() => setWarehousesModalOpen(false)}
         userId = {userId}
         source="addUserModal" 
     />
     </>
    );
};

export default AddUsersModal;