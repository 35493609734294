import React, { FC, useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box } from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import { editUsers } from './action.ts';
import { validateEmail } from '../../Utils/EmailValidation/email-validation.tsx';
import { validateAddress } from '../../Utils/AddressValidation/address-validation.tsx';
import { validateDriverName } from '../../Utils/NameValidation/name-validation.tsx';
import { validateRemarks } from '../../Utils/RemarksValidation/remarks-validation.tsx';
import { validatePhoneNumber } from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AddUsersModalProps {
    open: boolean;
    onClose: () => void;
    user: any;
}

const EditUsersModal: FC<AddUsersModalProps> = ({ open, onClose, user }) => {
    const [userName, setUserName] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState<string>('');
    const [userNameError, setUserNameError] = useState<string>('');
    const [whatsappError, setWhatsappError] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [remarks, setRemarks] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [remarksError, setRemarksError] = useState<string>('');
    const [mobileNumber, setMobileNumber] = useState<string>('');
    const [mobileNumberError, setMobileNumberError] = useState<string>('');
    const [location, setLocation] = useState<string>('');
    const [locationError, setLocationError] = useState<string>('');
    const initialValues = useRef({
        userName: '',
        whatsappNumber: '',
        mobileNumber: '',
        email: '',
    });

    useEffect(() => {
        if (open) {
            initialValues.current = {
                userName: user.userName,
                whatsappNumber: user.whatsappNumber,
                mobileNumber: user.mobileNumber,
                email: user.email,
            };
            setUserName(user.userName);
            setWhatsappNumber(user.whatsappNumber);
            setMobileNumber(user.mobileNumber);
            setEmail(user.email);
        }
    }, [open, user]);

    const handleEmailBlur = () => {
        if (email === '') {
            setEmailError('');
        } else if (validateEmail(email)) {
            setEmailError('');
        } else {
            setEmailError('Please enter a valid email address.');
        }
    };

    const handleSave = () => {
        const hasChanges =
            userName !== initialValues.current.userName ||
            whatsappNumber !== initialValues.current.whatsappNumber ||
            mobileNumber !== initialValues.current.mobileNumber ||
            email !== initialValues.current.email;

        if (!hasChanges) {
            onClose();
            return;
        }
        if (!userName) {
            setUserNameError('User Name is required');
            return;
        }
        if (!whatsappNumber) {
            setWhatsappError('Whatsapp Number is required');
            return;
        }
        if (!mobileNumber) {
            setMobileNumberError('Mobile number is required');
            return;
        }
        if (!email) {
            setLocationError('Email is required');
            return;
        }
        if (whatsappError || emailError || mobileNumberError || userNameError) {
            toast.error("Please fix the errors");
            return;
        }
        editUsers(
            userName,
            mobileNumber,
            whatsappNumber,
            email,
            '91',
            'EN',
            user.id,
            (response) => {
                toast.success(response.message);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            },
            (errorMessage) => {
                toast.error(errorMessage);
            }
        );

        setUserName('');
        onClose();
    };

    const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setLocation(value);
        if (value === '') {
            setLocationError('');
        } else if (validateAddress(value)) {
            setLocationError('');
        } else {
            setLocationError('Invalid Address');
        }
    };

    const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setUserName(value);
        if (value === '') {
            setUserNameError('');
        } else if (validateDriverName(value)) {
            setUserNameError('');
        } else {
            setUserNameError('Characters are allowed');
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setEmail(value);
        setEmailError('');
    };

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setMobileNumber(value);

        if (value === '') {
            setMobileNumberError('');
            return;
        }
        const { errorMessage } = validatePhoneNumber(value);
        setMobileNumberError(errorMessage || '');
    };

    const handleWhatsappNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setWhatsappNumber(value);

        if (value === '') {
            setWhatsappError('');
            return;
        }
        const { errorMessage } = validatePhoneNumber(value);
        setWhatsappError(errorMessage || '');
    };

    const handlePhoneNumberBlur = () => {
        if (mobileNumber === '') {
            setMobileNumberError('');
        } else if (mobileNumber.length >= 1 && mobileNumber.length < 10) {
            setMobileNumberError('Please enter a 10 digit number');
        }
    };

    const handleWhatsappNumberBlur = () => {
        if (whatsappNumber === '') {
            setWhatsappError('');
        } else if (whatsappNumber.length >= 1 && whatsappNumber.length < 10) {
            setWhatsappError('Please enter a 10 digit number');
        }
    };

    const handleUserNameBlur = () => {
        if (userNameError === '') {
            setUserNameError('');
        } else {
            setUserNameError(userNameError);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                <Box
                    sx={{
                        marginLeft: {
                            xs: '0px',
                            sm: '-180px',
                        },
                    }}
                >
                    <Heading title="Edit Users" closeIcon="true" onClose={onClose} />
                </Box>
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <div style={{ marginTop: '10px' }}>
                    <TextField
                        label="User Name"
                        size='small'
                        value={userName}
                        onChange={handleUserNameChange}
                        sx={{ marginRight: '10px' }}
                        variant="outlined"
                        error={!!userNameError}
                        helperText={userNameError}
                    />
                    <TextField
                        label="Mobile Number"
                        value={mobileNumber}
                        size='small'
                        onChange={handlePhoneNumberChange}
                        onBlur={handlePhoneNumberBlur}
                        error={!!mobileNumberError}
                        helperText={mobileNumberError}
                        sx={{ marginRight: '10px' }}
                        variant="outlined"
                    />
                </div>
                <div style={{ marginTop: '10px' }}>
                    <TextField
                        label="Email"
                        value={email}
                        size='small'
                        onChange={handleEmailChange}
                        onBlur={handleEmailBlur}
                        error={!!emailError}
                        helperText={emailError}
                        variant="outlined"
                        sx={{ marginRight: '10px' }}
                    />
                    <TextField
                        label="Whatsapp Number"
                        size='small'
                        value={whatsappNumber}
                        onChange={handleWhatsappNumberChange}
                        onBlur={handleWhatsappNumberBlur}
                        error={!!whatsappError}
                        helperText={whatsappError}
                        variant="outlined"
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    onClick={onClose}
                    className="custom-cancel-button"
                    style={{ marginRight: '10px' }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    className="custom-button"
                    style={{ marginLeft: '10px' }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUsersModal;