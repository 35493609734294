import React,{useState,useEffect} from 'react';
import NavBar from '../../Components/Navbar/sidenav.tsx';
import Header from '../../Components/Header/header.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import Newpagination from '../../Components/Pagination/pagination.tsx';
import '../../Components/Button/button-style.scss';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description'; 
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  Button ,TextField,Box,Typography} from '@mui/material';
import {getCompanies} from './action.ts';
import bankIcon from '../../assets/bank_icon.png';
import { Tooltip } from '@mui/material'; 
import BankDetailsModal from './bankdetails-modal.tsx';
import EditCompanyModal from './edit-company-modal.tsx';
import DocumentsViewModal from './document-view-modal.tsx';
import AddCompanyModal from './add-company-modal.tsx';
import {validateDriverName} from '../../Utils/NameValidation/name-validation.tsx';
import {capitalizeFirstLetter} from '../../Utils/ToUpperCase/to-upper-case.tsx';

interface CompanyProps {   
}
const Company: React.FC<CompanyProps> = (props) => {
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [onSearch, setOnSearch] = React.useState('');
    const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);
    const [documentViewModalOpen, setDocumentViewModalOpen] = useState(false);
    const [bankDetailsModalOpen, setBankDetailsModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [companies, setCompanies] = useState<any>();
    const [selectedId , setSelectedId] = useState<number>(0);
    useEffect(() => {
      const fetchCompanies = () => {
        getCompanies(
          pageSize,
          page,
          null,
          (response) => {
            setCompanies(response.message); 
            const totalCount = response.total_count; 
            setTotalRecords(totalCount);
          },
          (errorMessage) => {
            toast.error(errorMessage); 
          }
        );
      };
    
      fetchCompanies();
    }, [page,pageSize]);
    
    const rows = Array.isArray(companies)? companies.map((companyData: any, index: number) => {
      const company = companyData.company;
      const vendors = companyData.vendors;
      const vendorNames = vendors.map((vendor: any) => capitalizeFirstLetter(vendor.name)).join(', ');
      const cleanValue = (value: any) => (value === null || value === 'null' || value === undefined ? '' : value);
      return {
        id: company.id,
        sl_no: (page * pageSize) + index + 1,
        company_name: cleanValue(company.name),
        type: cleanValue(company.type),
        state: cleanValue(company.state),
        cin: cleanValue(company.cin),
        pan_number: cleanValue(company.pan),
        tan_number: cleanValue(company.tan),
        gst_number: cleanValue(company.gst),
        email: cleanValue(company.email),
        password: cleanValue(company.password),
        mobile_number: cleanValue(company.phone),
        address: cleanValue(company.address),
        bank_details: cleanValue(company.bank_name),
        account_number: cleanValue(company.account_number),
        account_name: cleanValue(company.account_name),
        branch_name: cleanValue(company.branch_name),
        ifsc: cleanValue(company.ifsc),
        pincode: cleanValue(company.pincode),
        logo: cleanValue(company.logo),
        pan_filepath: cleanValue(company.pan_filepath),
        gst_filepath: cleanValue(company.gst_filepath),
        tan_filepath: cleanValue(company.tan_filepath),
        cancelled_cheque: cleanValue(company.cancelled_cheque),
        contact1_name: cleanValue(company.contact1_name),
        contact1_email: cleanValue(company.contact1_email),
        contact1_phone: cleanValue(company.contact1_phone),
        contact1_designation: cleanValue(company.contact1_designation),
        contact2_name: cleanValue(company.contact2_name),
        contact2_email: cleanValue(company.contact2_email),
        contact2_phone: cleanValue(company.contact2_phone),
        contact2_designation: cleanValue(company.contact2_designation),
        contact3_name: cleanValue(company.contact3_name),
        contact3_email: cleanValue(company.contact3_email),
        contact3_phone: cleanValue(company.contact3_phone),
        contact3_designation: cleanValue(company.contact3_designation),
        vendor_name: vendorNames,
      };
     })
  : [];
  const updateField = (event: SelectChangeEvent<string>) => {
      const value = event.target.value;
      if (!isNaN(Number(value))) {
        setPageSize(Number(value));
        setPage(0);
      }
  };
  const [selectedBankDetails, setSelectedBankDetails] = useState({
    bank_name: '',
    branch_name: '',
    account_name: '',
    account_number: '',
    ifsc: '',
  });
  const [selectedData, setSelectedData] = useState({
    company_name: '',
    type : '',
    state : '',
    cin : '',
    pan_number : '',
    tan_number : '',
    gst_number : '',
    email: '',
    password : '',
    mobile_number: '',
    address: '',
    pincode: '',
    bank_details:'',
    branch_name: '',
    account_name: '',
    account_number:'',
    ifsc :'',
    logo : '',
    pan : '',
    gst : '',
    tan : '',
    cancelled_cheque : '',
    contact1_name :'',
    contact1_email : '',
    contact1_phone : '',
    contact1_designation : '',
    contact2_name :'',
    contact2_email : '',
    contact2_phone : '',
    contact2_designation : '',
    contact3_name :'',
    contact3_email : '',
    contact3_phone : '',
    contact3_designation : '',
    vendor_name : '',
 });
 const [selectedDocuments, setSelectedDocuments] = useState({
   pan : '',
   gst : '',
   tan : '',
   logo : '',
   cancelled_cheque : ''
 });
 const showEntries = [
    {id:'10',  label:'10'},
    { id: '20', label: '20' },
    { id: '50', label: '50' },
    { id: '100', label: '100' },
 ];
 const handlePageClick = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage - 1);
  };
  const handleBankIconClick = (bank_name,branch_name,account_name,account_number,ifsc) => {
      setSelectedBankDetails({
            bank_name,
            branch_name,
            account_name,
            account_number,
            ifsc,
      });
      setBankDetailsModalOpen(true);
  };
  const handleCloseBankDetails = () => {
        setBankDetailsModalOpen(false);
        setSelectedBankDetails({
            bank_name: '',
            branch_name: '',
            account_name: '',
            account_number: '',
            ifsc: '',
          });
  };
  const handleInputChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        if(value&&!validateDriverName(value)){
          toast.error("only characters are allowed");
        }
  };
  const handleSearchClick = () => {
       const matchingCompany = companies.find(companyData => companyData.company.name.toLowerCase() === searchTerm.toLowerCase());
       
  };
  const handleClickOpen = () => {
      setAddCompanyModalOpen(true);
  };
  const handleEditClick = (row , id) => {
    setSelectedData({
      company_name: row.company_name || '',
      type: row.type || '',
      state: row.state || '',
      cin: row.cin || '',
      pan_number: row.pan_number || '',
      tan_number: row.tan_number || '',
      gst_number: row.gst_number || '',
      email: row.email || '',
      password: row.password || '',
      mobile_number: row.mobile_number || '',
      address: row.address || '',
      pincode: row.pincode || '',
      bank_details: row.bank_details || '',
      branch_name: row.branch_name || '',
      account_name: row.account_name || '',
      account_number: row.account_number || '',
      ifsc: row.ifsc || '',
      logo: row.logo || '',
      pan: row.pan_filepath || '',  
      gst: row.gst_filepath || '',  
      tan: row.tan_filepath || '',  
      cancelled_cheque: row.cancelled_cheque || '',
      contact1_name: row.contact1_name || '',
      contact1_email: row.contact1_email || '',
      contact1_phone: row.contact1_phone || '',
      contact1_designation: row.contact1_designation || '',
      contact2_name: row.contact2_name || '',
      contact2_email: row.contact2_email || '',
      contact2_phone: row.contact2_phone || '',
      contact2_designation: row.contact2_designation || '',
      contact3_name: row.contact3_name || '',
      contact3_email: row.contact3_email || '',
      contact3_phone: row.contact3_phone || '',
      contact3_designation: row.contact3_designation || '',
      vendor_name : row.vendor_name||'',
    });
      setSelectedId(id);
      setEditModalOpen(true);
  };
  const handleCloseEdit = () => {
      setEditModalOpen(false);
  };
  const handleDocumentsClick = (row) => {
      setSelectedDocuments({
          pan: row.pan_filepath || '',
          gst: row.gst_filepath || '',
          tan: row.tan_filepath || '',
          logo: row.logo || '',
          cancelled_cheque: row.cancelled_cheque || ''
      });
      setDocumentViewModalOpen(true);
 };
 const handleClose = () => {
       setDocumentViewModalOpen(false);
        
 };
 const handleCloseAdd = () => {
        setAddCompanyModalOpen(false);
  };
  const columns: GridColDef[] = [
        { field: 'sl_no', headerName: 'Sl No',width:70, headerAlign:'center' },
        {field: 'company_name',headerName: 'Company Name',width:150, headerAlign:'center'},
        {field: 'type',headerName: 'Type',width:150, headerAlign:'center'},
        { field: 'vendor_name', headerName: 'Brands', width:150, headerAlign:'center'},
        { field: 'email', headerName: 'Admin Email', width:220, headerAlign:'center' },
        {field: 'mobile_number', headerName: 'Admin Mobile Number', width:120, headerAlign:'center'},
        { field: 'address', headerName: 'Address ',width:220,flex:1, headerAlign:'center'},
        {
            field: 'bank_details',
            headerName: 'Bank Details',
            width:100,
            headerAlign: 'center',
            renderCell: (params) => (
                <div style={{ display: 'center', alignItems: 'center', gap: '15px' }}>
                  <Tooltip title={`Bank Name: ${params.value.split(', ')[0]}`}>
                    <img 
                      src={bankIcon} 
                      alt="Bank Icon" 
                      style={{ width: '20px', height: '20px', cursor: 'pointer' }} 
                      onClick={() => handleBankIconClick(params.row.bank_details,       
                        params.row.branch_name,  
                        params.row.account_name,       
                        params.row.account_number,     
                        params.row.ifsc )} 
                    />
                  </Tooltip>
                  <span style={{ marginLeft: '5px' }}>{params.value.split(', ')[0]}</span>
                </div>
              ),
            },
        {
          field: 'actions',
          headerName: 'Actions',
          width: 100, headerAlign:'center',
          renderCell: (params: GridRenderCellParams) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  gap: '8px',
                }}
              >
              <IconButton color="primary" onClick={() => handleDocumentsClick(params.row)}sx={{ padding: '0px' }} >
              <DescriptionIcon /> 
            </IconButton>
            <IconButton color="primary" onClick={() => handleEditClick(params.row ,params.row.id)} sx={{ padding: '0px' }}>
              <EditIcon />
            </IconButton>
          </div>
            );
          },
        }
      ];
      
   return (
    <>   
      <Header/>
      <div style={{ marginTop: "70px" }}>
          <Heading title="Companies" page='companies' closeIcon="false"
           leftContent={
            <>
           <Box display="flex" alignItems="center">
           <Typography style={{ marginRight: '5px' }}>Show Entries:</Typography>
             <Dropdown
               options={showEntries}
               selectedValue={pageSize.toString()}
               handleChange={(event) => updateField(event)}
               width='90px'
               height='50px'
             />
             </Box>
             </>
            } 
            rightContent={
              <>
              <Box display="flex" alignItems="center" gap="10px">

              <TextField
                className="styled-textfield"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="Search by Company"
              />
              <Button className="custom-button" onClick={handleSearchClick}>
                Search
              </Button>
              </Box>
              <Box  sx={{ marginTop: {
      xs: '10px', 
      sm: '0px',  md:'10px', lg:'0px'} }}>
              <Button
                className="custom-button"
                onClick={handleClickOpen}
              >
                   Add Company
              </Button>
        </Box>
           </>
          }  />
        </div>
           <AddCompanyModal open={addCompanyModalOpen} onClose={handleCloseAdd} /> 
          <BankDetailsModal bankDetailsModalOpen={bankDetailsModalOpen} onClose={handleCloseBankDetails} selectedBankDetails={selectedBankDetails} />   
          <EditCompanyModal open={editModalOpen} onClose={handleCloseEdit} selectedData={selectedData} selectedId={selectedId} /> 
        <Box className="table-container custom-data-grid" sx={{ marginLeft: {
      xs: '10px', 
      sm: '180px', 
    },marginTop: '10px', marginRight: '40px', position: 'relative', height: '500px', overflowY: 'auto' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    rowCount={totalRecords}
                    autoHeight={false}
                    disableRowSelectionOnClick
                    disableColumnResize
                    hideFooterPagination
                    classes={{
                        columnHeader: 'red-header',
                    }}
                />
            </Box>
        <div style={{marginLeft : '50%' , marginTop : '8px'}}> 
         <Newpagination
           rows={totalRecords}
           pageSize={pageSize}
           page={page}
           handlePageChange={handlePageClick}
         />
        </div>
        <DocumentsViewModal open={documentViewModalOpen} onClose={handleClose}  documents={selectedDocuments} /> 
      <NavBar selected='Company' />
    </>
    );
};
export default Company;