import React, { useState } from 'react';
import { Box, Tabs, Tab, IconButton, Drawer } from '@mui/material';
import NavBar from '../../Components/Navbar/sidenav.tsx';
import Header from '../../Components/Header/header.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import CommonTabs from './common-tab.tsx';
import './driver_style.scss';

interface DriversProps {}

const Drivers: React.FC<DriversProps> = () => {
  const [value, setValue] = useState(0);
  const handleClose = () => {
  };
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const renderTabContent = (index: number) => {
    switch (index) {
      case 0:
        return <CommonTabs tabName="driver"/>;
      case 1:
        return <CommonTabs tabName="left"/>;
      case 2:
        return <CommonTabs tabName="blacklist"/>;
      case 3:
        return <CommonTabs tabName="lead"/>;
      case 4:
          return <CommonTabs tabName="owner"/>;
      default:
        return <CommonTabs tabName="driver"/>;
    }
  };
  return (
    <>
      <Header/>
      <Box sx={{ flexGrow: 1,marginTop:'70px'}}> 
        <Heading title="Drivers" closeIcon="false" onClose={handleClose} />
      </Box>
      <Box className="element" sx={{  marginLeft: {
      xs: '10px', 
      sm: '180px', 
    }, }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="drivers tabs"
          sx={{
            backgroundColor: '#68E0CF',
            '& .MuiTabs-indicator': {
              backgroundColor: '#68E0CF',
            },
          }}
        >
        <Tab
            label="Drivers"
            sx={{
              minWidth: '60px',
              color: value === 0 ? '#0e0d0dda' : '#0e0d0dda',
              backgroundColor: value === 0 ? '#fff' : '#68E0CF',
              textTransform: 'capitalize',
              '&.Mui-selected': {
                color: '#0e0d0dda',
              },
              '&:hover': { backgroundColor: '#fff', color: '#0e0d0dda' },
            }}
          />
          <Tab
            label="Left"
            sx={{
              minWidth: '60px',
              color: value === 1 ? '#0e0d0dda' : '#0e0d0dda',
              backgroundColor: value === 1 ? '#fff' : '#68E0CF',
              textTransform: 'capitalize',
              '&.Mui-selected': {
                color: '#0e0d0dda',
              },
              '&:hover': { backgroundColor: '#fff', color: '#0e0d0dda' },
            }}
          />
          <Tab
            label="BlackList"
            sx={{
              minWidth: '100px',
              color: value === 2 ? '#0e0d0dda' : '#0e0d0dda',
              backgroundColor: value === 2 ? '#fff' : '#68E0CF',
              textTransform: 'capitalize',
              '&.Mui-selected': {
                color: '#0e0d0dda',
              },
              '&:hover': { backgroundColor: '#fff', color: '#0e0d0dda' },
            }}
          />
          <Tab
            label="Leads"
            sx={{
              minWidth: '100px',
              color: value === 3 ? '#0e0d0dda' : '#0e0d0dda',
              backgroundColor: value === 3 ? '#fff' : '#68E0CF',
              textTransform: 'capitalize',
              '&.Mui-selected': {
                color: '#0e0d0dda',
              },
              '&:hover': { backgroundColor: '#fff', color: '#0e0d0dda' },
            }}
          />
           <Tab
            label="Owners"
            sx={{
              minWidth: '60px',
              color: value === 4 ? '#0e0d0dda' : '#0e0d0dda',
              backgroundColor: value === 4 ? '#fff' : '#68E0CF',
              textTransform: 'capitalize',
              '&.Mui-selected': {
                color: '#0e0d0dda',
              },
              '&:hover': { backgroundColor: '#fff', color: '#0e0d0dda' },
            }}
          />
        </Tabs>
        <Box sx={{  marginLeft: {
      xs: '10px', 
      sm: '0px', 
    }}}>{renderTabContent(value)}</Box>
      </Box>
      <NavBar selected="Users" />
    </>
  );
};

export default Drivers;
