import React,{useState, useEffect} from 'react';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import Newpagination from '../../Components/Pagination/pagination.tsx';
import '../../Components/Button/button-style.scss';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {SelectChangeEvent} from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description'; 
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button, TextField, Box} from '@mui/material';
import {getDrivers, editDriverLead,searchDriverLeads, getDriverName,getOwners,deleteOwner} from './action.ts';
import {addOwner,addOwnerDetails} from '../Vehicles/action.ts'
import AddLeadModal from './add-lead-modal.tsx';
import {Select, MenuItem} from '@mui/material';
import EditDriverLeadModal from './edit-lead-modal.tsx';
import EditDriverModal from './edit-driver-modal.tsx';
import AddOwnerModal from  '../Vehicles/add-owner-modal.tsx';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import DocumentsModal from './driver-documents-modal.tsx';
import DeleteModal from './delete-modal.tsx';
import EditOwnerModal from './edit-owner-modal.tsx';
import './driver_style.scss';

interface CommonTabsProps {
  tabName: string;
}

const CommonTabs: React.FC<CommonTabsProps> = ({ tabName}) => {
    const [totalCount, setTotalCount] = useState<number>(0);
    const [confirmOpen, setConfirmOpen] = useState(false);
const [ownerToDelete, setOwnerToDelete] = useState<number | null>(null);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [driversList, setDriversList] = useState<any>();
    const [searchTerm, setSearchTerm] = useState('');
    const [remarksList, setRemarksList] = useState<{ [key: number]: string }>({});
    const [driverId , setDriverId] = useState<number>(0);
    const [ownerId , setOwnerId] = useState<number>(0);
    const [isEditOwnerOpen, setIsEditOwnerOpen] = useState(false);
    const [ownerToEdit, setOwnerToEdit] = useState<any>(null);
    const [isAddLeadOpen, setIsAddLeadOpen] = useState(false);
    const [isAddOwnerOpen, setIsAddOwnerOpen] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [leadId , setLeadId] = useState<number>(0);
    const [option,setOption] = useState<string>('');
    const [searchError, setSearchError] =  useState(false);
    const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [driverIdToDelete , setDriverIdToDelete] = useState<number>(0);
 

    const [remarksToPass, setRemarksToPass] = useState<string>('');
    const [statuses, setStatuses] = useState({});
  const userDetails = JSON.parse(localStorage.getItem("userdetails") || "[]");
    const phoneNumber =userDetails.user.phone;
    const handleClosePopUp = () => {
      setOpenDialog(false);
    };
    const statusOptions = [
      { id: 'blacklist', label: 'Blacklist'},
      { id: 'others', label: 'Others' },
      { id: 'recruited', label: 'Recruited' },
      { id: 'uninterested', label: 'Uninterested' },
      { id: 'lead', label: 'Lead' },
    ];
    const showEntries = [
          {id:'10',  label:'10'},
          { id: '20', label: '20' },
          { id: '50', label: '50' },
          { id: '100', label: '100' },
    ];
    const [selectedData, setSelectedData] = useState({
          name: '',
          pincode: '',
          phone: '',
          whatsapp_number: '',
          email: '',
          remarks:'',
          status : '',
          location: '',
    });
    const updateField = (event: SelectChangeEvent<string>) => {
          const value = event.target.value;
          if (!isNaN(Number(value))) {
              setPageSize(Number(value));
              setPage(0);
          }
    };
    useEffect(() => {
      setPage(0);
      setPageSize(10);
      setTotalRecords(0);
      setShouldFetch(false); 
      setSearchTerm('');
    }, [tabName]);
    const handleEditOwnerClick = (rowData: any, id: number) => {
      setOwnerToEdit(rowData);
      setOwnerId(id);
      setIsEditOwnerOpen(true);
    };
  
    const handleCloseEditOwner = () => {
      setIsEditOwnerOpen(false);
    };
    const handleConfirm = () => {
      if (ownerToDelete !== null) {
        deleteOwner(ownerToDelete, (response) => {
          toast.success('Owner deleted successfully');
          setConfirmOpen(false);
              setDriversList((prevDriversList) =>
            prevDriversList.filter((driver) => driver.id !== ownerToDelete)
          );
              setTotalRecords((prevTotalRecords) => prevTotalRecords - 1);
              setTotalCount(Math.ceil((totalRecords - 1) / pageSize));
        }, (errorMessage) => {
          toast.error(errorMessage);
        });
      }
    };
    useEffect(() => {
      if (!shouldFetch) {
        setShouldFetch(true);
        return;
      }
    
      if (tabName === 'driver' || tabName === 'left' || tabName === 'blacklist' || tabName === 'lead') {
      let leadValue: string | null = null;
      let blacklistValue: string | null = null;
      let leftValue: string | null = null;
        if (tabName === 'driver') {
          leadValue = null;
          blacklistValue = null;
          leftValue = null;
        } else if (tabName === 'left') {
          leftValue = '1';
          leadValue = null;
          blacklistValue = null;
        } else if (tabName === 'blacklist') {
          blacklistValue = '1';
          leftValue = null;
          leadValue = null;
        } else if (tabName === 'lead') {
          leadValue = '1';
          blacklistValue = null;
          leftValue = null;
        }
        fetchUsers(page, pageSize, leadValue, blacklistValue, leftValue, tabName);}
        else if (tabName === 'owner') {
          fetchOwners('',page, pageSize);}
        }, [page,pageSize,shouldFetch]);
    const fetchOwners = (phone,page,pageSize) => {
      setDriversList([]);
      setRemarksList({});
      getOwners('', pageSize, page, (response: any) => {
        let driverDetails = [];
        let totalRecords = 0;
        driverDetails = Array.isArray(response.message.vehicles_owners_list)
          ? response.message.vehicles_owners_list.map((driver: any) => {
              return {
                id: driver.id,
                name: driver?.name,
                phone: driver?.phone,
                email: driver?.details[0]?.email_id, 
                remarks: driver?.details[0]?.remarks,
                account_number: driver?.details[0]?.acc_number,
                ifsc: driver?.details[0]?.ifsc,
                address: driver?.details[0]?.address ,
                details_id:driver?.details[0]?.id,

              };
            })
          : [];
        setDriversList(driverDetails);
        totalRecords = response.message.total_vehicle_owners || 0;
        setTotalCount(Math.ceil(totalRecords / pageSize));
        setTotalRecords(totalRecords);
      }, (errMessage: any) => {
        toast.error(errMessage);
      });
    };
  
    const fetchUsers = (page, pageSize, lead, blacklist, left, tabName) => {
      setDriversList([]);
      setRemarksList({});
      getDrivers(
          lead,
          blacklist,
          pageSize,
          page,
          left,
          (response: any) => {
            let driverDetails = [];
            let totalRecords = 0;
            if (tabName === 'left') {
              driverDetails = Array.isArray(response.left_drivers)
                ? response.left_drivers.map((driver: any) => {
                    const driverInfo = driver.driver_user || driver.driver_lead;
                    return {
                      id: driver.id,
                      name: driverInfo?.name,
                      phone: driverInfo?.phone,
                      whatsapp_number: driverInfo?.whatsapp_number,
                      email: driverInfo?.email,
                      remarks: driver.remarks,
                    };
                  })
                : [];
              totalRecords = response.total_count|| 0;
            } 
            else if (tabName === 'blacklist') {
              driverDetails = Array.isArray(response.blacklisted_drivers)
                ? response.blacklisted_drivers.map((driver: any) => {
                    const { driver_user, driver_lead } = driver;
                    return {
                      id: driver.id,
                      name: driver_user?.name || driver_lead?.name,
                      phone: driver_user?.phone || driver_lead?.phone,
                      email: driver_user?.email || driver_lead?.email,
                      remarks: driver.remarks,
                    };
                  })
                : [];
              totalRecords = response.total_count|| 0;
            } 
            else if (tabName === 'driver') {
              toast.success(response);
              driverDetails = response.drivers || [];
              totalRecords = response.total_count|| 0;
            } 
            else if (tabName === 'lead') {
              driverDetails = response.driver_leads || [];
              totalRecords = response.total_count|| 0;
            }
            setDriversList(driverDetails);
            const initialRemarksList = driverDetails.reduce((acc, driver) => {
              acc[driver.id] = driver.remarks || '';
              return acc;
            }, {});
            setRemarksList(initialRemarksList);
            setTotalCount(Math.ceil(totalRecords / pageSize));
            setTotalRecords(totalRecords);
            if (driverDetails.length === 0) {
              totalRecords = 0;
              setTotalRecords(0); 
            }
          },
          (errMessage: any) => {
            toast.error(errMessage);   
          }
         );
    };
    const handleInputChange = (event) => {
      const { value } = event.target;
       setSearchTerm(value);
        if(value){
            const { errorMessage } = validatePhoneNumber(value);
            if(errorMessage){
                toast.error(errorMessage);
                setSearchError(true);
            }
           else{
                setSearchError(false);
            }
        }
       else {
           if(tabName==='driver')
              {
                  fetchUsers(page, pageSize, null, null, null, 'driver');
              }
           if(tabName==='left')
              {
                  fetchUsers(page, pageSize, null, null, '1', 'left');
              }
           if(tabName==='blacklist')
             {
                  fetchUsers(page, pageSize, null, '1', null, 'blacklist');
             }
           if(tabName==='lead')
            {
                  fetchUsers(page, pageSize, '1', null, null, 'lead');
            }
           
       }
    };
    const handleSearchClick = () => {
        if (searchTerm.length>=1&&searchTerm.length<10) {
           toast.error('Please enter a 10 digit number');
           setSearchError(true);
           return;
        } 
        if(!searchError){
          if(tabName==='lead'){
           searchDriverLeads(
              searchTerm,
              (response: any) => {
                 const driverDetails = response.message || [];
                 setDriversList(driverDetails);
              },
             (error) => {
               toast.error(error.message);
               setDriversList([]);
              }
           );
          }
          else if (tabName==='driver'){

              getDriverName(
                      searchTerm,
                          (response) => {
                              const user = response.message.user[0];
                              const driverData = {
                                  id: user.user_unique_id,
                                  name: user.user_name,
                                  email: user.user_email,
                                  phone: user.user_phone,
                                  whatsapp_number: user.whatsapp_number,
                               };
                              setDriversList([driverData]); 
                          },
                          (error) => {
                              toast.error("No driver found for the number");
                              setDriversList([]);
                          }
             );
          }
          else if(tabName==='owner')
            {
              getOwners(searchTerm,'1','0', (response: any) => {
                let driverDetails = [];
                let totalRecords = 0
                  driverDetails = Array.isArray(response.message.vehicles_owners_list)
                    ? response.message.vehicles_owners_list.map((driver: any) => {
                        return {
                          id: driver.id,
                          name: driver?.name,
                          phone: driver?.phone,
                          email: driver?.details[0]?.email_id,
                          details_id:driver?.details[0]?.id,
                          remarks: driver?.details[0]?.remarks,
                          account_number: driver?.details[0]?.acc_number,
                          ifsc: driver?.details[0]?.ifsc, 
                          address: driver?.details[0]?.address, 
                        };
                      })
                    : [];
                    setDriversList(driverDetails);
                    totalRecords = response.message.total_vehicle_owners|| 0;
                    setTotalCount(Math.ceil(totalRecords / pageSize));
                    setTotalRecords(totalRecords);
               }, (errMessage: any) => {
                 toast.error(errMessage);   
               })
            }
        }
    };
    const handleEditClick = (rowData: any,id ,option:string) => {
      if(tabName==='lead'){
          setSelectedData(rowData);
          setOpenModal(true);
          setLeadId(id);
          setOption(option);}
      else if(tabName==='driver'){
          setSelectedData(rowData);
          setOpenModal(true);
          setDriverId(id);
      }
      else if (tabName === 'owner') {
        handleEditOwnerClick(rowData, id);
    }; 
  }
    const handleClickOpen = () => {
      setIsAddLeadOpen(true);
    };
    const handleOpenAddOwner = () => {
      setIsAddOwnerOpen(true);
    };
    const handleCloseAddLead = () => {
      setIsAddLeadOpen(false);
    };
    const handleCloseAddOwner= () => {
      setIsAddOwnerOpen(false);
    };
    const handleCloseModal = () => {
          setOpenModal(false);
    };
    const handleAddOwner = (name: string, phoneNumber: string,email:any,address:any,ifsc:any,accountNumber:any,remarks:any) => {
       addOwner(name,phoneNumber,(response) => {
        toast.success('Owner added successfully ');
        setIsAddOwnerOpen(false)
        const ownerId = response.vehicle_owner.id;
     if(ownerId){
      addOwnerDetails(ownerId,accountNumber,ifsc,remarks,email,address,(response) => { toast.success('Owner added successfully ');},
      (errorMessage) => {
        toast.error(errorMessage);
      } );
     }
      },
      (errorMessage) => {
        toast.error(errorMessage);
      })
      };
    const handleDeleteClick = (rowData: any,id ,remarks) => {
      if (tabName === 'owner') {
        setOwnerToDelete(id);
        setConfirmOpen(true);
      } else if(tabName==='lead'){
            handleEditClick(rowData,id ,"delete");}
        else if(tabName==='driver'||tabName==='blacklist'||tabName==='left')
            {
               setDriverIdToDelete(id);
               setRemarksToPass(remarks);
               setOpenDialog(true);
            }
            else if (tabName === 'owner'){
              setOwnerToDelete(id);

            }
    };
   const handleClose = () => {
        setIsDocumentModalOpen(false);
    };
    const handleRemarksInput = (e, driverId) => {
          setRemarksList((prevRemarksList) => ({
             ...prevRemarksList,
             [driverId]: e.target.value, 
          }));
    };
    const handleRemarksChange = (value: string, rowId: number, field: string) => {
          console.log(`Updating remarks for row ${rowId}:`, value);
    };
    const handleDocumentsClick = (id) => {
           setIsDocumentModalOpen(true);
           setDriverId(id);
    };
    const columns: GridColDef[] = [
      { field: 'sl_no', headerName: 'Sl No', width: 60, headerAlign: 'center' },
      { field: 'name', headerName: 'Name', width: 160, headerAlign: 'center' },
      { field: 'location', headerName: 'Location', width: 160, headerAlign: 'center' },
      { field: 'phone', headerName: 'Mobile Number', width: 160, headerAlign: 'center' },
      ...(tabName !== 'owner' ? [{ field: 'whatsapp_number', headerName: 'WhatsApp Number', width: 200, headerAlign: 'center' as GridAlignment }] : []),
      { field: 'email', headerName: 'Email', flex:1, headerAlign: 'center' },
      ...(tabName === 'owner' ? [
        { field: 'account_number', headerName: 'Account Number', width: 180, headerAlign: 'center' },
        { field: 'ifsc', headerName: 'IFSC', width: 180, headerAlign: 'center' },
      ] : []),
      {
        field: 'remarks',
        headerName: 'Remarks',
        width: 140,
        headerAlign: 'center',
        editable: true,
        renderCell: (params) => {
          return (
            <div
              style={{
                border: '1px solid grey',
                borderRadius: '5px',
                outline: 'none',
                boxShadow: 'none',
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
              }}
            >
              {params.row.remarks || ''}
            </div>
          );
        },
        renderEditCell: (params: GridRenderCellParams) => (
          <input
            type="text"
            value={remarksList[params.row.id] ?? ''}
            onChange={(e) => handleRemarksInput(e, params.row.id)}
            onBlur={() => handleRemarksChange(remarksList[params.row.id], params.row.id, 'remarks')}
            style={{
              border: '1px solid black',
              outline: 'none',
              boxShadow: 'none',
              width: '100%',
              height: '100%',
            }}
          />
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 120,
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div
              className={
                tabName === 'driver' ? 'driver-icons-wrapper'
                  : tabName === 'lead' ? 'lead-icons-wrapper'
                    : 'general-icons-wrapper'
              }
            >
              {tabName === 'driver' && (
                <>
                  <IconButton color="primary" onClick={() => handleDocumentsClick(params.row.id)} sx={{ padding: '0px' }}>
                    <DescriptionIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => handleEditClick(params.row, params.row.id, "")} sx={{ padding: '0px' }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => handleDeleteClick("", params.row.id, params.row.remarks)} sx={{ padding: '0px' }}>
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
              {tabName === 'lead' && (
                <>
                  <IconButton color="primary" onClick={() => handleEditClick(params.row, params.row.id, "edit")} sx={{ padding: '0px' }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => handleDeleteClick(params.row, params.row.id, "")} sx={{ padding: '0px' }} >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
              {(tabName === 'blacklist' || tabName === 'left') && (
                <IconButton color="primary" onClick={() => handleDeleteClick("", params.row.id, "")}>
                  <DeleteIcon />
                </IconButton>
              )}
               {tabName === 'owner' && (
                    <>
                       <IconButton color="primary" onClick={() => handleEditClick(params.row, params.row.id, "")} sx={{ padding: '0px' }}>
              <EditIcon />
            </IconButton>
                      <IconButton color="primary" onClick={() => handleDeleteClick("",params.row.id, params.row.remarks)} sx={{ padding: '0px' }}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
            </div>
          );
        },
      },
    ];
    
    if (tabName === 'lead') {
      const leadStatusColumn = {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        headerAlign: 'center',
        renderCell: (params) => {
          const { id, status } = params.row;
          const handleStatusChange = (event, id) => {
            const updatedStatus = event.target.value;
            setStatuses((prevStatuses) => ({
              ...prevStatuses,
              [id]: updatedStatus,
            }));
            editDriverLead(
              id,
              '',
              '',
              '',
              '',
              updatedStatus,
              '',
              (response) => {
                toast.success(response.message);
                fetchUsers(page, pageSize, '1', null, null, 'lead');
              },
              (errorMessage) => {
                toast.error(errorMessage);
              }
            );
          };
          const currentStatus = statuses[id] || status || 'lead';
          return (
            <Select
              value={currentStatus}
              onChange={(event) => handleStatusChange(event, id)}
              fullWidth
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          );
        },
      };
      columns.splice(6, 0, leadStatusColumn);
    }
    const handlePageClick = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage - 1);
    };
    const rows = Array.isArray(driversList) ? driversList.map((driver, index) => ({
        id: driver.id,
        sl_no: (page * pageSize) + index + 1,
        name: driver.name,
        phone: driver.phone,
        whatsapp_number: driver.whatsapp_number,
        email : driver.email,
        pincode : driver.pincode,
        remarks : driver.remarks,
        status : driver.status,
        ...(tabName === 'owner' && {
          details_id:driver.details_id,
          account_number: driver.account_number,
          ifsc: driver.ifsc,
          address: driver.address,
        }),
    })) : [];
    return (
        <>
          <div style={{ display: "flex", alignItems: "center", marginTop: "-20px", gap: "5px", whiteSpace: "nowrap" }}>
           <span>Show Entries:</span>
           <Dropdown
                 options={showEntries}
                 selectedValue={pageSize.toString()}
                 handleChange={(event) => updateField(event)}
                 width="80px"
                height="30px"
            />
            <Box  className="styled-textfield"
                  display="flex" 
                  justifyContent="space-between"  
                  alignItems="center" 
                  style={{ margin: '40px 0', width: '100%' }}  
                >
                 <Box 
                   display="flex" 
                   justifyContent="center"  
                   alignItems="center"
                   style={{ flexGrow: 1 }}  
                 >
                    <TextField
                        className="styled-textfield"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder="Search by Mobile Number"
                    />   
                    <Button  className='custom-button' onClick={handleSearchClick} style={{ marginLeft: '13px' }}> 
                         Search
                    </Button>
                  </Box>
            </Box>
            {tabName === 'lead' && (
                  <>
                   <Button 
                      className='custom-button' 
                      onClick={handleClickOpen} 
                      style={{ width: '110px', height: '40px' }}  
                    >
                       Add Leads
                    </Button>
                   <AddLeadModal addLeadModalOpen={isAddLeadOpen} onClose={handleCloseAddLead} fetchUsers={fetchUsers} page={page} pageSize={pageSize} />   
                   </>  
                )}
            {tabName === 'owner' && (
               <>
               <Button 
                  className='custom-button' 
                  onClick={handleOpenAddOwner} 
                  style={{ width: '110px', height: '40px' }}  
                >
                   Add Owner
                </Button>
               <AddOwnerModal isOpen={isAddOwnerOpen} handleClose={handleCloseAddOwner} onAddOwner={handleAddOwner} />   
               </>  
            )}
          </div>
          <div className="table-pagination-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',height: '53vh', }}>
                <Box className="table-container" style={{  marginLeft: {
      xs: '10px', 
      sm: '10px', 
    },position: 'relative', overflowY: 'auto', flexGrow: 1, width: '100%', marginTop: '-25px' }}>
                     <DataGrid
                         rows={rows}
                         columns={columns}
                         rowCount={totalRecords}
                         autoHeight ={false}
                         disableRowSelectionOnClick
                         disableColumnResize
                         hideFooterPagination
                         classes={{
                            columnHeader: 'red-header',
                         }}
                      />
                </Box>
                <div className="pagination-container" style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10px' }}>
                     <Newpagination
                        rows={totalRecords}
                        pageSize={pageSize}
                        page={page}
                        handlePageChange={handlePageClick}
                      />
                </div>
            </div>
               {tabName === 'driver' && (
                  <EditDriverModal
                       open={openModal}
                       onClose={handleCloseModal}
                       driverData={selectedData}
                       driverId={driverId}
                       fetchUsers={fetchUsers} page={page} pageSize={pageSize}
                  />
             )}
            {tabName === 'lead' && (
                <EditDriverLeadModal
                    open={openModal}
                    onClose={handleCloseModal}
                    driverData={selectedData}
                    leadId={leadId}
                    option={option}
                    fetchUsers={fetchUsers} page={page} pageSize={pageSize}
                />
            )} 
           {tabName === 'owner' && (
        <EditOwnerModal
          open={isEditOwnerOpen}
          onClose={handleCloseEditOwner}
          ownerData={ownerToEdit}
          ownerId={ownerId}
          fetchOwners={fetchOwners}
        />
      )}
           {(tabName === 'driver' || tabName === 'left' || tabName === 'blacklist')  && (
               <DeleteModal open={openDialog} handleClose={handleClosePopUp} driverId={driverIdToDelete} remarksToPass={remarksToPass} option={tabName} fetchUsers={fetchUsers} page={page} pageSize={pageSize}/>
           )}
           <DocumentsModal open={isDocumentModalOpen} onClose={handleClose} driverId={driverId}/> 
           <ConfirmationPopup
  open={confirmOpen}
  onClose={() => setConfirmOpen(false)}
  onConfirm={handleConfirm}
  title="Confirm Delete"
  message="Are you sure you want to delete this owner?"
/>
        </>
    );
};
export default CommonTabs;
 
