import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { validatePhoneNumber } from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import { validateAccountNumber} from '../../Utils/AccountNumberValidation/account-number-validation.tsx';
import { validateAddress } from '../../Utils/AddressValidation/address-validation.tsx';
import { validateIFSC } from '../../Utils/IFSCValidation/ifsc-validation.tsx';
import { validateDriverName } from '../../Utils/NameValidation/name-validation.tsx';
import { validateRemarks } from '../../Utils/RemarksValidation/remarks-validation.tsx';
import { validateEmail } from '../../Utils/EmailValidation/email-validation.tsx';
import MandatoryLabel from '../../Components/MandatoryLabel/mandatory-label.tsx';
import {toast} from 'react-toastify';
import Heading from '../../Components/Heading/heading.tsx';

interface AddOwnerModalProps {
  isOpen: boolean;
  handleClose: () => void;
  phoneNumber?: string;
  onAddOwner: (name: string, phoneNumber: string, email: any, address: any, ifsc: any, accountNumber: any, remarks: any) => void;
}

const AddOwnerModal: React.FC<AddOwnerModalProps> = ({ isOpen, handleClose, phoneNumber, onAddOwner }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifsc, setIfsc] = useState('');
  const [remarks, setRemarks] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [ifscError, setIfscError] = useState('');
  const [remarksError, setRemarksError] = useState('');
  const [localPhoneNumber, setLocalPhoneNumber] = useState(phoneNumber || '');

  useEffect(() => {
    if (phoneNumber) {
      setLocalPhoneNumber(phoneNumber);
    }
  }, [phoneNumber]);

  const handleNameChange = (event) => {
    const { value } = event.target;
    setName(value);
    if (validateDriverName(value)) {
      setNameError('');
    } else {
      setNameError('Characters are allowed');
    }
  };

  const handleNameBlur = () => {
    if (name === '') {
      setNameError("Required Field");
    } else if (!validateDriverName(name)) {
      setNameError('Characters are only allowed');
    } else {
      setNameError('');
    }
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setEmailError('');
  };

  const handleEmailBlur = () => {
    if (email === '') {
      setEmailError("Required Field");
    } else if (validateEmail(email)) {
      setEmailError('');
    } else {
      setEmailError('Please enter a valid email address.');
    }
  };
  const handleAddressChange = (event) => {
    const { value } = event.target;
    setAddress(value);
    if (validateAddress(value)) {
      setAddressError('');
    } else {
      setAddressError('Invalid address');
    }
  };
  const handleAddressBlur = () => {
    if (address === '') {
      setAddressError("Required Field");
    } else if (!validateAddress(address)) {
      setAddressError('Invalid address');
    } else {
      setAddressError('');
    }
  };
  const handleRemarksChange = (event) => {
    const { value } = event.target;
    setRemarks(value);
    if (value === '') {
      setRemarksError('');
    } else if (validateRemarks(value)) {
      setRemarksError('');
    } else {
      setRemarksError('Invalid Character');
    }
  };

  const handleAccountNumberChange = (event) => {
    const { value } = event.target;
    setAccountNumber(value);
      const errorMessage = validateAccountNumber(value);
      setAccountNumberError(errorMessage);
    }

  const handleAccountNumberBlur = () => {
    if (accountNumber === '') {
      setAccountNumberError("Required Field");
    } else if (accountNumber.length >= 1 && accountNumber.length < 9) {
      setAccountNumberError('Please enter at least 9 digits');
    }
  };

  const handleIfscChange = (event) => {
    const { value } = event.target;
    const ifsc = value.toUpperCase();
    setIfsc(ifsc);
      const newError = validateIFSC(ifsc);
      setIfscError(newError);
  };

  const handleIfscBlur = () => {
    if (ifsc === '') {
      setIfscError("Required Field");
    } else if (ifsc.length >= 1 && ifsc.length < 11) {
      setIfscError('IFSC must be an 11 digit number');
    }
  };

  const handleSubmit = () => {
    if (nameError || emailError || remarksError || addressError || accountNumberError || ifscError) {
      console.log(emailError);
      toast.error("Please fix the errors");
      return;
    } else {
      onAddOwner(name, localPhoneNumber, email, address, ifsc, accountNumber, remarks);
      resetFields();
      handleClose();
    }
  };

  const resetFields = () => {
    setAccountNumber('');
    setAddress('');
    setName('');
    setEmail('');
    setIfsc('');
    setRemarks('');
    setNameError('');
    setEmailError('');
    setAddressError('');
    setAccountNumberError('');
    setIfscError('');
    setRemarksError('');
    setLocalPhoneNumber(phoneNumber || '');
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          marginLeft: {
            xs: '0px',
            sm: '-180px',
          },
        }}
      >
        <Heading title="Add Owner" closeIcon="true" onClose={handleClose} />
      </Box>
      <DialogContent>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}><MandatoryLabel labelText="Phone Number" /> </p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-40px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Phone Number"
              size="small"
              value={localPhoneNumber}
              onChange={(e) => setLocalPhoneNumber(e.target.value)}
              disabled={!!phoneNumber}
              style={{ width: '225px' }}
              margin="normal"
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}><MandatoryLabel labelText="Name" /> </p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-40px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Name"
              value={name}
              size="small"
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              error={!!nameError}
              helperText={nameError}
              style={{ width: '225px' }}
              fullWidth
              margin="normal"
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}><MandatoryLabel labelText="Email" /></p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-40px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Email"
              value={email}
              size="small"
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              error={!!emailError}
              helperText={emailError}
              style={{ width: '225px' }}
              fullWidth
              margin="normal"
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}><MandatoryLabel labelText ="Address"/></p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-40px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Address"
              value={address}
              size="small"
              onChange={handleAddressChange}
              onBlur={handleAddressBlur}
              error={!!addressError}
              helperText={addressError}
              style={{ width: '225px' }}
              fullWidth
              margin="normal"
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}><MandatoryLabel labelText ="Account Number"/></p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-40px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Account Number"
              value={accountNumber}
              size="small"
              onChange={(e) => handleAccountNumberChange(e)}
              onBlur={handleAccountNumberBlur}
              error={!!accountNumberError}
              helperText={accountNumberError}
              style={{ width: '225px' }}
              fullWidth
              margin="normal"
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}><MandatoryLabel labelText ="IFSC Code"/></p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-40px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              label="IFSC"
              value={ifsc}
              size="small"
              onChange={(e) => handleIfscChange(e)}
              onBlur={handleIfscBlur}
              error={!!ifscError}
              helperText={ifscError}
              style={{ width: '225px' }}
              fullWidth
              margin="normal"
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: { xs: '-10px', sm: '0px' }, marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}>Remarks</p>
        </Box>
        <Box sx={{ marginLeft: { xs: '112px', sm: '200px' }, marginTop: '-40px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Remarks"
              size="small"
              value={remarks}
              onChange={handleRemarksChange}
              error={!!remarksError}
              helperText={remarksError}
              style={{ width: '225px' }}
              fullWidth
              margin="normal"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
        <Button className="custom-button" onClick={handleSubmit}>Add</Button>
        <Button className="custom-cancel-button" onClick={() => { handleClose(); resetFields(); }}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOwnerModal;