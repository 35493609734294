import React, { FC, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button,Box } from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import { editClients } from './action.ts';
import {validateRemarks} from '../../Utils/RemarksValidation/remarks-validation.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AddClientsModalProps {
    open: boolean;
    onClose: () => void;
    client: any;
}

const EditClientsModal: FC<AddClientsModalProps> = ({ open, onClose, client }) => {
    const [clientName, setClientName] = useState('');
    const [clientNameError, setClientNameError] = useState<string>('');
    const storedUserDetails = JSON.parse(localStorage.getItem("userdetails"));

    useEffect(() => {
        if (open) {
            setClientName(client.clientName);
        }
    }, [open, client]);

    const handleSave = () => {
        let hasError = false;

        if (clientName === client.clientName) {
            onClose();
            return;
        }
    
        if (!clientName) {
            setClientNameError('Client Name is required');
            hasError = true;

          }
         
             if (hasError) {
                    toast.error("Please fix the errors");
                    return;
                }
        if(clientNameError){
          toast.error("Please fix the errors");
          return;
        } 
        else {

        editClients(
            clientName,
            client.id,
            storedUserDetails.user.companies_id,
            (response) => {
                toast.success(response.message);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            },
            (errorMessage) => {
                toast.error(errorMessage);
            }
        );

        setClientName('');
        onClose();
    }
    };

    const handleClientNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setClientName(value);
        if (value === '') {
            setClientNameError('');
        } else if (validateRemarks(value)) {
            setClientNameError('');
        } else {
            setClientNameError('Invalid Character');
        }
    };



    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" sx={{
            '& .MuiDialog-paper': {
                width: '400px',
                maxWidth: 'none',
            },
        }}>
            <DialogTitle sx={{padding:'0px'}}>
            <Box
                      sx={{
                        marginLeft: {
                          xs: '0px',
                          sm: '-180px',
                        },
                      }}
                    >  
                    <Heading title="Edit Clients" closeIcon="true" onClose={onClose} />
                </Box>
            </DialogTitle>
            <DialogContent style={{textAlign:'center'}} >
                <div style={{ marginTop: '10px' }}>
                    <TextField
                        label="Client Name"
                        size='small'
                        value={clientName}
                        onChange={handleClientNameChange}
                        sx={{ marginRight: '10px' }}
                        variant="outlined"
                        error={!!clientNameError}
                        helperText={clientNameError}
                    />
               
                   </div>
               
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    onClick={onClose}
                    className="custom-cancel-button"
                    style={{ marginRight: '10px' }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    className="custom-button"
                    style={{ marginLeft: '10px' }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditClientsModal;
