import axios, { AxiosResponse, AxiosError } from "axios";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const storedUserDetails = localStorage.getItem("userdetails");
const token = storedUserDetails ? JSON.parse(storedUserDetails).token.replace(/^"(.*)"$/, '$1') : null;

export const getTrips = (
  per_page_records: number,
  page_number: number,
  from_date: string,
  to_date: string,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
  vehicle_id?: number,
  warehouse_name?:any,
  fuel_type?:string
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios
    .get(`${global.api_url}/api/get_trips`, {
      params: {
        per_page_records,
        page_number,
        from_date,
        to_date,
        vehicle_id,
        warehouse_name,
        fuel_type
      },
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      }
      else if  (error.response?.status === 401){
        localStorage.clear();
      }
      else{
        toast.error("Server Error");
      }
    });
};

export const getVehicle = (
  per_page_records:number,
  page_number:number,
  warehouse_name ?: string,
  fuel_type?:string,
  _successCallBack:(response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios
    .get(`${global.api_url}/api/vehicles`, {params:{per_page_records, page_number, warehouse_name,fuel_type},
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 404){
        _errorCallBack(error.response.data);
      }
      else if  (error.response?.status === 401){
        localStorage.clear();
      }
      else{
        toast.error("Server Error");
      }
    });
};

export const editRemarks = (
  trip_id: number,
  key: 'remarks' | 'odometer_start' | 'odometer_end',
  value: string,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void
) => {
  const payload = { [key]: value };
  axios.put(`${global.api_url}/api/edit_trips/${trip_id}`, payload, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then((response: AxiosResponse) => {
      _successCallBack(response.data);
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();
      } else {
        toast.error("Server Error");
      }
    });
};

    export const getWarehouses = (
      _successCallBack: (response: any) => void,
      _errorCallBack: (errMessage: any) => void,
    ) => {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      axios
        .get(`${global.api_url}/api/warehouses`, {
          headers: headers,
        })
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            _successCallBack(response.data);
          }
        })
        .catch((error: AxiosError) => {
          console.log('Error caught:', error);
          if (error.response?.status === 400) {
            _errorCallBack(error.response.data);
          }
          else if  (error.response?.status === 401){
            localStorage.clear();
          }
          else{
            toast.error("Server Error");
          }
        });
    };
    export const endTripApi = (
      latitude: any,
      longitude: any,
      trip_id: any,
      vehicle_id: any,
      successCallBack: (message: any) => void,
      errorCallBack: (errorMessage: any) => void,
      actionType: 'start' | 'end',
      odometer_pic?: any,
      odometer_start?: any,
      odometer_end?: any,
      orders_picked?: any,
      orders_delivered?: any
    ) => {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let payload: any = {
        latitude: latitude,
        longitude: longitude,
        trip_id: trip_id,
        vehicle_id: vehicle_id,
        odometer_pic: odometer_pic,
      };
    
      if (actionType === 'start') {
        payload.odometer_start = odometer_start;
        payload.orders_picked = orders_picked;
      } else if (actionType === 'end') {
        payload.odometer_end = odometer_end;
        payload.orders_delivered = orders_delivered;
      }
      axios.post(
        `${global.api_url}/api/start_trip`,
        payload,
        { headers }
      )
      .then((response: AxiosResponse) => {
        if (response.data.status === 201 || response.data.status === 200) {
          successCallBack(response);
        }
        else if ( response.data.status === 400 ) {
          successCallBack(response);
        }
      })
      .catch((error: AxiosError) => {
        console.error('Network Error Details:', error);
        if (error.response) {
          if (error.response.status === 400) {
            errorCallBack(error?.response?.data);
          } else if (error.response.status === 404) {
            errorCallBack(error?.response?.data);
          } else if (error.response.status === 401) {
            localStorage.clear();
            toast.error("Login Expired.");
          }
        } else {
          toast.error("Server Error");
        }
      });
    };
    