import React, { useEffect, useState } from 'react';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import {SelectChangeEvent} from '@mui/material/Select';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx'; 
import Newpagination from '../../Components/Pagination/pagination.tsx';
import { Button,Box,Typography} from '@mui/material';
import NavBar from '../../Components/Navbar/sidenav.tsx';
import Header from '../../Components/Header/header.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import AddClientsModal from './add-clients-modal.tsx';
import EditClientsModal from './edit-clients-modal.tsx';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getClients, deleteClients } from './action.ts';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientsPage = () => {
    const [clients, setClients] = useState<any[]>([]);
    const [addClientsModalOpen, setAddClientsModalOpen] = useState(false);
    const [editClientsModalOpen, setEditClientsModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    useEffect(() => {
        getClients(page,pageSize,
            (response) => {
                if (response.status === 200) {
                    setClients(response.message.clients);
                } else {
                    toast.error("Failed to fetch clients");
                }
            },
            (errorMessage) => {
                toast.error(errorMessage);
            }
        );
    }, [page,pageSize]);

    const handleClickOpen = () => {
        setAddClientsModalOpen(true);
    };
    const handleClickEditOpen = (client) => {
        setSelectedClient(client);
        setEditClientsModalOpen(true);
    };

    const handleDeleteClick = (client) => {
        setClientToDelete(client);
        setConfirmationOpen(true);
    };

    const handleDeleteClients = () => {
        deleteClients(clientToDelete?.id,  
            (response) => {
                if (response.status === 200) {
                    toast.success(response.message);
                    setConfirmationOpen(false);
                    setClientToDelete(null);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else {
                    toast.error(response.message);
                }
            },
            (errorMessage) => {
                toast.error(errorMessage);
            }
        );
    };

    const handleCloseAdd = () => {
        setAddClientsModalOpen(false);
    };
    const handleCloseEdit = () => {
        setEditClientsModalOpen(false);
    };

    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
        setClientToDelete(null);
    };

    const rows = clients.map((client, index) => ({
        id: client.id,
        slNo: index + 1,
        clientName: client.name,
        contactName: client.contact_name,
        mobileNumber: client.phone,
        email: client.email,
        remarks: client.remarks,
    }));

    const columns = [
        { field: 'slNo', headerName: 'SL No', width: 100, headerAlign: 'center' },
        { field: 'clientName', headerName: 'Client Name', flex:1, headerAlign: 'center' },
        {
            field: 'actions',
            headerName: 'Actions',
            flex:1, headerAlign: 'center',
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', gap: '8px' }}>
                        <IconButton color="primary" onClick={() => handleDeleteClick(params.row)}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={() => handleClickEditOpen(params.row)}>
                            <EditIcon />
                        </IconButton>
                    </div>
                );
            },
        },
    ];
  const handlePageClick = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage - 1);
  };
    const showEntries = [
        { id: '10', label: '10' },
        { id: '20', label: '20' },
        { id: '50', label: '50' },
        { id: '100', label: '100' },
    ];
    const updateField = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        if (!isNaN(Number(value))) {
            setPageSize(Number(value));
            setPage(0);
        }
    };
    return (
        <>
            <Header />
            <div style={{ marginTop: "70px" }}>
                <Heading
                    title="Clients"
                    closeIcon="false"
                    leftContent={
                        <>
                        <Box display="flex" alignItems="center" marginLeft='10px'>
                            <Typography style={{ marginRight: '5px' }}>Show Entries:</Typography>
                            <Dropdown
                                options={showEntries}
                                selectedValue={pageSize.toString()}
                                handleChange={(event) => updateField(event)}
                                width='75px'
                                height='25px'
                            />
                        </Box>
                        </>
                    }
                    rightContent={
                        <>
                            <Box display="flex" alignItems="center" gap="10px" marginRight={"10px"}>
                                <Button
                                    className="custom-button"
                                    onClick={handleClickOpen}
                                >
                                    Add Clients
                                </Button>
                            </Box>
                        </>
                    }
                />
                 <Box className="table-container custom-data-grid" sx={{ marginLeft: {xs: '10px', sm: '180px', 
                    },marginTop: '10px', marginRight: '40px', position: 'relative', height: '500px', overflowY: 'auto' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            rowCount={totalRecords}
                            autoHeight
                            disableExtendRowFullWidth={true}
                            disableRowSelectionOnClick
                            hideFooterPagination
                        />
                   </Box>
                   <div style={{
            marginTop: '-20px',
            marginLeft: '50%'
          }}>
            <Newpagination
              rows={totalRecords}
              pageSize={pageSize}
              page={page}
              handlePageChange={handlePageClick}
            />
          </div>
                <AddClientsModal open={addClientsModalOpen} onClose={handleCloseAdd} />
                <EditClientsModal open={editClientsModalOpen} onClose={handleCloseEdit} client={selectedClient} />
            </div>
            <NavBar selected="Clients" />
            <ConfirmationPopup
                open={confirmationOpen}
                onClose={handleCloseConfirmation}
                onConfirm={handleDeleteClients}
                title="Delete Client"
                message="Are you sure you want to delete the client?"
            />
        </>
    );
};

export default ClientsPage;
