import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogContent, TextField, Box, Typography, Grid, Button } from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import { getOTP } from './action.ts';

interface OTPVerificationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  phoneNumber: any;
  handleVerifyOTP: (otp: string) => Promise<{ success: boolean; message?: string }>;
}

const OTPVerificationModal = ({ isOpen, handleClose, handleVerifyOTP, phoneNumber }: OTPVerificationModalProps) => {
  const [otp, setOtp] = useState<string[]>(['', '', '', '']);
  const [error, setError] = useState<string | null>(null);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (otp.every(digit => digit !== '') && otp.length === 4) {
      handleAutoVerify();
    }
  }, [otp]);

  const handleOtpChange = (index: number, value: string) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const resetFields = () => {
    setOtp(['', '', '', '']);
    setError(null);
    inputRefs.current[0]?.focus();
  };

  const handleAutoVerify = async () => {
    const otpString = otp.join('');
    if (otpString.length !== 4) {
      setError('Please enter a 4-digit OTP');
      return;
    }
    const response = await handleVerifyOTP(otpString);

    if (response.success) {
      handleClose();
      resetFields();
    } else {
      setError(response.message || 'Invalid OTP. Please try again.');
    }
  };

  const handleResendOTP = async () => {
        getOTP(
          phoneNumber.driverPhoneNumber,
          '91',
          '0',
          (response) => {
            if (response.data.status === 200) {
              console.log(response.data.message);
            } else {
              setError(response.data.message);
            }
          },
          (errorMessage) => {
            setError(errorMessage.message || 'Failed to send OTP. Please try again.');
           
          }
        );

   
  };

  const handleCloseModal = () => {
    handleClose();
    resetFields();
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseModal}>
      <Box
        sx={{
          marginLeft: {
            xs: '0px',
            sm: '-180px',
          },
        }}
      >
        <Heading title="Verify OTP" closeIcon="true" onClose={handleCloseModal} />
      </Box>
      <DialogContent sx={{ padding: '10px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: '10px', marginTop: '10px', justifyContent: 'center' }}>
              {[0, 1, 2, 3].map((index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  size="small"
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: 'center' }
                  }}
                  value={otp[index]}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  style={{ width: '50px' }}
                />
              ))}
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Typography variant="body2">
            Didn't receive an OTP?{' '}
            <Button
              onClick={handleResendOTP}
              sx={{
                minWidth: 'auto',
                padding: 0,
                color: '#68E0CF !important',
                fontWeight: 'bold',
                textTransform: 'none',
                '&:disabled': {
                  color: 'rgba(0, 0, 0, 0.26) !important'
                }
              }}
            >
              Resend
            </Button>
          </Typography>
        </Box>

        {error && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OTPVerificationModal;