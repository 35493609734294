import React, { useEffect, useState } from 'react';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import {SelectChangeEvent} from '@mui/material/Select';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx';
import { Button,Box,Typography} from '@mui/material';
import NavBar from '../../Components/Navbar/sidenav.tsx';
import Header from '../../Components/Header/header.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import AddUsersModal from './add-user-modal.tsx';
import EditUsersModal from './edit-user-modal.tsx';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getUsers,deleteUsers } from './action.ts';
import Newpagination from '../../Components/Pagination/pagination.tsx';
import * as FaIcons from 'react-icons/fa';
import WarehousesModal from './add-warehouse-modal.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UsersPage = () => {
    const [users, setUsers] = useState<any[]>([]);
    const [addUsersModalOpen, setAddUsersModalOpen] = useState(false);
    const [editUsersModalOpen, setEditUsersModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const storedUserDetails = JSON.parse(localStorage.getItem("userdetails"));
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [warehousesModalOpen, setWarehousesModalOpen] = useState(false); // State for WarehousesModal
    const [selectedUserId, setSelectedUserId] = useState(null);
    useEffect(() => {
        getUsers('','','',storedUserDetails.user.companies_id,page,pageSize,
            (response) => {
                if (response.status === 200) {
                    setUsers(response.message.user);
                } else {
                    toast.error("Failed to fetch users");
                }
            },
            (errorMessage) => {
                toast.error(errorMessage);
            }
        );
    }, []);
    const formatUserRole = (role) => {
        switch (role) {
            case 'vendor_manager':
                return 'Vendor Manager';
            case 'admin':
                return 'Admin';
            case 'vendor_supervisor':
                return 'Vendor Supervisor';
            case 'vendor_company_admin':
                return 'Vendor Company Admin';
            default:
                return role;
        }
    };
    const handleClickOpen = () => {
        setAddUsersModalOpen(true);
    };
    const handleClickEditOpen = (user) => {
        setSelectedUser(user);
        setEditUsersModalOpen(true);
    };

    const handleDeleteClick = (user) => {
        setUserToDelete(user);
        setConfirmationOpen(true);
    };

    const handleDeleteUsers = () => {
        deleteUsers(userToDelete?.id,  
            (response) => {
                if (response.status === 200) {
                    toast.success(response.message);
                    setConfirmationOpen(false);
                    setUserToDelete(null);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else {
                    toast.error(response.message);
                }
            },
            (errorMessage) => {
                toast.error(errorMessage);
            }
        );
    };

    const handleCloseAdd = () => {
        setAddUsersModalOpen(false);
    };
    const handleCloseEdit = () => {
        setEditUsersModalOpen(false);
    };

    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
        setUserToDelete(null);
    };

    const rows = users.map((user, index) => ({
        id: user.user_unique_id,
        slNo: index + 1,
        userName: user.user_name,
        role:formatUserRole(user.user_role),
        mobileNumber: user.user_phone,
        whatsappNumber: user.whatsapp_number,
        email: user.user_email,
    }));
    const handleWarehouseIconClick = (userId) => {
        setSelectedUserId(userId);
        setWarehousesModalOpen(true);
    };
    const handleCloseWarehousesModal = () => {
        setWarehousesModalOpen(false);
        setSelectedUserId(null);
    };
    const columns = [
        { field: 'slNo', headerName: 'SL No', width: 80, headerAlign: 'center' },
        { field: 'userName', headerName: 'User Name', width:200, headerAlign: 'center' },
        { field: 'role', headerName: 'Role', width:200, headerAlign: 'center' },

        { field: 'mobileNumber', headerName: 'Mobile Number', width: 200, headerAlign: 'center' },

        { field: 'whatsappNumber', headerName: 'Whatsapp Number', width: 200, headerAlign: 'center' },
        { field: 'email', headerName: 'Email', width:200, headerAlign: 'center' },
        {
            field: 'actions',
            headerName: 'Actions',
            flex:1, headerAlign: 'center',
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', gap: '8px' }}>
                        <IconButton color="primary" onClick={() => handleDeleteClick(params.row)}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={() => handleClickEditOpen(params.row)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={() => handleWarehouseIconClick(params.row.id)}>
                        <FaIcons.FaWarehouse />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    const showEntries = [
        { id: '10', label: '10' },
        { id: '20', label: '20' },
        { id: '50', label: '50' },
        { id: '100', label: '100' },
    ];

    const updateField = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        if (!isNaN(Number(value))) {
            setPageSize(Number(value));
            setPage(0);
        }
    };
    const handlePageClick = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage - 1);
      };
    return (
        <>
            <Header />
            <div style={{ marginTop: "70px" }}>
                <Heading
                    title="Users"
                    closeIcon="false"
                    leftContent={
                        <>
                       <Box display="flex" alignItems="center" marginLeft={'10px'}>
                       <Typography style={{ marginRight: '5px' }}>Show Entries:</Typography>
                            <Dropdown
                                options={showEntries}
                                selectedValue={pageSize.toString()}
                                handleChange={(event) => updateField(event)}
                                width='75px'
                                height='25px'
                            />
                        </Box>
                        </>
                    }
                    rightContent={
                        <>
                         <Box display="flex" alignItems="center" gap="10px" marginRight={'10px'}>
                            <Button
                                className="custom-button"
                                onClick={handleClickOpen}
                            >
                                Add Users
                            </Button>
                        </Box>
                        </>
                    }
                />
                 <Box className="table-container custom-data-grid" sx={{ marginLeft: {
      xs: '10px', 
      sm: '180px', 
    },marginTop: '10px', marginRight: '40px', position: 'relative', height: '500px', overflowY: 'auto' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            autoHeight
                            disableExtendRowFullWidth={true}
                            disableRowSelectionOnClick
                            hideFooterPagination
                        />
                   </Box>
                   <div style={{
            marginTop: '20px',
            marginLeft: '50%'
          }}>
            <Newpagination
              rows={totalRecords}
              pageSize={pageSize}
              page={page}
              handlePageChange={handlePageClick}
            />
          </div>
                <AddUsersModal open={addUsersModalOpen} onClose={handleCloseAdd} />
                <EditUsersModal open={editUsersModalOpen} onClose={handleCloseEdit} user={selectedUser} />
                <WarehousesModal open={warehousesModalOpen} onClose={handleCloseWarehousesModal} userId={selectedUserId} source="userPage"  />
            </div>
            <NavBar selected="Users" />
            
            <ConfirmationPopup
                open={confirmationOpen}
                onClose={handleCloseConfirmation}
                onConfirm={handleDeleteUsers}
                title="Delete User"
                message="Are you sure you want to delete the user?"
            />
        </>
    );
};

export default UsersPage;
