import axios, { AxiosResponse, AxiosError } from "axios";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const storedUserDetails = localStorage.getItem("userdetails");
const token = storedUserDetails ? JSON.parse(storedUserDetails).token.replace(/^"(.*)"$/, '$1') : null;

export const getVehicle = (
  per_page_records:any,
  page_number:any,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
  number_plate?:any,
  warehouse_name?:any,
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${global.api_url}/api/vehicles`, {
        params :{ per_page_records, page_number,number_plate, warehouse_name}, 
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if (response.status === 201){
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if( error.response?.status === 404)
        {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else{
          toast.error("Server Error");
        }
      });
  };

  export const getDocuments = (
    vehicles_id:any,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${global.api_url}/api/documents`, {
        params: {vehicles_id},
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if(response.status === 201) {
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else if( error.response?.status === 404)
          {
            _errorCallBack(error.response.data);
          }
        else{
          toast.error("Server Error");
        }
      });
  };

  export const addDocuments = (
    drivers_license: File | null,
    aadhar: File | null,
    reg_cert: File | null,
    insurance: File | null,
    fitness_cert: File | null,
    pollution_cert: File | null,
    vehicles_id: number,
    fitness_expiry: string | null,
    insurance_expiry: string | null,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {
    const formData = new FormData();
    if (drivers_license) formData.append('drivers_license', drivers_license);
    if (aadhar) formData.append('aadhar', aadhar);
    if (reg_cert) formData.append('reg_cert', reg_cert);
    if (insurance) formData.append('insurance', insurance);
    if (fitness_cert) formData.append('fitness_cert', fitness_cert);
    if (pollution_cert) formData.append('pollution_cert', pollution_cert);
    formData.append('vehicles_id', vehicles_id.toString());
    if (fitness_expiry) formData.append('fitness_expiry', fitness_expiry);
    if (insurance_expiry) formData.append('insurance_expiry', insurance_expiry);
    axios.post(`${global.api_url}/api/documents`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse) => {
      if(response.status === 200) {
      _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
       })
    .catch((error: AxiosError) => {
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }  else if( error.response?.status === 404)
        {
          _errorCallBack(error.response.data);
        }else {
        
        _errorCallBack(error);
      }
    });
  };

  export const editVehicle = (
    model:string,
    reg_date:string,
    number_plate:string,
    driver_id: string,
    owner_id: number,
    fuel_type:string,
    otp_verified:string,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {

  const encodedNumberPlate = encodeURIComponent(number_plate);
  const payload = {
    driver_id,
    model,
    reg_date,
    owner_id,
    fuel_type,
    otp_verified,
  };
    axios.put(`${global.api_url}/api/vehicles/${encodedNumberPlate}`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response: AxiosResponse) => {
        if(response.status === 201) {
        _successCallBack(response.data);
      }
        else if(response.status === 200) 
      { _successCallBack(response.data);
      }
    })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        } else if (error.response?.status === 401) {
          localStorage.clear();
          toast.error("Login Expired")
        } else if (error.response?.status === 500){
          toast.error(error?.response?.message);
        }
        else if( error.response?.status === 404)
          {
            _errorCallBack(error.response.data);
          }else {
          toast.error("Server Error");
        }
      });
  };
 
  export const editDocuments = (
    drivers_license: File | null,
    aadhar: File | null,
    reg_cert: File | null,
    insurance: File | null,
    fitness_cert: File | null,
    pollution_cert: File | null,
    vehicles_id: number,
    fitness_expiry: string | null,
    insurance_expiry: string | null,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {
    const formData = new FormData();
    if (drivers_license) formData.append('drivers_license', drivers_license);
    if (aadhar) formData.append('aadhar', aadhar);
    if (reg_cert) formData.append('reg_cert', reg_cert);
    if (insurance) formData.append('insurance', insurance);
    if (fitness_cert) formData.append('fitness_cert', fitness_cert);
    if (pollution_cert) formData.append('pollution_cert', pollution_cert);
    formData.append('vehicles_id', vehicles_id.toString());
    if (fitness_expiry) formData.append('fitness_expiry', fitness_expiry);
    if (insurance_expiry) formData.append('insurance_expiry', insurance_expiry);
    axios.post(`${global.api_url}/api/documents/edit`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse) => {
      if(response.status === 200) {
      _successCallBack(response.data);
      }
      else if(response.status === 201) {
        _successCallBack(response.data);
      }
       })
    .catch((error: AxiosError) => {
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }  else if( error.response?.status === 404)
        {
          _errorCallBack(error.response.data);
        }else {
        
        _errorCallBack(error);
      }
    });
  };
export const getDriverName = (
  phone_number:any,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios
    .get(`${global.api_url}/api/users/?device_id=&user_phone=${phone_number}&country_code=91`, {
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if(response.status === 201) { 
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      }
      else if  (error.response?.status === 401){
        localStorage.clear();
      }
      else if( error.response?.status === 404)
        {
          _errorCallBack(error.response.data);
        }
      else{
        toast.error("Server Error");
      }
    });
};
export const getOwnerName = (
  phone_number:any,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios
    .get(`${global.api_url}/api/vehicles_owners?phone=${phone_number}&per_page_records=1&page_number=0`, {
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if(response.status === 201) { 
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      }
      else if  (error.response?.status === 401){
        localStorage.clear();
      }
      else if( error.response?.status === 404)
        {
          _errorCallBack(error.response.data);
        }
      else{
        toast.error("Server Error");
      }
    });
};

export const fetchClients = (
  successCallBack: (clients: any[]) => void,
  errorCallBack: (errorMessage: any) => void
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios.get(`${global.api_url}/api/clients`, { headers })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        const clients = response.data.message.clients;
        successCallBack(clients);
      } 
      else if(response.status === 201) {
         successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 400) {
          errorCallBack(error.response.data);
        } else if (error.response?.status === 401) {
          localStorage.clear();
          toast.error("Login Expired.");
        }
        else if( error.response?.status === 404)
          {
            errorCallBack(error.response.data);
          }
      else {
        toast.error("Server Error");
      }
    });
};

export const addWarehouseUserMapping = (
  userId: any,
  warehouseId: any,
  successCallBack: (message: any) => void,
  errorCallBack: (errorMessage: any) => void
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const payload = {
    users_id:userId,
    warehouses_id:warehouseId,
  };
  axios.post(
    `${global.api_url}/api/user_warehouses`,
    payload,
    { headers }
  )
  .then((response: AxiosResponse) => {
    if (response.status === 201) {
      successCallBack(response);
    } 
    else if (response.status === 200) {
      successCallBack(response);
    } 
  })
  .catch((error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 400) {
        errorCallBack(error?.response?.data);
      } else if (error.response.status === 404){
        errorCallBack(error?.response?.data)
      }
      else if( error.response?.status === 409)
        {
          errorCallBack(error?.response?.data);
        }
      else if (error.response.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }
    } 
    else {
      toast.error("Server Error");
    }
  });
};
export const addClientUserMapping = (
  userId: any,
  clientId: any,
  successCallBack: (message: any) => void,
  errorCallBack: (errorMessage: any) => void
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const payload = {
    users_id:userId,
    clients_id:clientId,
  };
  axios.post(
    `${global.api_url}/api/client_users_mapping`,
    payload,
    { headers }
  )
  .then((response: AxiosResponse) => {
    if (response.status === 201) {
      successCallBack(response.data.message);
    } 
    else if (response.status === 200) {
      successCallBack(response.data.message);
    } 
  })
  .catch((error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 400) {
        errorCallBack(error?.response?.data);
      } else if (error.response.status === 404){
        errorCallBack(error?.response?.data)
      }
      else if( error.response?.status === 404)
        {
          errorCallBack(error?.response?.data);
        }
      else if (error.response.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }
    } 
    else {
      toast.error("Server Error");
    }
  });
};
export const addVehicle = (
  model: string,
  makeYear: string,
  numberPlate: string,
  driverId: number,
  ownerId: number,
  fuelType: string,
  otpVerify:string,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void
) => {
  const payload = {
    model : model,
    reg_date : makeYear,
    number_plate : numberPlate,
    driver_id : driverId,
    owner_id :ownerId,
    fuel_type : fuelType,
    otp_verified:otpVerify,
  };
   axios.post(`${global.api_url}/api/vehicles`,payload,
   {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response: AxiosResponse) => {
      if (response.status === 201) {
        _successCallBack(response);
      } 
      else if (response.status === 200) {
        _successCallBack(response);
      } 
      
    })
    .catch((error: any) => {
      if (error.response?.status === 400) {
        const errorMessage = error.response.data.message?.error || "An error occurred1";
         _errorCallBack(errorMessage);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }  else if( error.response?.status === 404)
        {
          _errorCallBack(error?.response?.data);
        }
      else {
        toast.error("Server Error");
      }
    });
};
export const addOwner = (
  name: string,
  phone: any,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void
) => {
  const payload = {
    name : name,
    phone : phone,
  };
   axios.post(`${global.api_url}/api/vehicles_owners`,payload,
   {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response: AxiosResponse) => {
      if (response.status === 201) {
        _successCallBack(response.data.message);
      } 
      else if (response.status === 200) {
        _successCallBack(response.data.message);
      } 
      
    })
    .catch((error: any) => {
      if (error.response?.status === 400) {
        const errorMessage = error.response.data.message?.error || "An error occurred";
         _errorCallBack(errorMessage);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }  else if( error.response?.status === 404)
        {
          _errorCallBack(error?.response?.data);
        }
      else {
        toast.error("Server Error");
      }
    });
};
export const addOwnerDetails = (
  id:any,
  account_number: any,
  ifsc: any,
  remarks: any,
  email: any,
  address: any,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void
) => {
  const payload = {
    owners_id:id,
    account_number : account_number,
    ifsc : ifsc,
    remarks:remarks,
    email:email,
    address:address
  };
   axios.post(`${global.api_url}/api/vehicles_owners_details`,payload,
   {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response: AxiosResponse) => {
      if (response.status === 201) {
        _successCallBack(response.data.message);
      } 
      else if (response.status === 200) {
        _successCallBack(response.data.message);
      } 
      
    })
    .catch((error: any) => {
      if (error.response?.status === 400) {
        const errorMessage = error.response.data.message?.error || "An error occurred";
         _errorCallBack(errorMessage);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }  else if( error.response?.status === 404)
        {
          _errorCallBack(error?.response?.data);
        }
      else {
        toast.error("Server Error");
      }
    });
};
export const fetchVendors = (
  successCallBack: (clients: any[]) => void,
  errorCallBack: (errorMessage: any) => void
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios.get(`${global.api_url}/api/vendors`, { headers })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        const vendors = response.data.message.vendors;
        successCallBack(vendors);
      }
      else if (response.status === 200) {
        successCallBack(response.data.message);
      } 
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 400) {
          errorCallBack(error.response.data);
        } else if (error.response?.status === 401) {
          localStorage.clear();
          toast.error("Login Expired.");
        } 
        else if( error.response?.status === 404)
          {
            errorCallBack(error?.response?.data);
          }
       else{
        toast.error("Server Error");
      }
});
};

export const deleteDocument = (
  vehicleDocumentId: number | null,
  vehicleId: number | null,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  const data = {
    veh_documents_id: vehicleDocumentId,
    vehicles_id: vehicleId
  };
  axios
    .delete(`${global.api_url}/api/documents`, {
      headers: headers,
      data: data 
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
      else if (response.status === 200) {
        _successCallBack(response.data);
      } 
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }  else if( error.response?.status === 404)
        {
          _errorCallBack(error?.response?.data);
        }
      else {
        toast.error("Server Error");
      }
    });
};

export const addVendorUserMapping = (
  userId: number,
  vendorId: number,
  successCallBack: (message: string) => void,
  errorCallBack: (errorMessage: string) => void
) => {
  const payload = {
    users_id:userId,
    vendors_id:vendorId,
  };
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios.post(
    `${global.api_url}/api/vendor_users_mapping`,
    payload,
    { headers }
  )
  .then((response: AxiosResponse) => {
    if (response.status === 201) {
      successCallBack(response.data.message);
    } 
    else if (response.status === 200) {
      successCallBack(response.data.message);
    }
  })
  .catch((error: any) => {
    if (error.response) {
      if (error.response.status === 400) {
        errorCallBack(error.response.data);
      } else if (error.response.status === 401) {
        localStorage.clear();
        toast.error("Login Expired.");
      }
    } 
    else if( error.response?.status === 404)
      {
        errorCallBack(error?.response?.data);
      }
    else {
      toast.error("Server Error");
    }
  });
};

export const getWarehouseUserMapping = (
      users_id:any,
      _successCallBack: (response: any) => void,
      _errorCallBack: (errMessage: any) => void,
    ) => {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      axios
        .get(`${global.api_url}/api/user_warehouses?per_page_records=1&page_number=0&users_id=${users_id}`, {
          headers: headers,
        })
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            _successCallBack(response.data);
          }
          else if (response.status === 200) {
            _successCallBack(response.data);
          } 
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 400) {
            _errorCallBack(error.response.data);
          }
          else if  (error.response?.status === 401){
            localStorage.clear();
          }
          else if( error.response?.status === 404)
            {
              _errorCallBack(error?.response?.data);
            }
          else{
            toast.error("Server Error");
          }
        });
    };
  
    export const getWarehouses = (
      _successCallBack: (response: any) => void,
      _errorCallBack: (errMessage: any) => void,
    ) => {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      axios
        .get(`${global.api_url}/api/warehouses`, {
          headers: headers,
        })
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            _successCallBack(response.data);
          }
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 400) {
            _errorCallBack(error.response.data);
          }
          else if  (error.response?.status === 401){
            localStorage.clear();
          }
          else{
            _errorCallBack(error.response.data);
          }
        });
    };
    export const verifyOTP = (
      phone_number: any,
      country_code: any,
      otp:any,
      successCallBack: (message: any) => void,
      errorCallBack: (errorMessage: any) => void
    ) => {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const payload = {
        phone_number:phone_number,
        country_code:country_code,
        otp:otp
      };
      axios.post(
        `${global.api_url}/api/verify_otp`,
        payload,
        { headers }
      )
      .then((response: AxiosResponse) => {
        if (response.status === 201) {
          successCallBack(response);
        } 
        else if (response.status === 200) {
          successCallBack(response);
        } 
        else {successCallBack(response)
        }
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          if (error.response.status === 400) {
            errorCallBack(error?.response?.data);
          } else if (error.response.status === 404){
            errorCallBack(error?.response?.data)
          }
          else if( error.response?.status === 404)
            {
              errorCallBack(error?.response?.data);
            }
          else if (error.response.status === 401) {
            localStorage.clear();
            toast.error("Login Expired.");
          }
        } 
        else {
          toast.error("Server Error");
        }
      });
    };
    export const getOTP = (
      phone_number: any,
      country_code: any,
      from_register:any,
      successCallBack: (message: any) => void,
      errorCallBack: (errorMessage: any) => void
    ) => {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const payload = {
        phone_number:phone_number,
        country_code:country_code,
        from_register:from_register
      };
          axios.post(`${global.api_url}/api/generate_otp`, payload)
          .then((response: AxiosResponse) => {
           
              successCallBack(response);
           
          })
          .catch((error: AxiosError) => {
            if (error.response?.status === 400) {
              errorCallBack(error);
            }
            else if  (error.response?.status === 401){
              localStorage.clear();
            }
            else{
              errorCallBack(error);
            }
          });
            }