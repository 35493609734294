import axios, { AxiosResponse, AxiosError } from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const storedUserDetails = localStorage.getItem("userdetails");
const token = storedUserDetails ? JSON.parse(storedUserDetails).token.replace(/^"(.*)"$/, '$1') : null;

export const getClients = (
  page_number,
  per_page_records,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
    ) => {
      const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");

    const headers = {
      Authorization: `Bearer ${userDetailsFromStorage.token}`, 
    };
    axios
      .get(`${global.api_url}/api/clients?companies_id&per_page_records=${per_page_records}&page_number=${page_number}`, { 
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200 || response.status === 201) {
          _successCallBack(response.data);
        } else {
          _errorCallBack("Unexpected status code: " + response.status);
        }
      })
      .catch((error: AxiosError) => {
          console.log('Error caught:', error);
          if (error.response?.status === 400) {
            _errorCallBack(error.response.data);
          } else if (error.response?.status === 404) {
              console.log(error.response.data);
            _errorCallBack(error.response.data);
          }else if (error.response?.status === 401) {
            localStorage.clear();
          } 
        });
  };
  export const addClients = (name:any,companies_id:any,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
    ) => {
      const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
      const headers = {
        Authorization: `Bearer ${userDetailsFromStorage.token}`, 
      };
      const payload = {
        name,
        companies_id
      };
    axios
      .post(`${global.api_url}/api/clients`, payload, { headers })
      
      .then((response: AxiosResponse) => {
        if (response.status === 200 || response.status === 201) {
          _successCallBack(response.data);
        } else {
          _errorCallBack("Unexpected status code: " + response.status);
        }
      })
      .catch((error: AxiosError) => {
          console.log('Error caught:', error);
          if (error.response?.status === 400) {
            _errorCallBack(error.response.data);
          } else if (error.response?.status === 404) {
              console.log(error.response.data);
            _errorCallBack(error.response.data);
          }else if (error.response?.status === 401) {
            localStorage.clear();
          } 
        });
  };
  export const editClients = (
    name: string | null,
    clients_id: string|null,  
    companies_id:string | null,                 
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`, 
      'Content-Type': 'application/json',
    };
    const payload = {
      name,
      companies_id
    
    };
    axios
      .put(`${global.api_url}/api/clients/${clients_id}`, payload, { headers })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data); 
        }
        else if(response.status === 201) {
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data); 
        } 
        else if (error.response?.status === 404) {
          _errorCallBack(error.response.data);
        }else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else{
          toast.error("Server Error");
        }
      });
  };
  export const deleteClients = (
    clientId: any,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {
    const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
    const headers = {
      Authorization: `Bearer ${userDetailsFromStorage.token}`
    };
    const data = {
     client_id : clientId
    };
    axios
      .delete(`${global.api_url}/api/clients/${clientId}`, {
        headers: headers,

      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if (response.status === 200) {
          _successCallBack(response.data);
        } 
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        } else if (error.response?.status === 401) {
          localStorage.clear();
          toast.error("Login Expired.");
        }  else if( error.response?.status === 404)
          {
            _errorCallBack(error?.response?.data);
          }
        else {
          toast.error("Server Error");
        }
      });
  };

  export const warehouseClientMapping = (
    clientsId: number,
    usersId: number,
    successCallBack: (message: string) => void,
    errorCallBack: (errorMessage: string) => void
  ) => {
    const payload = {
      clients_id:clientsId,
      users_id:usersId,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios.post(
      `${global.api_url}/api/client_warehouses`,
      payload,
      { headers }
    )
    .then((response: AxiosResponse) => {
      if (response.status === 201) {
        successCallBack(response.data.message);
      } 
      else if (response.status === 200) {
        successCallBack(response.data.message);
      }
    })
    .catch((error: any) => {
      if (error.response) {
        if (error.response.status === 400) {
          errorCallBack(error.response.data);
        } else if (error.response.status === 401) {
          localStorage.clear();
          toast.error("Login Expired.");
        }
      } 
      else if( error.response?.status === 404)
        {
          errorCallBack(error?.response?.data);
        }
      else {
        toast.error("Server Error");
      }
    });
  };