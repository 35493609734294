import axios, { AxiosResponse, AxiosError } from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const storedUserDetails = localStorage.getItem("userdetails");
const token = storedUserDetails ? JSON.parse(storedUserDetails).token.replace(/^"(.*)"$/, '$1') : null;

export const getWarehouses = (
  page_number: number,
  per_page_records: string | null,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
    ) => {
      const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");

    const headers = {
      Authorization: `Bearer ${userDetailsFromStorage.token}`, 
    };
    axios
      .get(`${global.api_url}/api/warehouses?per_page_records=${per_page_records}&page_number=${page_number}`, { 
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200 || response.status === 201) {
          _successCallBack(response.data);
        } else {
          _errorCallBack("Unexpected status code: " + response.status);
        }
      })
      .catch((error: AxiosError) => {
          console.log('Error caught:', error);
          if (error.response?.status === 400) {
            _errorCallBack(error.response.data);
          } else if (error.response?.status === 404) {
              console.log(error.response.data);
            _errorCallBack(error.response.data);
          }else if (error.response?.status === 401) {
            localStorage.clear();
          } 
        });
  };
  export const addWarehouses = (name:any,contact_name:any,latitude:any,longitude:any,remarks:any,phone:any,email:any,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
    ) => {
      const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
      const headers = {
        Authorization: `Bearer ${userDetailsFromStorage.token}`, 
      };
      const payload = {
        name,
        contact_name,
        phone,
        email,
        latitude,
        longitude,
        remarks
      };
    axios
      .post(`${global.api_url}/api/warehouses`, payload, { headers })
      
      .then((response: AxiosResponse) => {
        if (response.status === 200 || response.status === 201) {
          _successCallBack(response.data);
        } else {
          _errorCallBack("Unexpected status code: " + response.status);
        }
      })
      .catch((error: AxiosError) => {
          console.log('Error caught:', error);
          if (error.response?.status === 400) {
            _errorCallBack(error.response.data);
          } else if (error.response?.status === 404) {
              console.log(error.response.data);
            _errorCallBack(error.response.data);
          }else if (error.response?.status === 401) {
            localStorage.clear();
          } 
        });
  };
  export const editWarehouses = (
    name:any,contact_name:any,latitude:any,longitude:any,remarks:any,phone,email:any,warehouses_id:any,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`, 
      'Content-Type': 'application/json',
    };
    const payload = {
      name,
        contact_name,
        phone,
        email,
        latitude,
        longitude,
        remarks
    
    };
    axios
      .put(`${global.api_url}/api/warehouses/${warehouses_id}`, payload, { headers })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data); 
        }
        else if(response.status === 201) {
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data); 
        } 
        else if (error.response?.status === 404) {
          _errorCallBack(error.response.data);
        }else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else{
          toast.error("Server Error");
        }
      });
  };
  export const deleteWarehouses = (
    warehouse_id: any,
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void
  ) => {
    const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
    const headers = {
      Authorization: `Bearer ${userDetailsFromStorage.token}`
    };
  
    axios
      .delete(`${global.api_url}/api/warehouses/${warehouse_id}`, {
        headers: headers,

      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
        else if (response.status === 200) {
          _successCallBack(response.data);
        } 
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        } else if (error.response?.status === 401) {
          localStorage.clear();
          toast.error("Login Expired.");
        }  else if( error.response?.status === 404)
          {
            _errorCallBack(error?.response?.data);
          }
        else {
          toast.error("Server Error");
        }
      });
  };
  export const warehouseClientMapping = (
    clientsId: any,
    warehouseId: any,
    successCallBack: (message: string) => void,
    errorCallBack: (errorMessage: string) => void
  ) => {
    const payload = {
      clients_id:clientsId,
      warehouses_id:warehouseId,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios.post(
      `${global.api_url}/api/client_warehouses`,
      payload,
      { headers }
    )
    .then((response: AxiosResponse) => {
      if (response.status === 201) {
        successCallBack(response.data.message);
      } 
      else if (response.status === 200) {
        successCallBack(response.data.message);
      }
    })
    .catch((error: any) => {
      if (error.response) {
        if (error.response.status === 400) {
          errorCallBack(error.response.data);
        } else if (error.response.status === 401) {
          localStorage.clear();
          toast.error("Login Expired.");
        }
      } 
      else if( error.response?.status === 404)
        {
          errorCallBack(error?.response?.data);
        }
      else {
        toast.error("Server Error");
      }
    });
  };
  export const getClients = (
    _successCallBack: (response: any) => void,
    _errorCallBack: (errMessage: any) => void,
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axios
      .get(`${global.api_url}/api/clients`, {
        headers: headers,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          _successCallBack(response.data);
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error caught:', error);
        if (error.response?.status === 400) {
          _errorCallBack(error.response.data);
        }
        else if  (error.response?.status === 401){
          localStorage.clear();
        }
        else{
          toast.error("Server Error");
        }
      });
  };